import React, { Component } from 'react';
import  { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import _ from 'lodash';

import SeriesCategories from "../series/SeriesCategories";
import SeriesCategoriesList from "../series/SeriesCategoriesList";
import SeriesCategoriesSortable from "../series/SeriesCategoriesSortable";

import PodcastCategories from "../podcasts/PodcastCategories"
import PodcastCategoriesList from "../podcasts/PodcastCategoriesList";
import PodcastCategoriesSortable from "../podcasts/PodcastSortable";

import { fetchContentCategories, reorderContentCategories, reorderContentTVCategories, updateDatabaseCategories } from '../../../redux/actions/categories';

const SortableItem = SortableElement(({ value, orderNumber }) => <li className="sortable-banner-list-item podcast">{orderNumber + 1}. { value.label}</li>);

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul className="sortable-banner-list podcast">
            {items.map((value, i) => (
                <SortableItem key={`${value.id}`} index={i} orderNumber={i} value={value} />
            ))}
        </ul>
    );
});

class Reorder extends Component {
    componentDidMount(){
        this.props.fetchContentCategories();
    }

    _isArrayEqual = (x,y) => {
        return _.isEqual(x, y);
    }

    _onSortEnd = ({ oldIndex, newIndex }) => {  
        const { combinedCategories, reorderContentCategories } = this.props;
        const updatedCategories = arrayMove(combinedCategories, oldIndex, newIndex);

        if (this._isArrayEqual(combinedCategories, updatedCategories)) { 
            return 
        }

        reorderContentCategories(updatedCategories, "mobile");
    };

    _handleUpdate = () => {
        const { updateDatabaseCategories, combinedCategories } = this.props; 
        updateDatabaseCategories(combinedCategories, "mobile");
    }

    _onSortEndTV = ({ oldIndex, newIndex }) => {  
        const { combinedTVCategories, reorderContentTVCategories } = this.props;
        const updatedCategories = arrayMove(combinedTVCategories, oldIndex, newIndex);

        if (this._isArrayEqual(combinedTVCategories, updatedCategories)) { 
            return 
        }

        reorderContentTVCategories(updatedCategories);
    };

    _handleUpdateTV = () => {
        const { updateDatabaseCategories, combinedTVCategories } = this.props; 
        updateDatabaseCategories(combinedTVCategories, "tv");
    }

    render(){
        return (
            <div>
                <h1 className="mb-5">
                    Update Categories
                </h1>

                <div className="mb-4">
                    <h2 className="mb-4">Step 1</h2>
                    <p>* Warning! * This button updates <b>ALL CATEGORIES</b>. Please use with caution.</p>
                </div>

                <SeriesCategories />
                <PodcastCategories />
             
                <hr className="my-5" />
                <h2 className="mb-4">Step 2</h2>

                <div className="mb-5">
                  
                    <h3 className="mb-3">Sort Series Categories</h3>
                    <SeriesCategoriesList />
                    <SeriesCategoriesSortable />
                </div>

                <div>
                    <h3 className="mb-3">Sort Podcast Categories</h3>
                    <PodcastCategoriesList />
                    <PodcastCategoriesSortable />
                </div>
                
                <hr className="my-5" />
                <h2 className="mb-4">Step 3</h2>

                <div className="mb-5">
                    <h3 className="mb-3">Sort Mobile Categories</h3>
                    <div className="mb-3">
                        <SortableList 
                            items={this.props.combinedCategories} 
                            onSortEnd={this._onSortEnd} 
                        />
                    </div>
                    <div>
                        {
                            !this.props.mobileNeedsUpdate
                            ? null
                            : (
                                <button className="btn-rounded-pink" onClick={this._handleUpdate}>
                                    Update
                                </button>
                            )
                        }
                    </div>
                </div>
                    
                <div>
                    <h3 className="mb-3">Sort TV Categories</h3>
                    <div className="mb-3">
                        <SortableList 
                            items={this.props.combinedTVCategories} 
                            onSortEnd={this._onSortEndTV} 
                        />
                    </div>
                    <div> 
                        {
                            !this.props.tvNeedsUpdate
                            ? null
                            : (
                                <button className="btn-rounded-pink" onClick={this._handleUpdateTV}>
                                    Update
                                </button>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        )
    }   
}

const mapStateToProps = ({ categories: { combinedCategories, combinedTVCategories, mobileNeedsUpdate, tvNeedsUpdate } }) => ({ 
    combinedCategories,
    combinedTVCategories,
    mobileNeedsUpdate,
    tvNeedsUpdate
})

const addFunctionsToProps = {
    fetchContentCategories,
    reorderContentCategories,
    reorderContentTVCategories,
    updateDatabaseCategories
};

export default connect(mapStateToProps, addFunctionsToProps)(Reorder);