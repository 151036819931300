import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactFilestack from 'filestack-react';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import moment from 'moment';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/lib/Creatable';
import parseSRT from 'parse-srt'

import bootstrap from '../../../helpers/bootstrap';
import { toggleEpisodeModal } from '../../../../redux/actions/layout';

import {
    onEpisodeInputChange,
    handleImageUploaded,
    handleEpisodeFileChange,
    uploadVideoToAWS,
    cancelUploadToAWS,
    handleEpisodes,
    handleEditEpisodeImage,
    handleFileStackUploadSuccess,
    handleFileStackUploadSuccess720,
    handleFileStackUploadSuccess480,
    handleDailymotionCheckboxChange,
    handleYoutubeCheckboxChange,
    handleTagSelection
} from '../../../../redux/actions/forms';

class EditForm extends Component {
    state = {
        file: "",
        fileRequired: false
    }

    _handleImageUploaded = response => {
        this.props.handleImageUploaded("episodeForm", response.filesUploaded[0].url);
    }

    _handleFileStackVideoUploaded1080 = response => {
        this.props.handleFileStackUploadSuccess(response.filesUploaded[0])
    }

    _handleFileStackVideoUploaded720 = response => {
        this.props.handleFileStackUploadSuccess720(response.filesUploaded[0])
    }

    _handleFileStackVideoUploaded480 = response => {
        this.props.handleFileStackUploadSuccess480(response.filesUploaded[0])
    }

    _handleVideoUploadError = error => {
        console.log(error)
        toast("Unable to upload video");
    }

    _handleFileChange = e => {
        const { name, size, type } = e.target.files[0];

        this.setState({ file: e.target.files[0], fileRequired: false });

        this.props.handleEpisodeFileChange(name, size, type, e.target.files[0]);
    }

    _handleDateChange = episodeReleaseDate => {
        let episodeIsoDateFormat = moment(new Date(episodeReleaseDate)).format('YYYY-MM-DD');
        this.props.onEpisodeInputChange("releaseDate", episodeIsoDateFormat);
    }

    _handleVideoQualitySelection = quality => {
        this.props.onEpisodeInputChange("quality", quality);
    }

    _handleVideoTypeSelection = videoType => {
        this.props.onEpisodeInputChange("videoType", videoType);
    }

    _handleToggleModal = isEditing => {
        if (isEditing) {
            this.props.toggleEpisodeModal(true)
        } else {
            this.props.toggleEpisodeModal(false)
        }
    }

    _handleCheckedYoutube = async e => {
        if (process.env.NODE_ENV !== "development") {
            if (this.props.user && (this.props.user._id !== process.env.REACT_APP_WHOHAHA_ID)) {
                toast("Must be on main Whohaha Account for YouTube.")
                return;
            }
        }

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.props.handleYoutubeCheckboxChange(value, false);
    }

    _displayTimeFromSeconds(s) {
        var sec_num = parseInt(s, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return `${hours}:${minutes}:${seconds}`;
    }

    _formatBytes(a, b) {
        if (0 === a) return "0 Bytes";
        let c = 1024,
            d = b || 2,
            e = ["Bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"],
            f = Math.floor(Math.log(a) / Math.log(c));
        return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
    }

    _handleTagSelection = tags => {
        this.props.handleTagSelection(tags, "seriesEpisode");
    }

    _handleSrtFile = (e) => {
        var that = this;
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            let result = parseSRT(e.target.result);
            let data = JSON.stringify(result);
            that.props.onEpisodeInputChange("srtFileName", file.name);
            that.props.onEpisodeInputChange("srtData", data);
        };

        if (file) {
            reader.readAsText(file);
        }
    }

    render() {
        const { thumbnail, url, url720, url480, quality, videoType, title, releaseDate, shortDescription, episodeNumber, duration, autoplayTime, addToDailymotion, youtube, srtFileName } = this.props.episodeForm;
        const { fileName, fileSize, fileType, file, uploading, percentage } = this.props.awsForm;
        const { isEditingEpisode, updateEpisodeLoading, createEpisodeLoading, deleteEpisodeLoading } = this.props.layout;

        return (
            <div id="addepisode">
                <div className={`${bootstrap.between} mb-5`}>
                    <h2>
                        {isEditingEpisode ? "Episode" : "New Episode"}
                    </h2>
                    <button onClick={() => this._handleToggleModal(isEditingEpisode)}>
                        <FontAwesomeIcon icon="times" size="2x" color="#666666" />
                    </button>
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Episode Title
                    </h3>
                    <input value={title} className="input-title px-3 py-3" onChange={e => this.props.onEpisodeInputChange("title", e.target.value)} />
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Episode Description
                    </h3>
                    <input
                        value={shortDescription}
                        type="text"
                        className="input-title px-3 py-3"
                        maxLength="200"
                        onChange={e => this.props.onEpisodeInputChange("shortDescription", e.target.value)}
                    />
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Episode Number
                    </h3>
                    <input value={episodeNumber} type="number" className="input-title px-3 py-3" onChange={e => this.props.onEpisodeInputChange("episodeNumber", e.target.value)} />
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Episode Duration (in seconds)
                    </h3>
                    <label className="mb-2">{duration ? this._displayTimeFromSeconds(duration) : "00:00:00"}</label>
                    <input value={duration} type="number" className="input-title px-3 py-3" onChange={e => this.props.onEpisodeInputChange("duration", e.target.value)} />
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Episode Auto Play (in seconds)
                    </h3>
                    <label className="mb-2">{autoplayTime ? this._displayTimeFromSeconds(autoplayTime) : "00:00:00"}</label>
                    <input value={autoplayTime} type="number" className="input-title px-3 py-3" onChange={e => this.props.onEpisodeInputChange("autoplayTime", e.target.value)} />
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Episode Release Date
                    </h3>
                    <Flatpickr
                        value={releaseDate}
                        onChange={episodeReleaseDate => this._handleDateChange(episodeReleaseDate)}
                        options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: 'Y-m-d'
                        }}
                    />
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Video Quality
                    </h3>
                    <Select
                        value={quality}
                        options={[{ label: "HD", value: "HD" }, { label: "FHD", value: "FHD" }, { label: "UHD", value: "UHD" }]}
                        onChange={this._handleVideoQualitySelection}
                    />
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Video Type
                    </h3>
                    <Select
                        value={videoType}
                        options={[{ label: "MP4", value: "MP4" }]}
                        onChange={this._handleVideoTypeSelection}
                    />
                </div>
                <div className="input-container p-5 mb-4">
                    <div>
                        <h3 className="mb-4">
                            Upload video in 1080p
                        </h3>
                        {
                            fileType && fileSize && fileName
                                ? (
                                    <div className="d-flex border">
                                        <div className={`${bootstrap.center} px-4 icon-wrapper`}>
                                            <FontAwesomeIcon icon="file-video" size="5x" />
                                        </div>
                                        <div className="p-4">
                                            <p>
                                                File name: <span className="filename">{fileName}</span>
                                            </p>
                                            <p>
                                                File size: <span>{this._formatBytes(fileSize)}</span>
                                            </p>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                                        <span className="mr-4">
                                            No file selected
                                        </span>
                                        <div className="input-file-container">
                                            <ReactFilestack
                                                apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                                buttonText="Add File"
                                                buttonClass="ml-3 filestack btn-rounded-grey"
                                                options={{
                                                    accept: 'video/mp4'
                                                }}
                                                onSuccess={this._handleFileStackVideoUploaded1080}
                                                onError={this._handleVideoUploadError}
                                            />
                                        </div>
                                    </div>
                                )
                        }

                        {
                            fileType && !uploading && percentage < 100
                                ? (
                                    url
                                        ? (
                                            <div>
                                                <h3 className="my-4">
                                                    Video File Url
                                                </h3>
                                                <span className="uploaded-url">
                                                    {url}
                                                </span>
                                            </div>
                                        )
                                        : (
                                            <button className="mt-4 btn-rounded-pink upload-aws" onClick={() => this.props.uploadVideoToAWS(file)}>
                                                Upload
                                            </button>
                                        )
                                )
                                : (
                                    uploading
                                        ? (
                                            <button className="mt-4 btn-rounded-pink upload-aws" onClick={() => this.props.cancelUploadToAWS()}>
                                                Cancel <span className="ml-3">{percentage}% </span>
                                            </button>
                                        )
                                        : (
                                            !url
                                                ? null
                                                : (
                                                    <div>
                                                        <h3 className="my-4">
                                                            Video File Url
                                                        </h3>
                                                        <span className="uploaded-url">
                                                            {url}
                                                        </span>
                                                    </div>
                                                )
                                        )
                                )
                        }
                    </div>

                </div>

                <div className="input-container p-5 mb-4">
                    <div>
                        <h3 className="mb-4">
                            Upload video in 720p
                        </h3>
                        <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                            <span className="mr-4">
                                No file selected
                            </span>
                            <div className="input-file-container">
                                <ReactFilestack
                                    apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                    buttonText="Add File"
                                    buttonClass="ml-3 filestack btn-rounded-grey"
                                    options={{
                                        accept: 'video/mp4'
                                    }}
                                    onSuccess={this._handleFileStackVideoUploaded720}
                                    onError={this._handleVideoUploadError}
                                />
                            </div>
                        </div>
                        {
                            url720
                                ? (
                                    <div>
                                        <h3 className="my-4">
                                            Video File Url
                                        </h3>
                                        <span className="uploaded-url">
                                            {url720}
                                        </span>
                                    </div>
                                ) : null
                        }
                    </div>
                </div>

                <div className="input-container p-5 mb-4">
                    <div>
                        <h3 className="mb-4">
                            Upload video in 480p
                        </h3>
                        <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                            <span className="mr-4">
                                No file selected
                            </span>
                            <div className="input-file-container">
                                <ReactFilestack
                                    apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                    buttonText="Add File"
                                    buttonClass="ml-3 filestack btn-rounded-grey"
                                    options={{
                                        accept: 'video/mp4'
                                    }}
                                    onSuccess={this._handleFileStackVideoUploaded480}
                                    onError={this._handleVideoUploadError}
                                />
                            </div>
                        </div>
                        {
                            url480
                                ? (
                                    <div>
                                        <h3 className="my-4">
                                            Video File Url
                                        </h3>
                                        <span className="uploaded-url">
                                            {url480}
                                        </span>
                                    </div>
                                ) : null
                        }
                    </div>
                </div>

                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Episode Image (850w x 400h)
                    </h3>
                    <div className={`d-flex ${isEditingEpisode ? "flex-column align-items-start" : "align-items-center"}`}>
                        {
                            !thumbnail
                                ? (
                                    <div>
                                        <span className="mr-4">No file selected</span>
                                        <ReactFilestack
                                            apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                            buttonText="Add File"
                                            buttonClass="filestack btn-rounded-grey"
                                            options={{
                                                accept: 'image/*',
                                                storeTo: {
                                                    location: 's3',
                                                    path: "/images/"
                                                }
                                            }}
                                            onSuccess={this._handleImageUploaded}
                                        />
                                    </div>

                                )
                                : (
                                    <div className="img-container">
                                        <img className="img-fluid" src={thumbnail} alt="thumbnail" />
                                        <div className="edit-container">
                                            <button onClick={() => this.props.handleEditEpisodeImage()}>
                                                <FontAwesomeIcon icon="pencil-alt" color="#fff" />
                                            </button >
                                            <button onClick={() => this.props.handleEditEpisodeImage()}>
                                                <FontAwesomeIcon icon="times" color="#fff" />
                                            </button>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Captions (optional)
                    </h3>
                    {
                        !srtFileName ? null : <label className="mb-4">Filename: {srtFileName || ""}</label>
                    }
                    <input
                        type="file"
                        onChange={this._handleSrtFile}
                        accept=".srt"
                    />
                </div>
                <div className="input-container p-5 mb-4">
                    <h3 className="mb-4">
                        Tags (optional)
                    </h3>
                    <CreatableSelect
                        isMulti
                        isClearable
                        isDisabled={false}
                        isLoading={false}
                        onChange={this._handleTagSelection}
                        options={this.props.tags.all}
                        value={this.props.episodeForm._tags}
                    />
                </div>


                <div className={`flex-column d-flex flex-sm-row ${isEditingEpisode ? "justify-content-between" : "justify-content-end"}`} >
                    {
                        isEditingEpisode
                            ? (
                                <div className="mb-4 mb-sm-0">
                                    <button
                                        className={`btn-rounded-pink add-episode`}
                                        onClick={() => this.props.handleEpisodes("delete", this.props.episodeForm)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            )
                            : null
                    }

                    <div>
                        <button
                            disabled={updateEpisodeLoading || createEpisodeLoading}
                            className={`btn-rounded-pink add-episode`}
                            onClick={async () => {
                                !isEditingEpisode
                                    ? this.props.handleEpisodes("create", this.props.episodeForm, this.state.file, this.props.seriesForm.dailymotionId)
                                    : this.props.handleEpisodes("update", this.props.episodeForm)

                                this.setState({ fileRequired: false });
                            }}>
                            {
                                updateEpisodeLoading || createEpisodeLoading || deleteEpisodeLoading
                                    ? <span className="updating">Updating ... </span>
                                    : <span>{isEditingEpisode ? "Update" : "Add Episode"}</span>
                            }
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({
    forms: { episodeForm, awsForm, seriesForm },
    auth: { user },
    layout, tags
}) => ({ episodeForm, awsForm, seriesForm, layout, user, tags });

export default connect(mapStateToProps, {
    toggleEpisodeModal,
    onEpisodeInputChange,
    handleImageUploaded,
    handleEpisodeFileChange,
    uploadVideoToAWS,
    cancelUploadToAWS,
    handleEpisodes,
    handleEditEpisodeImage,
    handleFileStackUploadSuccess,
    handleFileStackUploadSuccess720,
    handleFileStackUploadSuccess480,
    handleDailymotionCheckboxChange,
    handleYoutubeCheckboxChange,
    handleTagSelection
})(EditForm);