import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import _, { sortBy } from 'lodash';
import axios from "axios";
import { parse } from 'json2csv';

import { getDataFromUuid, handleAccountInputChange} from '../../../redux/actions/accounts'

class UserAccountsList extends Component {
    state = {
        sortBy: "createdAt",
        currentPage: 1,
        contentCurrentPage: 0,
        content: "",
        userData: [],
        userDocs: [],
        loading: false
    }

    componentDidMount(){
        this.getAccounts(1, "", this.state.sortBy);
    }

    getAccounts = (page, name, sortBy, content, pageChanged ) => {
        this.setState({ loading: true });
        axios.get(`/accounts/getAccounts?page=${page||1}&name=${name||""}&sort=${sortBy||"createdBy"}&content=${content||""}`)
            .then( res => {
                const data = res.data;
                let userData;

                if (sortBy === "Series" || sortBy === "Podcast") {
                    const PER_PAGE = 20;
                    const page = pageChanged ? this.state.contentCurrentPage : 0;
                    const offset = page * PER_PAGE;
                    userData = data.docs
                    .slice(offset, offset + PER_PAGE)
                    .map(user => (
                        <li key={user._id} onClick={()=>this._handleItemSelected(user)}>
                            <span className="mr-5">{new Date(user.createdAt).toLocaleString()}</span>
                            <span><b>{ `${this._limit(user.firstName)} ${this._limit(user.lastName)}` }</b></span>
                        </li>
                    ));
                } else {
                    userData = data.docs.map(user => (
                        <li key={user._id} onClick={()=>this._handleItemSelected(user)}>
                            <span className="mr-5">{new Date(user.createdAt).toLocaleString()}</span>
                            <span><b>{ `${this._limit(user.firstName)} ${this._limit(user.lastName)}` }</b></span>
                        </li>
                    ));
                }

                this.setState({
                    pageCount: data.totalPages,
                    userData,
                    userDocs: data.docs,
                    loading: false
                })
            })
            .catch(err => {
                this.setState({ loading: false });
                if (err.response) {
                    const  { status } = err.response;
    
                    if (status ===  422) { return}
                    if (status ===  401) { return }
                }
            })
    }

    exportEmailCsv = async () => {
        const { sortBy } = this.state;

        if (sortBy === "Series" || sortBy === "Podcast") {
            const fields = ['createdAt', 'email'];
            const opts = { fields };
            const csv = parse(this.state.userDocs, opts);

            this._downloadCsv(csv);
            return
        }

        const { data } = await axios.get(`/accounts/convertToCSV/${sortBy}`);
        this._downloadCsv(data);
    }

    _handlePageClick = data => {
        let sortBy = this.state.sortBy;
        let page = data.selected + 1;
        if (sortBy === "Series" || sortBy === "Podcast") {
            page = data.selected;
            this.setState({ contentCurrentPage: page });
            this.getAccounts(page, "", this.state.sortBy, `${this.state.sortBy}-${this.state.content}`, true);
        } else {
            this.setState({ currentPage: page });
            this.getAccounts(page, "", this.state.sortBy);
        }
    };

    _downloadCsv(csvData){
        let a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        let blob = new Blob([csvData], { type: 'text/csv' });
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'emails.csv';
        a.click();
    }

    _handleItemSelected = (user) => {
        this.props.getDataFromUuid(user);
    }

    _limit(text){
        const count = 25;
        return text.slice(0, count) + (text.length > count ? "..." : "");
    }

    _renderFilterSearch = () => {
        const { name } = this.props;
        const { sortBy } = this.state;

        return (
            <div id="accounts-searchbar" className="mb-4">
                <form onSubmit={e => e.preventDefault()}>
                    <div className="form-group" style={{ maxWidth: "25rem" }}>
                        <label className="mb-2">Search:</label>
                        <input value={name} placeholder="Search name..." className="form-control" onChange={e => this.props.handleAccountInputChange(e.target.value) }/>
                    </div>

                    <div className="form-group" style={{ maxWidth: "25rem" }}>
                        <label className="mb-2">Sort by:</label>
                        <select value={sortBy} className="form-control" onChange={this._handleSortBy}>
                            <option value="createdAt">created at</option>
                            <option value="lastLogin">recent login</option>
                            <option value="appleMobile">mobile</option>
                            <option value="appleTV">apple tv</option>
                            <option value="Series">series</option>
                            <option value="Podcast">podcast</option>
                        </select>
                    </div>

                    {
                        sortBy === "Series" || sortBy === "Podcast"
                        ? (
                            <div className="d-flex" style={{ maxWidth: "25rem" }}>
                                <div className="form-group" >
                                    <label className="mb-2">Select {this.state.sortBy.toLowerCase()}</label>
                                    <select className="form-control" onChange={this._searchByFilterList}>
                                        <option value="">None</option>
                                        {this._filterList(this.state.sortBy)}
                                    </select>
                                </div>
                            </div> 
                        )
                        : null
                    }
                </form>
                
                <div className="d-flex justify-content-between">
                    <button className="btn-rounded-pink mt-3 mr-3" onClick={this._handleSubmit} disabled={this.state.loading}>
                        {   this.state.loading ? "Loading..." : "Search"}
                    </button> 
                    {
                        _.isEmpty(this.state.userData)
                        ? null
                        : (
                            <button className="btn-rounded-pink mt-3" onClick={this.exportEmailCsv} >
                                Print
                            </button>
                        )
                    }
                </div>
            </div>
        )
    }

    _handleSubmit = e => {
        e.preventDefault();

        if (this.state.sortBy === "Series" || this.state.sortBy === "Podcast") {
            let content = `${this.state.sortBy}-${this.state.content}`;
            const id = content.split("-");
            if (!id[1]){
                this.setState({ content: "", contentCurrentPage: 0, currentPage: 1, userData: [] });
                return 
            }
            
            this.getAccounts(this.state.contentCurrentPage, this.props.name, this.state.sortBy, content, false );
            return
        } 

        this.getAccounts(this.state.currentPage, this.props.name, this.state.sortBy);
    }

    _handleSortBy = e => {
        const value = e.target.value;
        this.setState({ sortBy: value, content: "", contentCurrentPage: 0, currentPage: 1, userData: [] });
    }

    _handleSelect = e => {
        this.setState({ selectBy: e.target.value });
    }

    _filterList = type => {
        if(type === "Series"){

            const removeDrafts = this.props.allSeries.filter( item => item.published);
            const removePodcasts = removeDrafts.filter( item => !item.isPodcast );
            
            return removePodcasts.map( item => (
                <option key={item._id} value={item.id} >{item.title}</option>
            ));
        }

        return this.props.allPodcasts.map( item => (
            <option key={item._id} value={item.podcastId}>{item.title}</option>
        ));
    }

    _searchByFilterList = e => {
        const content =  e.target.value;
        this.setState({ content, contentCurrentPage: 0, userData: [] });
    }

    _renderList = () => {
        const { userData, pageCount, sortBy, content } = this.state;

        if (sortBy === "Series" || sortBy === "Podcast"){ 
          
            if(!content) {
                return 
            }
        }

        return <div>
            <div id="accounts">
                <ul className="mb-4">
                    { userData }
                </ul>
            </div>
            <div id="react-paginate">
                <ReactPaginate 
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={this._handlePageClick}
                    activeClassName={'active'}
                />
            </div>
        </div>
    }
    
    render(){   
        const { userData } = this.state;

        if (!userData) {
            return <div>Loading ...</div>;
        }

        return (
            <div>
                { this._renderFilterSearch() }

                { _.isEmpty(userData) 
                    ? null
                    : this._renderList()
                }
            </div>
        )
    }
}

const mapStateToProps = ({ 
    accounts: { data, name }, 
    series: { allSeries },
    podcast: { allPodcasts }
}) => ({
    data,
    name,
    allSeries,
    allPodcasts
});

const mapFunctionsToProps = {
    getDataFromUuid,
    handleAccountInputChange
}

export default connect(mapStateToProps, mapFunctionsToProps)(UserAccountsList);