import React from 'react';
import _ from 'lodash';
import Table from 'react-bootstrap/Table';
import utils from '../../helpers/utils'

const UserAccountsItem  = ({ user, details }) => {
    if (!user) { return null }

    if (_.isEmpty(user.videos) && _.isEmpty(user.podcasts) && _.isEmpty(user.logins)) {
        return <div>No account data.</div>
    }

    return (
        <div style={{ backgroundColor: "white", padding: "2rem"}}>
            <div className="mb-5">
                 <h2 className="mb-2">Details</h2>
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th colSpan="1" >
                                    Full Name
                                </th>
                                <th colSpan="1"> 
                                    Logins
                                </th>
                                <th colSpan="1"> 
                                    Created At
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{`${details.firstName} ${details.lastName}`}</td>
                                <td>{user.logins.length}</td> 
                                <td>{(new Date(details.createdAt)).toLocaleString()}</td> 
                            </tr>
                        </tbody> 
                    </Table>
            </div>
            <div className="mb-5">
                <h2 className="mb-2">Videos</h2>
                <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th colSpan="1" >
                                    Title
                                </th>
                                <th colSpan="1" >
                                    Date Watched
                                </th>
                                <th colSpan="1"> 
                                    Watch Time
                                </th>
                                <th colSpan="1">
                                   Fully Watched
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                              user.videos.map( video => {
                                    let time =  video.millis / 1000;
            
                                    let minutes = Math.floor(time / 60);
                                    let seconds = time - minutes * 60;
                                    let hours = Math.floor(time / 3600);
                                    let formatedTime = `${utils.formatTime(hours,'0',2)}:${utils.formatTime(minutes,'0',2)}:${utils.formatTime(seconds,'0',2)}`;
    
                                    return (
                                        <tr key={video.episodeId}>
                                            <td>{video.title}</td>
                                            <td>{new Date(video.createdAt).toLocaleString(undefined, { year: "numeric", month: "long", day: "numeric" })}</td>
                                            <td>{video.millis !== 0 ? <span>{ formatedTime }</span> : "-" }</td>
                                            <td>{video.fullyWatched ? "Yes" : "No" }</td>
                                        </tr>
                                    )
                                })
                            }   
                        </tbody> 
                    </Table>
            </div>
            
           <div className="mb-5">
                <h2 className="mb-2">Podcasts</h2>
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th colSpan="1" >
                                    Title
                                </th>
                                <th colSpan="1" >
                                    Date Watched
                                </th>
                                <th colSpan="1"> 
                                    Watch Time
                                </th>
                                <th colSpan="1">
                                   Fully Watched
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                user.podcasts.map( podcast => {
                                    let time =  podcast.seekTime
                                    let minutes = Math.floor(time / 60);
                                    let seconds = time - minutes * 60;
                                    let hours = Math.floor(time / 3600);
                                    let formatedTime = `${utils.formatTime(hours,'0',2)}:${utils.formatTime(minutes,'0',2)}:${utils.formatTime(seconds,'0',2)}`;
    
                                    return (
                                        <tr key={podcast.podcastEpisodeId}>
                                            <td>{podcast.podcastTitle}</td>
                                            <td>{new Date(podcast.createdAt).toLocaleString(undefined, { year: "numeric", month: "long", day: "numeric" })}</td>
                                            <td>{podcast.seekTime !== 0 ? <span>{ formatedTime }</span> : "-" }</td>
                                            <td>{podcast.watched ? "Yes" : "No" }</td>
                                        </tr>
                                    )
                                })
                            }   
                        </tbody> 
                    </Table>
           </div>
        </div>
    )
}

export default UserAccountsItem;