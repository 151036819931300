import axios from 'axios';
import * as types from '../constants/types';
import { toast } from 'react-toastify';
import utils from '../../react/helpers/utils';

export const fetchAllSeries = () => async dispatch => {
    const res = await axios.get('/series/all_series');
   
    const payload = {
        reorderedSeries: res.data.featuredSeries,
        reorderedSeriesTV: res.data.featuredTV,
        reorderedSeriesMobile: res.data.featuredMobile
    };

    dispatch({ type: types.FETCH_ALL_SERIES, payload: res.data.series });
    dispatch({ type: types.REORDER_SERIES, payload });
};

export const reorderSeries = (list, platform) => dispatch =>{
    if (platform === "mobile") {
        dispatch({ type: types.REORDER_SERIES_MOBILE, payload: list });
        return 
    }

    dispatch({ type: types.REORDER_SERIES_TV, payload: list });
    return 
}

//FEATURED BANNER ITEMS
export const updatedReorderedSeries = (list, platform) => async dispatch => {
    const res = await axios.put('/series/reorder', { list, platform });

    if (platform === "mobile") {
        dispatch({ type: types.REORDER_SERIES_MOBILE, payload: res.data });
        return 
    }

    dispatch({ type: types.REORDER_SERIES_TV, payload: res.data });
    return 
}

//REORDER SERIES CATEGORIES ITEM
export const reorderSeriesCategories = (list, didSort, selectedCategory, platforms) => ({ 
    type: types.REORDER_SERIES_CATEGORIES, 
    payload: list, 
    didSort, 
    selectedCategory, 
    platforms 
});

export const onSearchChange = text => ({ type: types.SEARCH_SERIES, payload: text });

export const handlePublishSeries = (seriesId, shouldPublish, seriesForm) => async dispatch => {
    try {
        const errors = utils.validateSeriesForPublishing(seriesForm, false, false);

        if(errors.length > 0) {
            toast(`the following are required to publish: ${utils.displayErrors(errors)}`);
        } else {
            const res = await axios.put('/series/publish', { seriesId, shouldPublish });

            if(res.data.status === "success") {
                dispatch({ type: types.FETCH_ALL_SERIES, payload: res.data.allSeries });
                dispatch({ type: types.UPDATE_SERIES_FORM, published: res.data.published, datePublished: res.data.datePublished });
                toast("Series successfully updated");
            }
        }
    } catch(err) {
        console.log(err);
        toast("Something went wrong.");
    }
}

function sort(list){
    list.sort(function (a, b) {
        return a.seriesOrderNumber - b.seriesOrderNumber;
    });

    return list;
}

export const getMatchingSeriesCategories = (selectedCategory, series) => async dispatch => {
    try {
        const { data } = await axios.get(`/seriesCategories/${selectedCategory.value}`);

        const ids = data.ids;
        const platforms = data.platforms || [];
        let sortableSeries = [];

        for (let i = 0; i < series.length; i++) {
            const seriesItem = series[i];
            const copy = Object.assign({}, seriesItem);

            for (let j = 0; j < ids.length; j++) {
                if (seriesItem.id === ids[j] && seriesItem.published !== false){
                    copy.seriesOrderNumber = j;
                    sortableSeries.push(copy);
                }
            }
        }
  
        let sorted = sort(sortableSeries);
    
        dispatch({ type: types.REORDER_SERIES_CATEGORIES, payload: sorted, didSort: false, selectedCategory, platforms });
    } catch (error) {
        console.log(error);
        toast("[ERROR] Updating series category order.");
    }
} 