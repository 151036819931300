import React from 'react';
import { connect } from 'react-redux';

import Header from './Header';
import LeftSideDrawer from './sidedrawer/LeftSideDrawer';
import RightSideDrawer from './sidedrawer/RightSideDrawer';

const Layout = ({ children, editModalIsActive }) =>  (
    <div id="layout" className="d-flex flex-column">
        <Header />
        <div className="d-flex layout-main">
            <LeftSideDrawer />
            <main>
                {children}
            </main>
            <RightSideDrawer />
        </div>
    </div>
);

const mapStateToProps = ({ 
    layout : { editModalIsActive },
}) => ({ editModalIsActive });

export default connect(mapStateToProps)(Layout);
