import axios from 'axios';

export default ({ url, method, body, onSuccess, onError }) => {
    const tryRequest = async () => {
        try {
            const { data } = await axios[method](url, body);

            if (onSuccess) {
                onSuccess(data);
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    return { tryRequest };
}