import React from 'react';
import { connect } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import _ from "lodash";

import PodcastForm from './PodcastForm';
import PodcastList from './PodcastList';
import BannerPodcastSortable from './PodcastSortable';
import PodcastSocialSharing from './PodcastSocialSharing';
import PodcastCategories from './PodcastCategories';
import PodcastCategoriesList from './PodcastCategoriesList';
import PodcastEpisodes from './PodcastEpisodes';

import { toggleViewPodcasts , togglePodcastCategoriesModal } from '../../../redux/actions/layout';

function generateList(podcasts) {
    if (_.isEmpty(podcasts)) {
        return null;
    } 

    let items = [];

    for (let i = 0; i < podcasts.length; i++) {
        items.push(
            <label className="d-flex align-items-center mb-2" >
                <input type="checkbox" className="mr-2" />
                {podcasts[i].title}
            </label>
        )
    }

    return items 
}

const PodcastComponent = ({ podcastType, toggleViewPodcasts, togglePodcastCategoriesModal, showPodcastCategoriesModal, allPodcasts }) => {
    if (podcastType === "socialSharing") {
        return (
            <div id="podcast">
                <div className="mb-5">
                    <h1 className="mb-5"> 
                        Podcast Social Sharing
                    </h1>

                    <PodcastSocialSharing />
                </div>
            </div>
        )
    }

    if(podcastType === "subtitles") {
        return (
            <div id="podcast">
                <div className="d-flex mb-5">
                    <h1 className="mr-4"> 
                        Podcast Episodes
                    </h1>
                    <div>
                        <button className="btn-rounded-pink" onClick={() => toggleViewPodcasts("edit")}>BACK</button>
                    </div>
                </div>
                <PodcastEpisodes /> 
            </div>
        )
    }

    return (
        <div id="podcast">
             <div className="d-flex align-items-center mb-5">
                <h1 className="mr-4"> Podcasts </h1>
                <div className="" >
                    {
                        podcastType === "add" || podcastType === "edit"
                        ? <button className="btn-rounded-pink" onClick={()=>toggleViewPodcasts("view")}>BACK</button>
                        : <button className="btn-rounded-pink" onClick={()=>toggleViewPodcasts("add")}>ADD NEW</button>
                    }
                </div>
            </div>
            { podcastType === "view" ? <PodcastList /> : <PodcastForm podcastType={podcastType} /> }
        </div>
    )
}

const mapStateToProps = ({ 
        podcast: { allPodcasts },
        layout: { podcastType, showPodcastCategoriesModal },
    }) => ({ 
        podcastType, 
        showPodcastCategoriesModal,
        allPodcasts
    });

export default connect(mapStateToProps, { toggleViewPodcasts, togglePodcastCategoriesModal })(PodcastComponent);