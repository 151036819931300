import React, { Component } from 'react';
import { connect } from 'react-redux';

import ResetPasswordForm from '../components/forms/ResetPassword';

import { validateResetToken } from '../../redux/actions/auth';

class ResetPassword extends Component {
    componentDidMount(){
        let token = this.props.match.params.token
        this.props.validateResetToken(token)
    }

    render(){
        let token = this.props.match.params.token;
        const { resetTokenMessage, resetTokenIsSuccess} = this.props;

        if(!token) { 
            return null
        }
    
        return (
            <div id="password-reset-container" className="d-flex flex-column">
                <h1 className="mb-5">Reset Password</h1>
                {
                    resetTokenMessage
                    ? ( 
                        <div>
                            {
                                !resetTokenIsSuccess
                                ? (
                                    <div className="alert-danger mb-4">
                                        <p className="p-3">{resetTokenMessage}</p>
                                    </div>
                                )
                                : (
                                    <div className="alert alert-success mb-4">
                                        <p className="p-3">{resetTokenMessage}</p>
                                    </div>
                                )
                            }
                        </div>
                        
                    )
                    : null
                }
                <ResetPasswordForm token={token} />
            </div>
        )
    }
}

const mapFunctionsToProps = {
    validateResetToken
}

const mapStateToProps = ({ auth: { resetTokenMessage, resetTokenIsSuccess  }}) => ({ resetTokenMessage, resetTokenIsSuccess });
  
export default connect(mapStateToProps, mapFunctionsToProps)(ResetPassword);
  