import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { reorderSeries, updatedReorderedSeries } from '../../../redux/actions/series';
 
const SortableItem = SortableElement(({ value, orderNumber }) => <li className="sortable-banner-list-item"><span className="mr-4">{orderNumber}.</span> {value.title}</li>);

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul className="sortable-banner-list">
            {items.map((value, index) => (
                <SortableItem key={`${value._id}`} index={index} orderNumber={index} value={value} />
            ))}
        </ul>
    );
});

class BannerSortable extends Component {
    _onSortEnd = ({ oldIndex, newIndex }) => {  
        let updatedSeries = arrayMove(this.props.series, oldIndex, newIndex);
        
        //this.props.reorderSeries(updatedSeries, this.props.platform);
        this.props.updatedReorderedSeries(updatedSeries, this.props.platform);
    };

    render() {
        return <SortableList items={this.props.series} onSortEnd={this._onSortEnd} />;
    }
}

const mapFunctionsToProps = {
    reorderSeries,
    updatedReorderedSeries
}

export default connect(null, mapFunctionsToProps)(BannerSortable);