import * as types from '../constants/types'
import axios from 'axios';
import { toast } from 'react-toastify';
import utils from '../../react/helpers/utils';

const CancelToken = axios.CancelToken;
let cancel;

/*----------------------------------
    GENERAL
----------------------------------*/
export const onSeriesInputChange = (input, text) => ({ type: types.SERIES_FORM_INPUT_CHANGE, input, text });
export const onEpisodeInputChange = (input, text) => ({ type: types.EPISODE_FORM_INPUT_CHANGE, input, text });

export const handleCheckboxChange = e => dispatch => {
    const { checked, name } = e.target;

    switch (name) {
        case "featured":
            dispatch({ type: types.HANDLE_SERIES_FEATURED_CHANGE, checked, name });
            break;

        case "featuredTV":
            dispatch({ type: types.HANDLE_SERIES_FEATURED_CHANGE, checked, name });
            break;

        case "featuredMobile":
            dispatch({ type: types.HANDLE_SERIES_FEATURED_CHANGE, checked, name });
            break;

        case "featuredPodcastTV":
            dispatch({ type: types.PODCAST_HANDLE_CHECKBOX_CHANGE, name: "featuredTV", checked });
            break;

        case "featuredPodcastMobile":
            dispatch({ type: types.PODCAST_HANDLE_CHECKBOX_CHANGE, name: "featuredMobile", checked });
            break;

        case "isZeroBucket":
            dispatch({ type: types.PODCAST_HANDLE_CHECKBOX_CHANGE, name, checked });
            break;

        case "podcastPublished":
            dispatch({ type: types.PODCAST_HANDLE_CHECKBOX_CHANGE, name, checked });
            break;

        case "showOnRoku":
            dispatch({ type: types.HANDLE_SERIES_SHOW_ON_ROKU_CHANGE, checked });
            break;

        case "showOnApp":
            dispatch({ type: types.HANDLE_SERIES_SHOW_ON_APP_CHANGE, checked });
            break;

        default:
            dispatch({ type: types.HANDLE_SERIES_CHECKBOX_CHANGE, name, checked });
            break;
    }

}

export const handleDailymotionCheckboxChange = (checked, isNewSeries) => dispatch => {
    if (isNewSeries) {
        dispatch({ type: types.HANDLE_SERIES_DAILYMOTION_CHECKBOX_CHANGE, checked });
        return;
    }

    dispatch({ type: types.HANDLE_DAILYMOTION_CHECKBOX_CHANGE, checked });
}

export const handleYoutubeCheckboxChange = (checked, isSeries) => dispatch => {
    if (isSeries) {
        dispatch({ type: types.HANDLE_SERIES_YOUTUBE_CHECKBOX_CHANGE, checked });
        return;
    }

    dispatch({ type: types.HANDLE_EPISODE_YOUTUBE_CHECKBOX_CHANGE, checked });
}

export const handleGenreSelection = selectedGenres => dispatch => {
    dispatch({ type: types.HANDLE_SERIES_GENRES_CHANGE, payload: selectedGenres });
}

export const handleBlacklistSelection = selectedCountries => dispatch => {
    dispatch({ type: types.HANDLE_SERIES_BLACKLIST_CHANGE, payload: selectedCountries });
}

export const handleWhitelistSelection = selectedCountries => dispatch => {
    dispatch({ type: types.HANDLE_SERIES_WHITELIST_CHANGE, payload: selectedCountries });
}

/*----------------------------------
CATEGORIES
----------------------------------*/
export const handlePodcastCategorySelection = selectedCategories => dispatch => {
    dispatch({ type: types.PODCAST_CATEGORIES_CHANGE, payload: selectedCategories });
}

export const handleSeriesCategorySelection = (selectedCategories) => dispatch => {
    dispatch({ type: types.SERIES_CATEGORIES_CHANGE, payload: selectedCategories });
}

export const handleSeriesCategoryPlatformSelection = (selectedPlatforms) => dispatch => {
    dispatch({ type: types.SERIES_CATEGORIES_PLATFORMS, payload: selectedPlatforms });
}

export const handleCategorySelection = (selectedCategories, constant) => dispatch => {
    dispatch({ type: types[constant], payload: selectedCategories });
}

/*----------------------------------
TAGS
----------------------------------*/
export const handleTagSelection = (tags, tagType) => dispatch => {
    if (tagType === "series") {
        dispatch({ type: types.UPDATE_SERIES_TAGS, payload: tags });
        return;
    }

    if (tagType === "seriesEpisode") {
        dispatch({ type: types.UPDATE_SERIES_EPISODE_TAGS, payload: tags });
        return;
    }

    if (tagType === "podcast") {
        dispatch({ type: types.UPDATE_PODCAST_TAGS, payload: tags });
        return;
    }

    if (tagType === "podcastEpisode") {
        dispatch({ type: types.UPDATE_PODCAST_EPISODE_TAGS, payload: tags });
        return;
    }

    return;
}

/*----------------------------------
    SERIES
----------------------------------*/
function formatData(data) {
    let dataCopy = data;

    const validation = utils.validate(dataCopy);

    if (validation.length !== 0) {
        toast("All fields required for submission");
        return "invalid";
    }

    dataCopy.title = dataCopy.title.trim();
    dataCopy.shortDescription = dataCopy.shortDescription.trim();
    dataCopy.duration = Number(dataCopy.duration);
    dataCopy.autoplayTime = Number(dataCopy.autoplayTime);
    dataCopy.episodeNumber = Number(dataCopy.episodeNumber);
    dataCopy.quality = dataCopy.quality.value;
    dataCopy.videoType = dataCopy.videoType.value;

    return dataCopy;
}

async function handleSeriesUpdates(response, loadingType, dispatch) {
    dispatch({ type: types.FETCH_ALL_SERIES, payload: response.allSeries });
    dispatch({ type: types.REORDER_SERIES, payload: response.sortedSeries });
    dispatch({ type: types.HANDLE_SERIES_LOADING, loadingType, payload: false });

    const { data } = await axios.get('/tags');
    dispatch({ type: types.GET_ALL_TAGS, payload: data });
}

function createYoutubeSeriesData(data, id) {
    const { title, shortDescription } = data;

    let formData = {
        title,
        description: shortDescription
    };

    if (id) {
        formData.id = id;
    }

    return formData;
}

export const handleSeries = (loadingType, seriesData) => async dispatch => {
    let formattedSeriesData = seriesData;

    //dispatch({ type: types.HANDLE_SERIES_LOADING, loadingType, payload: true });

    try {
        if (loadingType === "create") {
            formattedSeriesData.categories = [];
            formattedSeriesData.appCategories = [];

            if (!formattedSeriesData.youtube) {
                let errors = utils.validateSeriesForPublishing(formattedSeriesData, false, true);

                if (errors.length > 0) {
                    toast(`The following are required to publish: ${utils.displayErrors(errors)}`);
                    return;
                }
            }

            const res = await axios.post('/series/create', formattedSeriesData);
            const res2 = await axios.get('/categories/all_categories');

            if (res.data.message === "success") {
                toast("Series successfully saved");
                handleSeriesUpdates(res.data, loadingType, dispatch);
                dispatch({ type: types.RESET_SERIES_FORM, payload: res2.data.categories });
            }
        }

        if (loadingType === "update") {

            let checkedCategories = seriesData.categories.filter(category => category.checked);
            formattedSeriesData.appCategories = checkedCategories.map(category => category.name);

            const res = await axios.put('/series/update', formattedSeriesData);

            if (res.data.message === "success") {
                toast("Series successfully updated");
                handleSeriesUpdates(res.data, loadingType, dispatch);
                dispatch({ type: types.EDIT_SELECTED_SERIES, payload: res.data.updatedSeries, seasons: res.data.seasons });
            }
        }

    } catch (err) {
        console.log(err)
        toast("Something went wrong.");
    }
}

export const handleSeriesDelete = (seriesId, seasonId, dailymotionId, youtubePlaylistId) => async dispatch => {
    if (youtubePlaylistId) {
        try {
            await axios.delete(`/youtube/deletePlaylist/${youtubePlaylistId}`);
        } catch (err) {
            toast("Something went wrong. Youtube");
        }
    }

    try {
        const res = await axios.delete(`/series/${seriesId}`);
        await axios.delete(`/seasons/${seasonId}`);
        const res2 = await axios.get('/categories/all_categories');

        if (res.data.status === "deleted") {
            dispatch({ type: types.TOGGLE_EDITING, payload: false });
            dispatch({ type: types.FETCH_ALL_SERIES, payload: res.data.allSeries });
            dispatch({ type: types.RESET_SERIES_FORM, payload: res2.data.categories });
            toast("Series successfully deleted");
        }
    } catch (err) {
        toast("Something went wrong.");
    }
}

export const handleImageUploaded = (input, thumbnail) => dispatch => {
    if (input === "episodeForm") {
        dispatch({ type: types.HANDLE_EPISODE_IMAGE_UPLOADED, payload: thumbnail });
    }

    if (input === "seriesFormVertical") {
        dispatch({ type: types.HANDLE_SERIES_VERTICAL_IMAGE, payload: thumbnail });
    }

    if (input === "seriesFormHorizontal") {
        dispatch({ type: types.HANDLE_SERIES_HORIZONTAL_IMAGE, payload: thumbnail });
    }
}

/*----------------------------------
    EPISODES 
----------------------------------*/
async function dispatchEpisodeUpdates(response, loadingType, dispatch) {
    dispatch({ type: types.FETCH_ALL_SERIES, payload: response.allSeries });
    dispatch({ type: types.UPDATED_SELECTED_SEASONS, seasons: response.updatedSeasons });
    dispatch({ type: types.RESET_EPISODE_FORM });
    dispatch({ type: types.HANDLE_EPISODE_LOADING, loadingType, payload: false });

    const { data } = await axios.get('/tags');
    dispatch({ type: types.GET_ALL_TAGS, payload: data });
}

export const handleEpisodeFileChange = (fileName, fileSize, fileType, file) => ({ type: types.HANDLE_EPISODE_FILE_CHANGE, fileName, fileSize, fileType, file });

export const fillEpisodeForm = (episode, seasonId, seasonNumber) => dispatch => {
    let formatted = episode;

    if (
        typeof formatted.content.videos[0].quality === "string" ||
        typeof formatted.content.videos[0].videoType === "string"
    ) {
        formatted.content.videos[0].quality = { value: formatted.content.videos[0].quality, label: formatted.content.videos[0].quality };
        formatted.content.videos[0].videoType = { value: formatted.content.videos[0].videoType, label: formatted.content.videos[0].videoType };
        dispatch({ type: types.UPDATE_SELECTED_EPISODE, formatted, seasonId, seasonNumber });

    } else {
        dispatch({ type: types.UPDATE_SELECTED_EPISODE, formatted, seasonId, seasonNumber });
    }
}

export const handleEpisodes = (loadingType, episodeData, file) => async dispatch => {
    let formattedEpisodeData;

    formattedEpisodeData = formatData(episodeData);

    dispatch({ type: types.HANDLE_EPISODE_LOADING, loadingType, payload: true });

    if (loadingType == "delete") {
        try {
            const res = await axios.put('/seasons/deleteEpisode', formattedEpisodeData);

            if (res.data.status === "success") {
                toast("Episode successfully deleted");
                dispatchEpisodeUpdates(res.data, loadingType, dispatch);
            }
        } catch (error) {
            toast(`Updating Error: ${error}`);
            dispatch({ type: types.HANDLE_EPISODE_LOADING, loadingType, payload: false });
        }

        return
    }

    if (loadingType === 'create') {
        if (formattedEpisodeData === "invalid") {
            dispatch({ type: types.HANDLE_EPISODE_LOADING, loadingType, payload: false });
            return;
        };

        if (formattedEpisodeData.youtube) {
            const { title, shortDescription } = formattedEpisodeData
            const config = {
                //onUploadProgress: progressEvent => console.log(`Upload progress: ${Math.round((progressEvent.loaded / progressEvent.total * 100))}%`),
                headers: { 'Content-Type': 'multipart/form-data' }
            };

            let fd = new FormData();
            fd.append("file", file, file.name);

            try {
                const temp = await axios.post("/youtube/tempUpload", fd, config);

                if (temp.data === file.name) {

                    let data = {
                        title,
                        description: shortDescription,
                        fileName: temp.data
                    };

                    const youtube = await axios.post('/youtube/createEpisode', data);

                    if (youtube) {
                        console.log("removing temp")
                        formattedEpisodeData.youtubeEpId = youtube.data;
                    }
                }

            } catch (err) {
                console.log(err);
                toast("Something went wrong. Youtube");
                dispatch({ type: types.HANDLE_EPISODE_LOADING, loadingType, payload: false });
            }
        }

        try {
            const res = await axios.put('/seasons/update', formattedEpisodeData);

            if (res.data.status === "success") {
                toast("Episode successfully added");
                dispatchEpisodeUpdates(res.data, loadingType, dispatch);
            }
        } catch (err) {
            console.log(err);
            toast("Something went wrong. WhoHaha OTT");
        }
    }

    if (loadingType === "update") {

        if (formattedEpisodeData === "invalid") {
            dispatch({ type: types.HANDLE_EPISODE_LOADING, loadingType, payload: false });
            return;
        }

        if (formattedEpisodeData.youtube && formattedEpisodeData.youtubeEpId) {

            let data = {
                title: formattedEpisodeData.title,
                description: formattedEpisodeData.shortDescription,
                id: formattedEpisodeData.youtubeEpId
            };

            try {
                const youtubeRes = await axios.put("/youtube/updateVideo", data);

                if (youtubeRes.status === 200) {
                    toast("Youtube successfully updated");
                }
            } catch (err) {
                toast("Something went wrong updating Youtube");
            }
        }

        try {
            const res = await axios.put('/seasons/updateEpisode', formattedEpisodeData);

            if (res.data.status === "success") {
                toast("Episode successfully updated");
                dispatchEpisodeUpdates(res.data, loadingType, dispatch);
            }
        } catch (err) {
            console.log(err)
            toast("Something went wrong updating.");
            dispatch({ type: types.HANDLE_EPISODE_LOADING, loadingType, payload: false });
        }
    }

    dispatch({ type: types.HANDLE_EPISODE_LOADING, loadingType, payload: false });
    return;
}

export const handleEditEpisodeImage = () => ({ type: types.CLEAR_EPISODE_FORM_IMAGE });

export const handleSeriesEditImage = () => ({ type: types.CLEAR_SERIES_FORM_IMAGE });

export const handleSeriesVeritcalEditImage = () => ({ type: types.CLEAR_SERIES_FORM_VERTICAL_IMAGE });

/*----------------------------------
    SEASONS
----------------------------------*/
export const handleAddSeason = (seriesId, seasonNumber) => async dispatch => {
    try {

        const res = await axios.post('/seasons/create', { seriesId, seasonNumber });

        if (res.data.status === "success") {
            toast("Season Successfully Added");
            dispatch({ type: types.FETCH_ALL_SERIES, payload: res.data.allSeries });
            dispatch({ type: types.UPDATED_SELECTED_SEASONS, seasons: res.data.seasons });
            console.log(res.data.seasons)
        }

    } catch (err) {
        console.log(err);
        toast("Something went wrong.");
    }
}

export const handleSelectSeason = (seasonId, seasonNumber) => ({ type: types.SEASON_SELECTED, payload: { seasonId, seasonNumber } });

/*----------------------------------
    AWS
----------------------------------*/
// CANCEL UPLOAD
export const cancelUploadToAWS = () => dispatch => {
    cancel();
    dispatch({ type: types.AWS_UPLOAD_CANCEL });
};

export const uploadVideoToAWS = videoFile => async dispatch => {
    try {
        dispatch({ type: types.AWS_UPLOADING, payload: true });

        //Get AWS presigned URL (SECURITY)
        const uploadConfig = await axios.post('/episode/upload', { fileType: videoFile.type });

        //Upload to AWS
        await axios.put(uploadConfig.data.url, videoFile, {
            headers: {
                'Content-Type': videoFile.type
            },
            onUploadProgress: function (progressEvent) {
                dispatch({
                    type: types.AWS_UPLOADING_PROGRESS,
                    uploadPercentage: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                });
            },
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        dispatch({ type: types.AWS_UPLOAD_SUCCESS, payload: uploadConfig.data.key });

    } catch (err) {
        if (axios.isCancel(err)) {
            dispatch({ type: types.AWS_UPLOAD_CANCEL });
            toast("Video upload canceled");
        } else {
            console.log(err);
            toast("Something went wrong.");
        }
    }
};

export const handleFileStackUploadSuccess = file => dispatch => {
    dispatch({ type: types.FILESTACK_AWS_UPLOAD_SUCCESS, payload: file });
}

export const handleFileStackUploadSuccess720 = file => dispatch => {
    dispatch({ type: types.FILESTACK_AWS_UPLOAD_SUCCESS_720, payload: file });
}

export const handleFileStackUploadSuccess480 = file => dispatch => {
    dispatch({ type: types.FILESTACK_AWS_UPLOAD_SUCCESS_480, payload: file });
}

export const handleBannerUpdate = (seriesId, bannerUrl) => async dispatch => {
    try {
        const BANNER_DATA = {
            seriesId,
            bannerUrl
        };

        const res = await axios.put('/series/updateBanner', BANNER_DATA);

        toast("Banner updated");
        dispatch({ type: types.FETCH_ALL_SERIES, payload: res.data });

    } catch (err) {
        toast("Unable to update banner");
    }
}

export const handleDeleteBannerImage = seriesId => async dispatch => {
    try {
        const BANNER_DATA = {
            seriesId,
            bannerUrl: ""
        };

        const res = await axios.put('/series/updateBanner', BANNER_DATA);

        toast("Banner Removed");
        dispatch({ type: types.FETCH_ALL_SERIES, payload: res.data });

    } catch (err) {
        toast("Unable to delete banner");
    }
}