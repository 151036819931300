import * as types from '../constants/types';

const initialState = {
    user: null,
    userVerified: null,
    roles: [],
    dmReportAuthToken: "",
    isResetToken: null,
    resetTokenMessage: "",
    resetToken: "",
    resetTokenIsSuccess: false
};

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.FETCH_USER:
            return {
                ...state,
                user: action.payload || false,
                userVerified: action.payload.admin
            };
        case types.VERIFY_USER:
            return {
                ...state,
                userVerified: action.payload.isAdmin,
                roles: action.payload.roles
            };
        case types.HANDLE_DAILYMOTION_REPORT_AUTH_TOKEN:
            return {
                ...state,
                dmReportAuthToken: action.payload
            };
        case types.HANDLE_RESET_TOKEN_MESSAGE:
            return {
                ...state,
                resetTokenIsSuccess: false,
                resetTokenMessage: action.payload
            };
        case types.HANDLE_RESET_TOKEN:
            return {
                ...state,
                resetTokenMessage: "",
                resetToken: action.payload
            };
        case types.HANDLE_RESET_TOKEN_MESSAGE_SUCCESS:
            return {
                ...state,
                resetTokenIsSuccess: true,
                resetTokenMessage: action.payload,
                resetToken: ""
            };
        
        default:
            return state;
    }
}
