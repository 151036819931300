import * as types from '../constants/types';

const initialState = {
    allCategories: [],
    podcastCategories: [],
    combinedCategories: [],
    combinedTVCategories: [],
    seriesCategories: [],
    mobileNeedsUpdate: false,
    tvNeedsUpdate: false, 
};

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.FETCH_ALL_CATEGORIES_EDITING:
        case types.FETCH_ALL_CATEGORIES:
            return {
                ...state,
                allCategories: action.payload.allCategories,
                podcastCategories: action.payload.podcastCategories || [],
                seriesCategories: action.payload.seriesCategories || []
            };

        case types.UPDATE_PODCAST_CATEGORIES:
            return {
                ...state,
                podcastCategories: action.payload
            }

        case types.UPDATE_SERIES_CATEGORIES:
            return {
                ...state,
                seriesCategories: action.payload
            };

        case types.FETCH_PODCAST_CATEGORIES:
            return {
                ...state,
                podcastCategories: action.payload
            }
        
        case types.FETCH_COMBINED_CATEGORIES:
            return {
                ...state,
                combinedCategories: action.payload,
                mobileNeedsUpdate: action.mobileNeedsUpdate
            };

        case types.FETCH_COMBINED_TV_CATEGORIES:
            return {
                ...state,
                combinedTVCategories: action.payload,
                tvNeedsUpdate: action.tvNeedsUpdate
            };

        default:
            return state;
    }
};