import React from 'react';
import { connect } from 'react-redux';

import { 
    toggleAddingNewSeries, 
    selectedCategories,  
    toggleEditingBanner,
    toggleViewPodcasts,
    toggleViewAnalytics,
    toggleViewUserAccounts,
    toggleViewReorder,
    toggleViewCopy
} from '../../../../redux/actions/layout';

const LeftSideDrawer = ({ toggleAddingNewSeries, toggleEditingBanner, toggleViewUserAccounts, toggleViewAnalytics,  toggleViewPodcasts, toggleViewReorder, toggleViewCopy, user }) => {

    if(!user) {
        return null;
    }

    if (!user.admin) {
        return null
    }

    if (user.roles === undefined || user.roles.length == 0) {
        // array empty or does not exist
        return null;
    }

    if (user.roles.includes("creator")) {
       return (
            <aside id="sidedrawer-left" className="pt-4">
                <section className="d-flex ">
                    <ul className="d-flex flex-column w-100">
                        <h3 className="p-4 text-center"> Series </h3>
                        <li className="p-4 text-center" onClick={()=>toggleAddingNewSeries(false, false)}>All Series</li>
                    </ul>
                </section>
            </aside>
       )
    }

    return (
        <aside id="sidedrawer-left" className="pt-4">
            <section className="d-flex ">
                <ul className="d-flex flex-column w-100">
                    <h3 className="p-4 text-center"> Series </h3>
                    <li className="p-4 text-center" onClick={()=>toggleAddingNewSeries(false, false)}>All Series</li>
                    <li className="p-4 text-center" onClick={()=>toggleAddingNewSeries(true, false)}>Add New</li>
                    {/* <li className="p-4 text-center" onClick={()=>selectedCategories(true)}>Categories</li> */}
                    <li className="p-4 text-center" onClick={()=>toggleEditingBanner()}>Banners</li>

                    <h3 className="p-4 text-center"> Podcasts </h3>
                    <li className="p-4 text-center" onClick={()=>toggleViewPodcasts("view")}>View Podcasts</li>
                    <li className="p-4 text-center" onClick={()=>toggleViewPodcasts("add")}>Add Podcast</li>
                    <li className="p-4 text-center" onClick={()=>toggleViewPodcasts("socialSharing")}> Social Sharing</li>

                    <h3 className="p-4 text-center"> Reorder </h3>
                    <li className="p-4 text-center" onClick={()=>toggleViewReorder()}>Categories</li>

                    <h3 className="p-4 text-center"> Analytics </h3>
                    <li className="p-4 text-center" onClick={()=>toggleViewAnalytics()}>View Reports</li>
                    <li className="p-4 text-center" onClick={()=>toggleViewUserAccounts()}>View Accounts</li>
                    
                    <h3 className="p-4 text-center"> Copy </h3>
                    <li className="p-4 text-center" onClick={()=>toggleViewCopy("edit")}>Edit</li>
                </ul>
            </section>
        </aside>
    )
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps, {
    toggleAddingNewSeries, 
    selectedCategories,
    toggleEditingBanner,
    toggleViewPodcasts,
    toggleViewAnalytics,
    toggleViewUserAccounts,
    toggleViewReorder,
    toggleViewCopy
})(LeftSideDrawer);