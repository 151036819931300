import { combineReducers } from 'redux';

import auth from './reducer_auth';
import layout from './reducer_layout';
import forms from './reducer_forms';
import series from './reducer_series';
import categories from './reducer_categories';
import analytics from './reducer_analytics';
import podcast from './reducer_podcast';
import accounts from './reducer_accounts';
import copy from './reducer_copies';
import tags from './reducer_tags';

const rootReducer = combineReducers({
    auth,
    layout,
    series,
    forms,
    categories,
    analytics,
    podcast,
    accounts,
    copy,
    tags 
});

export default rootReducer;
