import React from "react";
import { connect } from "react-redux";

import { editCopyForm, updateCopyForm } from "../../../redux/actions/copy";

const CopyForm = ({ copy, editCopyForm, updateCopyForm }) => {
    return (
        <>
            <ul>
                {
                    copy.list.map((item, i) => {
                        return (
                            <li className="mb-5" key={i}>
                                <h2 className="mb-2">{item.label}</h2>
                                <div className="form-group">
                                    <label>
                                        Title
                                    </label>
                                    <input value={item.title} className="form-control" type="text" onChange={e => editCopyForm(copy.list, i, "title", e.target.value)} />
                                </div>
                                <div className="form-group mb-5">
                                    <label>
                                        Message
                                    </label>
                                    <textarea value={item.message}className="form-control" onChange={e => editCopyForm(copy.list, i, "message", e.target.value)}></textarea>
                                </div>
                                <hr/>
                            </li>
                        )
                    })
                }
            </ul>
            <div className="text-right">
                <button className="btn-rounded-pink" onClick={() => updateCopyForm(copy.list, copy.copyId)}>
                    Update
                </button>
            </div>
            
        </>
    )
}

const mapStateToProps = ({ copy }) => ({ copy });

const mapFunctionsToProps = {
    editCopyForm,
    updateCopyForm
};

export default connect(mapStateToProps, mapFunctionsToProps)(CopyForm);