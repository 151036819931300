import * as types from '../constants/types';

const initialState = {
    data: {},
    selectedData: {},
    isViewingData: false,
    pageCount: "",
    error: "",
    name: "",
    selectedUser: {}
};

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.GET_ACCOUNTS:
            return {
                ...state,
                data: action.payload
            };
        case types.GET_SELECTED_ACCOUNT:
            return {
                ...state,
                selectedData: action.payload,
                isViewingData: true,
                selectedUser:action.selectedUser
            };
        case types.CANCEL_ACCOUNT_VIEWING:
            return {
                ...state,
                isViewingData: false
            };
        case types.ACCOUNT_SEARCH_INPUT_CHANGE:
            return {
                ...state,
                name: action.payload
            };
        case types.HANDLE_GET_ACCOUNTS_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};