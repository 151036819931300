//AUTHENTICATION
export const FETCH_USER = 'FETCH_USER';
export const VERIFY_USER ='VERIFY_USER';
export const HANDLE_DAILYMOTION_REPORT_AUTH_TOKEN = 'HANDLE_DAILYMOTION_REPORT_AUTH_TOKEN';
export const HANDLE_RESET_TOKEN_MESSAGE = 'HANDLE_RESET_TOKEN_MESSAGE';
export const HANDLE_RESET_TOKEN = 'HANDLE_RESET_TOKEN';
export const HANDLE_RESET_TOKEN_MESSAGE_SUCCESS = 'HANDLE_RESET_TOKEN_MESSAGE_SUCCESS';
export const RESET_PASSWORD_FORM_INPUT_CHANGE = 'RESET_PASSWORD_FORM_INPUT_CHANGE';

//PLAYLIST
export const FETCH_ALL_PLAYLISTS = 'FETCH_ALL_PLAYLISTS';
export const FETCH_PLAYLIST = 'FETCH_PLAYLIST';
export const EDIT_PLAYLIST_SUCCESS = 'EDIT_PLAYLIST_SUCCESS';
export const EDIT_PLAYLIST_CANCEL = 'EDIT_PLAYLIST_CANCEL';
export const EDIT_PLAYLIST_TOGGLE = 'EDIT_PLAYLIST_TOGGLE';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const TOGGLE_IS_ADDING_SERIES = 'TOGGLE_IS_ADDING_SERIES';

//EPISODE
export const FETCH_PLAYLIST_EPISODE = 'FETCH_PLAYLIST_EPISODE';
export const DELETE_EPISODE_SUCCESS = 'DELETE_EPISODE_SUCCESS';
export const UPLOAD_EPISODE_SUCCESS = 'UPLOAD_EPISODE_SUCCESS';
export const UPDATING_EPISODE = 'UPDATED_EPISODE';
export const SEASON_SELECT = 'SEASON_SELECT';
export const EPISODE_SELECT_PLAYLIST = 'EPISODE_SELECT_PLAYLIST';
export const EPISODE_SELECT_ROKU =  'EPISODE_SELECT_ROKU';

//AWS
export const AWS_UPLOADING = 'AWS_UPLOADING';
export const AWS_UPLOAD_CANCEL = 'AWS_UPLOAD_CANCEL';
export const AWS_UPLOAD_SUCCESS = 'AWS_UPLOAD_SUCCESS';
export const AWS_UPLOAD_FAIL = 'AWS_UPLOAD_FAIL';
export const AWS_UPLOADING_PROGRESS = 'AWS_UPLOADING_PROGRESS';
export const AWS_UPLOAD_NEW_EPISODE = 'AWS_UPLOAD_NEW_EPISODE';
export const FILESTACK_AWS_UPLOAD_SUCCESS = 'FILESTACK_AWS_UPLOAD_SUCCESS';
export const FILESTACK_AWS_UPLOAD_SUCCESS_720 = 'FILESTACK_AWS_UPLOAD_SUCCESS_720';
export const FILESTACK_AWS_UPLOAD_SUCCESS_480 = 'FILESTACK_AWS_UPLOAD_SUCCESS_480';

// ERROR HANDLERS
export const FETCH_PLAYLIST_FAIL = 'FETCH_PLAYLIST_FAIL';
export const UPLOAD_EPISODE_FAIL = 'UPLOAD_EPISODE_FAIL';
export const UPDATE_EPISODE_FAIL = 'UPDATE_EPISODE_FAIL';
export const UPDATE_PLAYLIST_FAIL = 'UPDATE_PLAYLIST_FAIL';
export const DELETE_PLAYLIST_FAIL = 'DELETE_PLAYLIST_FAIL';
export const DELETE_EPISODE_FAIL = 'DELETE_EPISODE_FAIL';

// NOTIFICATIONS
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const LOADING_PLAYLIST = 'LOADING_PLAYLIST';

//SEASONS
export const FETCH_SEASON_EPISODES = 'FETCH_SEASON_EPISODES';
export const RESET_SEASON_EDITING = 'RESET_SEASON_EDITING';
export const SET_DELETABLE_SEASONS = 'SET_DELETABLE_SEASON';
export const REMOVE_DELETABLE_SEASONS = 'REMOVE_DELETABLE_SEASONS';
export const TOGGLE_EDITING_SEASON = 'TOGGLE_EDITING_SEASON';
export const TOGGLE_DELETING_SEASON = 'TOGGLE_DELETING_SEASON';
export const TOGGLE_ADDING_SEASON = 'TOGGLE_ADDING_SEASON';

export const SEASON_SELECTED = 'SEASON_SELECTED';
export const UPDATED_SELECTED_SEASONS = 'UPDATED_SELECTED_SEASONS';

//LAYOUT
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_EDITING = 'TOGGLE_EDITING';
export const TOGGLE_EPISODES_MODAL = 'TOGGLE_EPISODES_MODAL';
export const TOGGLE_CATEGORIES_MODAL = 'TOGGLE_CATEGORIES_MODAL';
export const TOGGLE_MOBILE_SIDEDRAWER = 'TOGGLE_MOBILE_SIDEDRAWER';
export const HANDLE_EPISODE_LOADING = 'HANDLE_EPISODE_LOADING';
export const HANDLE_SERIES_LOADING = 'HANDLE_SERIES_LOADING';
export const HANDLE_EDITING_BANNER = 'HANDLE_EDITING_BANNER';
export const HANDLE_VIEW_PODCAST = 'HANDLE_VIEW_PODCAST';
export const HANDLE_VIEW_PODCAST_BANNER = 'HANDLE_VIEW_PODCAST_BANNER'
export const HANDLE_REORDER_CATEGORIES =  'HANDLE_REORDER_CATEGORIES';
export const TOGGLE_LOADING_DAILYMOTION_REPORT = 'TOGGLE_LOADING_DAILYMOTION_REPORT';
export const TOGGLE_VIEW_EDITING_PODCAST_SUBTITLES = 'TOGGLE_VIEW_EDITING_PODCAST_SUBTITLES';
export const TOGGLE_PODCAST_CATEGORIES_MODAL = 'TOGGLE_PODCAST_CATEGORIES_MODAL';

//SERIES 
export const REORDER_SERIES = 'REORDER_SERIES';
export const REORDER_SERIES_TV = 'REORDER_SERIES_TV';
export const REORDER_SERIES_MOBILE = 'REORDER_SERIES_MOBILE';
export const RESET_SERIES_FORM = 'RESET_SERIES_FORM';
export const EDIT_SELECTED_SERIES = 'EDIT_SELECTED_SERIES';
export const EDIT_SELECTED_SERIES_UPDATE = 'EDIT_SELECTED_SERIES_UPDATE';
export const FETCH_ALL_SERIES = 'FETCH_ALL_SERIES';
export const UPDATE_SERIES_FORM = 'UPDATE_SERIES_FORM';
export const SEARCH_SERIES = 'SEARCH_SERIES';
export const RESET_SERIES_SEARCH = 'RESET_SERIES_SEARCH';
export const CLEAR_SERIES_FORM_IMAGE = 'CLEAR_SERIES_FORM_IMAGE';
export const CLEAR_SERIES_FORM_VERTICAL_IMAGE = 'CLEAR_SERIES_FORM_VERTICAL_IMAGE';

//EPISODES
export const EPISODE_FORM_INPUT_CHANGE = 'EPISODE_FORM_INPUT_CHANGE';
export const RESET_EPISODE_FORM = 'RESET_EPISODE_FORM';
export const UPDATE_SELECTED_EPISODE = 'UPDATE_SELECTED_EPISODE';
export const CLEAR_EPISODE_FORM_IMAGE = 'CLEAR_EPISODE_FORM_IMAGE';

//FORMS 
export const SERIES_FORM_INPUT_CHANGE = 'SERIES_FORM_INPUT_CHANGE';
export const HANDLE_EPISODE_IMAGE_UPLOADED = 'HANDLE_EPISODE_IMAGE_UPLOADED';
export const HANDLE_EPISODE_FILE_CHANGE = 'HANDLE_EPISODE_FILE_CHANGE';

export const HANDLE_SERIES_VERTICAL_IMAGE = 'HANDLE_SERIES_VERTICAL_IMAGE';
export const HANDLE_SERIES_HORIZONTAL_IMAGE = 'HANDLE_SERIES_HORIZONTAL_IMAGE';
export const HANDLE_SERIES_CHECKBOX_CHANGE = 'HANDLE_SERIES_CHECKBOX_CHANGE';
export const HANDLE_SERIES_FEATURED_CHANGE = 'HANDLE_SERIES_FEATURED_CHANGE';
export const HANDLE_SERIES_GENRES_CHANGE = 'HANDLE_SERIES_GENRES_CHANGE';
export const HANDLE_SERIES_SHOW_ON_ROKU_CHANGE = 'HANDLE_SERIES_SHOW_ON_ROKU_CHANGE';
export const HANDLE_SERIES_SHOW_ON_APP_CHANGE = 'HANDLE_SERIES_SHOW_ON_APP_CHANGE';
export const HANDLE_SERIES_BLACKLIST_CHANGE = 'HANDLE_SERIES_BLACKLIST_CHANGE';
export const HANDLE_SERIES_WHITELIST_CHANGE = 'HANDLE_SERIES_WHITELIST_CHANGE';

export const HANDLE_SERIES_DAILYMOTION_CHECKBOX_CHANGE = 'HANDLE_SERIES_DAILYMOTION_CHECKBOX_CHANGE';
export const HANDLE_DAILYMOTION_CHECKBOX_CHANGE = 'HANDLE_DAILYMOTION_CHECKBOX_CHANGE';
export const HANDLE_EPISODE_YOUTUBE_CHECKBOX_CHANGE = 'HANDLE_EPISODE_YOUTUBE_CHECKBOX_CHANGE';
export const HANDLE_SERIES_YOUTUBE_CHECKBOX_CHANGE = 'HANDLE_SERIES_YOUTUBE_CHECKBOX_CHANGE';

//DAILYMOTION
export const DOWNLOADABLE_DAILYMOTION_REPORT = 'DOWNLOADABLE_DAILYMOTION_REPORT';
export const RESET_DOWNLOADABLE_DAILYMOTION_REPORT = 'RESET_DOWNLOADABLE_DAILYMOTION_REPORT';

//PODCAST
export const FETCH_ALL_PODCASTS = "FETCH_ALL_PODCASTS";
export const REORDER_PODCASTS = 'REORDER_PODCASTS';
export const PODCAST_FORM_LOADING = "PODCAST_FORM_LOADING";
export const PODCAST_HANDLE_CHECKBOX_CHANGE = 'PODCAST_HANDLE_CHECKBOX_CHANGE';
export const PODCAST_FORM_RESET = 'PODCAST_FORM_RESET';
export const PODCAST_EDIT_FORM = 'PODCAST_EDIT_FORM';
export const PODCAST_DELETED = 'PODCAST_DELETED';
export const PODCAST_EDIT_IMAGE = 'PODCAST_EDIT_IMAGE';
export const PODCAST_EDIT_MOBILE_FEATURED_IMAGE = 'PODCAST_EDIT_MOBILE_FEATURED_IMAGE';
export const PODCAST_FORM_INPUT_CHANGE = 'PODCAST_FORM_INPUT_CHANGE';
export const PODCAST_HANDLE_IMAGE_UPLOADED = 'PODCAST_HANDLE_IMAGE_UPLOADED';
export const PODCAST_HANDLE_MOBILE_FEATURED_IMAGE_UPLOADED = 'PODCAST_HANDLE_MOBILE_FEATURED_IMAGE_UPLOADED';
export const HANDLE_VIEW_PODCAST_SOCIAL_SHARING = 'HANDLE_VIEW_PODCAST_SOCIAL_SHARING';
export const PODCAST_SOCIAL_VIDEO_SUCCESS = 'PODCAST_SOCIAL_VIDEO_SUCCESS';
export const PODCAST_CATEGORIES_CHANGE = 'PODCAST_CATEGORIES_CHANGE';
export const PODCAST_SEASON_BY_EPISODE_CATEGORIES_CHANGE = 'PODCAST_SEASON_BY_EPISODE_CATEGORIES_CHANGE';
export const PODCAST_GET_RSS = 'PODCAST_GET_RSS';


//PODCAST EPISODE
export const PODCAST_EPISODE_SELECTED = 'PODCAST_EPISODE_SELECTED';
export const PODCAST_EPISODE_SET = 'PODCAST_EPISODE_SET';
export const PODCAST_EPISODE_EDIT_SUBTITLE = 'PODCAST_EPISODE_EDIT_SUBTITLE';
export const PODCAST_EPISODE_SUBTITLE_FORM_INPUT_CHANGE = 'PODCAST_EPISODE_SUBTITLE_FORM_INPUT_CHANGE';
export const PODCAST_EPISODE_SEASON_FORM_INPUT_CHANGE = 'PODCAST_EPISODE_SEASON_FORM_INPUT_CHANGE';
export const PODCAST_EPISODE_SUBTITLE_IMAGE_UPLOADED = 'PODCAST_EPISODE_SUBTITLE_IMAGE_UPLOADED';
export const PODCAST_EPISODE_FORM_RESET = 'PODCAST_EPISODE_FORM_RESET';

//CATEGORIES 
export const SELECT_CATEGORIES = 'SELECT_CATEGORIES';
export const FETCH_ALL_CATEGORIES = 'FETCH_ALL_CATEGORIES';
export const FETCH_ALL_CATEGORIES_EDITING = 'FETCH_ALL_CATEGORIES_EDITING';
export const FETCH_PODCAST_CATEGORIES = 'FETCH_PODCAST_CATEGORIES';
export const UPDATE_PODCAST_CATEGORIES = 'UPDATE_PODCAST_CATEGORIES';
export const FETCH_COMBINED_CATEGORIES = 'FETCH_COMBINED_CATEGORIES';
export const REORDER_CONTENT_CATEGORIES = 'REORDER_CONTENT_CATEGORIES';
export const FETCH_COMBINED_TV_CATEGORIES = 'FETCH_COMBINED_TV_CATEGORIES';

export const PODCAST_CATEGORIES_PLATFORMS = 'PODCAST_CATEGORIES_PLATFORMS';
export const UPDATE_SERIES_CATEGORIES = 'UPDATE_SERIES_CATEGORIES';
export const REORDER_SERIES_CATEGORIES = 'REORDER_SERIES_CATEGORIES';
export const SERIES_CATEGORIES_CHANGE = 'SERIES_CATEGORIES_CHANGE';
export const SERIES_CATEGORIES_PLATFORMS = 'SERIES_CATEGORIES_PLATFORMS';

//ANALYTICS
export const HANDLE_VIEW_ANALYTICS = 'HANDLE_VIEW_ANALYTICS';
export const HANDLE_CSV_STATISTICS = 'HANDLE_CSV_STATISTICS';
export const SUCCESSFULLY_SAVED_CSV_STATISTICS = 'SUCCESSFULLY_SAVED_CSV_STATISTICS';
export const SAVE_SUCCESS_CSV_YOUTUBE = 'SAVE_SUCCESS_CSV_YOUTUBE';
export const HANDLE_ADD_YOUTUBE_CSV = 'HANDLE_ADD_YOUTUBE_CSV';
export const HANDLE_ADD_ROKU_CSV = 'HANDLE_ADD_ROKU_CSV';
export const HANDLE_ADD_DAILYMOTION_CSV = 'HANDLE_ADD_DAILYMOTION_CSV';

//ACCOUNTS
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_SELECTED_ACCOUNT = 'GET_SELECTED_ACCOUNT';
export const HANDLE_GET_ACCOUNTS_ERROR = 'HANDLE_GET_ACCOUNTS_ERROR';
export const HANDLE_GET_ACCOUNTS_SUCCESS = 'HANDLE_GET_ACCOUNTS_SUCCESS';
export const HANDLE_VIEW_USER_ACCOUNTS = 'HANDLE_VIEW_USER_ACCOUNTS';
export const ACCOUNT_SEARCH_INPUT_CHANGE = 'ACCOUNT_SEARCH_INPUT_CHANGE';
export const CANCEL_ACCOUNT_VIEWING= 'CANCEL_ACCOUNT_VIEWING';

//COPY
export const HANDLE_EDIT_COPY = 'HANDLE_EDIT_COPY';
export const HANDLE_EDIT_FORM = 'HANDLE_EDIT_FORM';

//TAGS
export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const UPDATE_SERIES_TAGS = 'UPDATE_SERIES_TAGS';
export const UPDATE_SERIES_EPISODE_TAGS = 'UPDATE_SERIES_EPISODE_TAGS';
export const UPDATE_PODCAST_TAGS = 'UPDATE_PODCAST_TAGS';
export const UPDATE_PODCAST_EPISODE_TAGS = 'UPDATE_PODCAST_EPISODE_TAGS';
