import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactFilestack from 'filestack-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { toast } from 'react-toastify';
import _ from "lodash"

import {
    onPodcastInputChange,
    handlePodcastImageUploaded,
    handlePodcastMobileFeaturedImageUploaded,
    editPodcastImage,
    editPodcastMobileFeaturedImage,
    createPodcast,
    saveEditedPodcast,
    deletePodcast,
    getPodcastRss,
    handleSelectedPodcastEpisode
} from '../../../redux/actions/podcast';

import {
    handleCheckboxChange,
    handlePodcastCategorySelection,
    handleTagSelection,
    handleCategorySelection
} from '../../../redux/actions/forms';
import { toggleView } from '../../../redux/actions/layout';

class PodcastForm extends Component {
    state = {
        didWarn: false
    };

    componentDidMount() {
        const { podcastForm, getPodcastRss } = this.props;

        if (!_.isEmpty(podcastForm.rssFeedUrl)) {
            getPodcastRss(podcastForm.rssFeedUrl);
        }
    }

    _handleOnSubmit = e => {
        e.preventDefault();

        const { createPodcast, podcastForm, podcastType, saveEditedPodcast } = this.props;

        if (podcastForm.podcastPublished) {
            if (this.state.didWarn === false) {
                toast("Warning! Did you set seasons by episode? Please review and submit again.");
                this.setState({ didWarn: true })
                return
            }
        }

        podcastType === "add" ? createPodcast(podcastForm) : saveEditedPodcast(podcastForm)
    }

    _renderButton = () => {
        let submitButton = (
            <button type="submit" className="btn-rounded-pink">
                Submit
            </button>
        );

        const { title, description, author, rssFeedUrl, image, mobileFeaturedImage } = this.props.podcastForm;

        if (this.props.podcastType === "edit") {
            return submitButton
        }

        return (
            <div>
                {
                    !title || !description || !author || !rssFeedUrl || !image || !mobileFeaturedImage
                        ? null
                        : submitButton
                }
            </div>
        )
    }

    _handlePodcastCategorySelection = updatedCategories => {
        this.props.handlePodcastCategorySelection(updatedCategories);
    }

    _handleCategorySelection = updatedCategories => {
        this.props.handleCategorySelection(updatedCategories, "PODCAST_SEASON_BY_EPISODE_CATEGORIES_CHANGE");
    }

    _handleSeasonByEpisodeOptions = (array) => {

        return array;
    }

    _handleTagSelection = tags => {
        this.props.handleTagSelection(tags, "podcast");
    }

    render() {
        const {
            title,
            description,
            author,
            rssFeedUrl,
            image,
            _id,
            featureAsBanner,
            featuredTV,
            featuredMobile,
            podcastPublished,
            mobileFeaturedImage,
            categories,
            podcastId,
            seasonsByEpisode
        } = this.props.podcastForm;

        const {
            handlePodcastImageUploaded,
            deletePodcast,
            loading,
            podcastEpisodes,
            podcastType,
            handlePodcastMobileFeaturedImageUploaded,
            podcastCategories,
            toggleView,
            handleSelectedPodcastEpisode
        } = this.props;

        let formattedSeasonsByEpisode = seasonsByEpisode;

        if (podcastEpisodes && !_.isEmpty(podcastEpisodes)) {

            formattedSeasonsByEpisode = podcastEpisodes.map((episode) => {
                const removeWhiteSpace = episode.guid[0]._.trim();
                const removeTrailingSlash = removeWhiteSpace.replace(/\/$/, "");
                const formattedGuid = removeTrailingSlash.substring(removeTrailingSlash.lastIndexOf('/') + 1);

                return {
                    label: episode.title[0],
                    value: formattedGuid
                }
            });
        }

        return (
            <div id="podcast-form">
                <form onSubmit={this._handleOnSubmit}>

                    <div className="d-flex">
                        <div className="featured d-flex align-items-center mr-4">
                            <input
                                type="checkbox"
                                checked={featuredTV}
                                name={"featuredPodcastTV"}
                                onChange={e => this.props.handleCheckboxChange(e)}
                                className="mr-3"
                            />
                            <p> Featured TV</p>
                        </div>
                        <div className="featured d-flex align-items-center mr-4">
                            <input
                                type="checkbox"
                                checked={featuredMobile}
                                name={"featuredPodcastMobile"}
                                onChange={e => this.props.handleCheckboxChange(e)}
                                className="mr-3"
                            />
                            <p> Featured Mobile </p>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="featured d-flex align-items-center mr-4">
                            <input
                                type="checkbox"
                                checked={podcastPublished}
                                name={"podcastPublished"}
                                onChange={e => this.props.handleCheckboxChange(e)}
                                className="mr-3"
                            />
                            <p> Publish </p>
                        </div>
                    </div>

                    <input
                        type="text"
                        placeholder="Enter Title here"
                        className="title podcast-input"
                        value={title}
                        onChange={e => this.props.onPodcastInputChange("title", e.target.value)}
                    />

                    <div className="d-flex flex-column">
                        <label className="px-3 py-2">Description</label>
                        <textarea
                            className="description"
                            value={description}
                            onChange={e => this.props.onPodcastInputChange("description", e.target.value)}>
                        </textarea>
                    </div>

                    <div className="">
                        <label className="px-3 py-2">Author(s)</label>
                        <input
                            className="author podcast-input"
                            value={author}
                            onChange={e => this.props.onPodcastInputChange("author", e.target.value)}
                        />
                    </div>
                    <div className="">
                        <label className="px-3 py-2">RSS Feed</label>
                        <input
                            className="rss-feed podcast-input"
                            value={rssFeedUrl}
                            onChange={e => this.props.onPodcastInputChange("rssFeedUrl", e.target.value)}
                        />
                    </div>

                    {/* Organize season by episode */}
                    {
                        _.isEmpty(podcastEpisodes) || this.props.podcastType === "add"
                            ? null
                            : (
                                <div className="mb-4">
                                    <label className="px-3 py-2">Season by Episodes</label>
                                    <Select
                                        isMulti
                                        value={seasonsByEpisode}
                                        options={formattedSeasonsByEpisode}
                                        onChange={this._handleCategorySelection}
                                    />
                                </div>
                            )
                    }

                    <div className="mb-4">
                        <label className="px-3 py-2">Categories</label>
                        <Select
                            isMulti
                            value={categories}
                            options={podcastCategories}
                            onChange={this._handlePodcastCategorySelection}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="px-3 py-2">Tags</label>
                        <CreatableSelect
                            isMulti
                            isClearable
                            isDisabled={false}
                            isLoading={false}
                            onChange={this._handleTagSelection}
                            options={this.props.tags.all}
                            value={this.props.podcastForm._tags}
                        />
                    </div>
                    {
                        podcastPublished
                            ? (
                                <div className="mb-4">
                                    <label className="px-3 py-2">Deep Link</label>
                                    <input className="podcast-input" value={`whohaha://podcasts-${podcastId}`} onChange={() => { return }} />
                                </div>
                            )
                            : null
                    }

                    {
                        _.isEmpty(rssFeedUrl) || this.props.podcastType === "add"
                            ? null
                            : (
                                <div className="mb-4">
                                    <label className="px-3 py-2">Episodes</label>
                                    <div className="subtitles-container d-flex align-items-center">
                                        <button className="btn-rounded-pink" onClick={() => {
                                            handleSelectedPodcastEpisode("reset", {})
                                            toggleView("TOGGLE_VIEW_EDITING_PODCAST_SUBTITLES")
                                        }}>Edit</button>
                                    </div>
                                </div>
                            )
                    }

                    <div className="mb-4">
                        <h3 className="mb-2">Thumbnail Image</h3>
                        {
                            image ? (
                                <div className="img-container thumbnail">
                                    <img src={image} className="img-fluid" alt="podcast mobile featured thumbnail" style={{ width: "200px", height: "200px" }} />
                                    <div className="edit-container">
                                        <button onClick={() => this.props.editPodcastImage()}>
                                            <FontAwesomeIcon icon="pencil-alt" color="#fff" />
                                        </button>
                                    </div>
                                </div>
                            )
                                : (
                                    <div className="filestack-podcast">
                                        <ReactFilestack
                                            apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                            buttonText="Set Thumbnail Image"
                                            options={{
                                                accept: 'image/*',
                                                storeTo: {
                                                    location: 's3',
                                                    path: "/images/"
                                                }
                                            }}
                                            onSuccess={response => handlePodcastImageUploaded(response.filesUploaded[0].url)}
                                        />
                                    </div>
                                )
                        }
                    </div>

                    <div className="mb-4">
                        <h3 className="mb-2">Featured Image</h3>
                        {
                            mobileFeaturedImage ? (
                                <div className="img-container featured">
                                    <img src={mobileFeaturedImage} className="img-fluid" alt="podcast thumbnail" style={{ width: "272px" }} />
                                    <div className="edit-container">
                                        <button onClick={() => this.props.editPodcastMobileFeaturedImage()}>
                                            <FontAwesomeIcon icon="pencil-alt" color="#fff" />
                                        </button>
                                    </div>
                                </div>
                            )
                                : (
                                    <div className="filestack-podcast">
                                        <ReactFilestack
                                            apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                            buttonText="Set Featured Image"
                                            options={{
                                                accept: 'image/*',
                                                storeTo: {
                                                    location: 's3',
                                                    path: "/images/"
                                                }
                                            }}
                                            onSuccess={response => handlePodcastMobileFeaturedImageUploaded(response.filesUploaded[0].url)}
                                        />
                                    </div>
                                )
                        }
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
                        {
                            loading
                                ? (
                                    <div className="btn-rounded-pink loading">
                                        Loading ...
                                    </div>
                                )
                                : this._renderButton()
                        }

                        {
                            podcastType !== "edit"
                                ? null
                                : (
                                    <div onClick={e => {
                                        e.preventDefault();
                                        deletePodcast(_id, podcastId);
                                    }} className="btn-delete">
                                        Delete
                                    </div>
                                )
                        }
                    </div>
                </form>
            </div>

        )
    }
}

const mapFunctionsToProps = {
    onPodcastInputChange,
    handlePodcastImageUploaded,
    handlePodcastMobileFeaturedImageUploaded,
    editPodcastImage,
    editPodcastMobileFeaturedImage,
    createPodcast,
    saveEditedPodcast,
    deletePodcast,
    handleCheckboxChange,
    handlePodcastCategorySelection,
    toggleView,
    getPodcastRss,
    handleSelectedPodcastEpisode,
    handleTagSelection,
    handleCategorySelection
};

const mapStateToProps = ({
    forms: { podcastForm },
    podcast: { loading, podcastEpisodes },
    categories: { podcastCategories },
    layout: { isEditingPodcastSubtitles, podcastType },
    tags
}) => ({
    podcastForm,
    podcastCategories,
    loading,
    podcastEpisodes,
    isEditingPodcastSubtitles,
    podcastType,
    tags
});

export default connect(mapStateToProps, mapFunctionsToProps)(PodcastForm);