import * as types from '../constants/types';
import { toast } from 'react-toastify';
import axios from 'axios';
import { parseString } from 'xml2js';
import _ from 'lodash';

import utils from '../../react/helpers/utils';

/******************************************************************
    PODCASTS
/******************************************************************/
export const onPodcastInputChange = (input, text) => ({ type: types.PODCAST_FORM_INPUT_CHANGE, input, text });

export const handlePodcastImageUploaded = (file) => ({ type: types.PODCAST_HANDLE_IMAGE_UPLOADED, payload: file });
export const handlePodcastMobileFeaturedImageUploaded = (file) => ({ type: types.PODCAST_HANDLE_MOBILE_FEATURED_IMAGE_UPLOADED, payload: file });

export const fetchPodcasts = () => async dispatch => {
    const podcastRes = await axios.get("/podcast/fetchPodcasts");
    
    if (podcastRes.status === 500) {
        toast("unable to get podcasts");
        return;
    }

    dispatch({ type: types.FETCH_ALL_PODCASTS, payload: podcastRes.data.allPodcasts });
    //dispatch({ type: types.REORDER_PODCASTS, payload: podcastRes.data.sortedPodcasts });
}

function createImageSet(formData) {
    let data = { ...formData };
    const { image } = data;

    let filestackHandle = image.replace(/(.*\/)*/,"");
    let filestackUrl = "https://cdn.filestackcontent.com";

    if(filestackHandle) {
        let thumbnail = `${filestackUrl}/resize=width:200,height:200/${filestackHandle}`;
        data.thumbnail = thumbnail;
    }
    return data;
}

export const createPodcast = formData => async dispatch => {
    let data = createImageSet(formData);
    let errors = utils.isEmpty(data);

    if(errors.length !== 0){
        toast("please fill in all fields.");
        return; 
    }

    dispatch({ type: types.PODCAST_FORM_LOADING, payload: true });
    const podcastRes = await axios.post("/podcast/createPodcast", data);
    
    if (podcastRes.status === 422) {
        dispatch({ type: types.PODCAST_FORM_LOADING, payload: false });
        toast("unable to save podcast");
        return;
    }

    if (podcastRes.status === 200) {
        toast("Podcast successfully saved");
        dispatch({ type: types.FETCH_ALL_PODCASTS , payload: podcastRes.data.allPodcasts });
        dispatch({ type: types.REORDER_SERIES_TV, payload: podcastRes.data.featuredPodcastTV }); 
        dispatch({ type: types.REORDER_SERIES_MOBILE, payload: podcastRes.data.featuredPodcastMobile });  
        dispatch({ type: types.PODCAST_FORM_RESET });

        const resTag = await axios.get('/tags');
        dispatch({ type: types.GET_ALL_TAGS, payload: resTag.data });
    }
}

export const saveEditedPodcast = formData => async dispatch => {
    let data = createImageSet(formData);
    let errors = utils.isEmpty(data);

    if(errors.length !== 0){
        toast("please fill in all fields.");
        return; 
    }

    dispatch({ type: types.PODCAST_FORM_LOADING, payload: true });

    try {
        const podcastRes = await axios.put("/podcast/saveEditedPodcast", data);

        if (podcastRes.status === 422) {
            dispatch({ type: types.PODCAST_FORM_LOADING, payload: false });
            toast("unable to save podcast");
            return;
        }
    
        if (podcastRes.status === 200) {
            toast("Podcast successfully updated");
            dispatch({ type: types.FETCH_ALL_PODCASTS , payload: podcastRes.data.allPodcasts });
            dispatch({ type: types.REORDER_SERIES_TV, payload: podcastRes.data.featuredPodcastTV }); 
            dispatch({ type: types.REORDER_SERIES_MOBILE, payload: podcastRes.data.featuredPodcastMobile }); 

            const resTag = await axios.get('/tags');
            dispatch({ type: types.GET_ALL_TAGS, payload: resTag.data });
        }
    } catch (err) {
        dispatch({ type: types.PODCAST_FORM_LOADING, payload: false });
        toast("unable to save podcast updates");
    }
}

export const editPodcast = formData => async dispatch => {
    dispatch({ type: types.PODCAST_EDIT_FORM, payload: formData });
}

export const deletePodcast = (_id, podcastId) => async dispatch => {
    const podcastRes = await axios.post("/podcast/deletePodcast", { _id, podcastId });
    
    if (podcastRes.status === 422) {
        toast("unable to delete podcast");
        return;
    }

    if (podcastRes.status === 200) {
        toast("Podcast successfully deleted");
        dispatch({ type: types.FETCH_ALL_PODCASTS , payload: podcastRes.data });
        dispatch({ type: types.PODCAST_DELETED });
    }
}


/******************************************************************
    PODCAST SOCIAL SHARING
/******************************************************************/
export const editPodcastImage = () => ({ type: types.PODCAST_EDIT_IMAGE });
export const editPodcastMobileFeaturedImage = () => ({ type: types.PODCAST_EDIT_MOBILE_FEATURED_IMAGE });

export const socialSharing = (key, actionType) => async dispatch => {
    if (actionType === "update") {
        const res = await axios.put('/podcast/social_sharing', { key });

        dispatch({ type: types.PODCAST_SOCIAL_VIDEO_SUCCESS, payload: res.data });
        toast("New Video Uploaded")
    }
}

export const getSocialSharing = () => async dispatch => {
    const res = await axios.get('/podcast/social_sharing');

    if (res.data.src.length === 0) {
        return 
    }

    let src = `${res.data.cloudUrl}/${res.data.src}`

    dispatch({ type: types.PODCAST_SOCIAL_VIDEO_SUCCESS, payload: src });
}


/******************************************************************
    PODCAST SORTING
/******************************************************************/
function sort(list){
    list.sort(function (a, b) {
        return a.podcastOrderNumber - b.podcastOrderNumber;
    });

    return list;
}

function dispatchReordered(dispatch, sorted, didSort, selectedCategory, platforms) {
    dispatch({ type: types.REORDER_PODCASTS, payload: sorted, didSort, selectedCategory, platforms });
}

export const handlePodcastCategoryPlatformSelection = (selectedPlatforms) => dispatch => {
    dispatch({ type: types.PODCAST_CATEGORIES_PLATFORMS, payload: selectedPlatforms });
}

export const reorderPodcasts = (list, didSort, selectedCategory, platforms) => ({ type: types.REORDER_PODCASTS, payload: list, didSort, selectedCategory, platforms });


export const getMatchingPodcastCategories = (selectedCategory, podcasts) => async dispatch => {
    try {
        const { data } = await axios.get(`/categories/${selectedCategory.value}`);
        const ids = data.ids;
        const platforms = data.platforms || [];
        let sortablePodcasts = [];
      
        for (let i = 0; i < podcasts.length; i++) {
    
            const podcast = podcasts[i];
            const copy = Object.assign({}, podcast);

            for (let j = 0; j < ids.length; j++) {
                if (podcast.podcastId === ids[j] && podcast.podcastPublished !== false){
                    copy.podcastOrderNumber = j;
                    sortablePodcasts.push(copy);
                }
            }
        }
  
        let sorted = sort(sortablePodcasts);

        dispatchReordered(dispatch, sorted, false, selectedCategory, platforms);

    } catch (error) {
        console.log(error);
        toast("ERROR Updating podcast category order.");
    }
} 


/******************************************************************
    PODCAST EPISODES
/******************************************************************/
function sortSubtitles (list){
    list.sort(function (a, b) {
        return a.startTime - b.startTime;
    });

    return list;
}

export const onPodcastEpisodeSubtitleInputChange = (input, text) => ({ type: types.PODCAST_EPISODE_SUBTITLE_FORM_INPUT_CHANGE, input, text });
export const handlePodcastEpisodeSubtitleImageUploaded = (file) => ({ type: types.PODCAST_EPISODE_SUBTITLE_IMAGE_UPLOADED, payload: file });

export const handleSelectedPodcastEpisode = (action , selectedEpisode, data) => async dispatch => {
    try {
        if(action === "edit"){
            const podcastEpisodeId = selectedEpisode.title[0];
            let payload;

            if (_.isEmpty(data)){
                payload = { 
                    _id: "",
                    podcastEpisodeId,
                    startTime: "",
                    endTime: "",
                    content: ""
                 };
            } else {
                payload = {
                    _id: data._id,
                    podcastEpisodeId,
                    startTime: Number(data.startTime),
                    endTime: Number(data.endTime),
                    content: data.content
                };
            }

            const res = await axios.get(`/podcast/getPodcastsEpisode/${podcastEpisodeId}`);
            dispatch({ type: types.PODCAST_EPISODE_FORM_RESET });
            dispatch({ type: types.PODCAST_EPISODE_SET , payload });
            dispatch({ type: types.PODCAST_EPISODE_SELECTED, payload: selectedEpisode, podcastSubtitles: sortSubtitles(res.data) });

            const resTag = await axios.get('/tags');
            dispatch({ type: types.GET_ALL_TAGS, payload: resTag.data });
            return 
        }

        if(action === "create"){
            const podcastEpisodeId = selectedEpisode.link[0].split("/").pop();
            const reqData = {
                _id: data._id,
                podcastEpisodeId,
                startTime: Number(data.startTime),
                endTime: Number(data.endTime),
                content: data.content
            };

            if (Number(data.endTime) < Number(data.startTime)) {
                toast("End time cannot be less than start time.");
                return;
            }

            const errors = utils.isEmpty(reqData);

            if (!_.isEmpty(errors)){
                toast("Please fill in all fields.");
                return;
            }

            const res = await axios.put('podcast/updatePodcastEpisodeSubtitle', reqData );
            dispatch({ type: types.PODCAST_EPISODE_FORM_RESET });
            dispatch({ type: types.PODCAST_EPISODE_SELECTED, payload: selectedEpisode, podcastSubtitles: sortSubtitles(res.data) });

            const resTag = await axios.get('/tags');
            dispatch({ type: types.GET_ALL_TAGS, payload: resTag.data });

            toast("Podcast Episode Subtitle Updated");
            return 
        }

        if(action === "delete"){
            const podcastEpisodeId = selectedEpisode.link[0].split("/").pop();
            const res = await axios.delete(`/podcast/deletePodcastEpisodeSubtitle/${podcastEpisodeId}`);
            dispatch({ type: types.PODCAST_EPISODE_SELECTED, payload: selectedEpisode, podcastSubtitles: sortSubtitles(res.data) });
            toast("Podcast Episode Subtitle Deleted.");
            return 
        }

        if(action === "reset") {
            dispatch({ type: types.PODCAST_EPISODE_FORM_RESET });
            dispatch({ type: types.PODCAST_EPISODE_SELECTED, payload: selectedEpisode, podcastSubtitles: [] });
        }
        
    } catch (error) {
        toast("Podcast Episode Error");
        console.log(error)
    }
}

export const getPodcastRss = rss => async dispatch => {

    try {
        const res = await axios.get(rss);

        parseString(res.data, function(err, result){
            if(err){
                dispatch({ type: types.PODCAST_GET_RSS, payload: [] });
                toast("Could not retrieve podcast episodes.");
                return 
            }
            
            dispatch({ type: types.PODCAST_GET_RSS, payload: result.rss.channel[0].item || [] });
        }) 
    } catch (error) {
        dispatch({ type: types.PODCAST_GET_RSS, payload: [] });
        dispatch({ type: types.PODCAST_EPISODE_SELECTED, payload: {} });
    }    
}
