import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import Thunk from 'redux-thunk';

import reducers from './redux/reducers/index';
import 'flatpickr/dist/themes/airbnb.css';
import 'bootstrap/scss/bootstrap.scss';
import './assets/styles/main.scss';
import App from './react/App';

const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(Thunk))
);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
