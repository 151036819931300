import React from 'react';
import { connect } from 'react-redux';

import { updatePassword, onResetPasswordInputChange } from '../../../redux/actions/auth';

const ResetPasswordForm = ({ token, updatePassword, onResetPasswordInputChange, passwordResetForm, resetTokenMessage }) => (
    <form onSubmit={e => {
        e.preventDefault()
        if (
            resetTokenMessage === "Password reset token is invalid or has expired."
            || resetTokenMessage === "Success! Your password has been changed."
        ) { return }
        updatePassword(token, passwordResetForm) 
    }}>
        <div className="form-group mb-3">
            <label>New Password</label>
            <input value={passwordResetForm.newPassword} type="password" className="form-control" onChange={e=>onResetPasswordInputChange("newPassword", e.target.value)}/>
        </div>
       
        <div className="form-group mb-4">
            <label>Confirm Password</label>
            <input value={passwordResetForm.confirmPassword} type="password" className="form-control" onChange={e=>onResetPasswordInputChange("confirmPassword", e.target.value)} />
        </div>
      
        <button type="submit" className="btn-rounded-pink">Update</button>
    </form>
)

const mapFunctionsToProps = {
    updatePassword,
    onResetPasswordInputChange
};

const mapStateToProps = (
    { forms: { passwordResetForm }, 
    auth: { resetTokenMessage } }
) => ({
    passwordResetForm,
    resetTokenMessage
});

export default connect(mapStateToProps, mapFunctionsToProps)(ResetPasswordForm);