import React from "react";
import { connect } from 'react-redux';
import ReactFilestack from 'filestack-react';
import CreatableSelect from 'react-select/lib/Creatable';
import _ from 'lodash';

import { 
    handleSelectedPodcastEpisode, 
    onPodcastEpisodeSubtitleInputChange, 
    handlePodcastEpisodeSubtitleImageUploaded
} from '../../../redux/actions/podcast';
import { editSubtitle } from '../../../redux/actions/layout';
import { handleTagSelection } from '../../../redux/actions/forms';
import utils from '../../helpers/utils';

const PodcastEpisodes = ({ 
    //variables
    podcastEpisodes,
    podcastEpisodeSelected,
    podcastSubtitles,
    podcastEpisodeForm,
    isEditingPodcastSubtitles,
    tags,
    
    //functions
    handleSelectedPodcastEpisode,
    onPodcastEpisodeSubtitleInputChange,
    handlePodcastEpisodeSubtitleImageUploaded,
    handleTagSelection,
    editSubtitle
}) => {
    if (_.isEmpty(podcastEpisodes)) {
        return null
    }

    return (
        <div id="podcast-episodes">
            <div className="d-flex">
                {
                    podcastEpisodes.length === 0 
                    ? null
                    : <ul className="mr-5">{
                        podcastEpisodes.map( ep =>  {
                            let isActive = false;
                            let activeCheck = _.isEmpty(podcastEpisodeSelected) ? false : true ;

                            if(activeCheck){
                                if (podcastEpisodeSelected.title[0] === ep.title[0]) {
                                    isActive = true
                                }
                            }

                            return (
                                <li key={ep.title[0]} className={`m-1 py-1 px-3 editable ${isActive ? "active": ""}`} onClick={()=> handleSelectedPodcastEpisode("edit",ep) }>
                                    {ep.title[0]} 
                                </li>
                            )}
                        )
                    }
                    </ul>
                }
                {
                    _.isEmpty(podcastEpisodeSelected)
                    ? <h2>Select an episode to begin.</h2>
                    : (
                        <div className="episode-container">
                            <div className="mb-4">
                                <h2>Supplemental Media</h2>     
                            </div>

                            <hr className="mb-5"/>

                            <div className="mb-4">
                                <h3 className="mb-2">Editing:</h3>
                                <p className="title">{podcastEpisodeSelected.title[0]}</p>
                            </div>

                            <hr className="mb-5"/>

                            <div className="mb-4">
                                <h3 className="mb-2">Tags:</h3>
                                <CreatableSelect
                                    isMulti
                                    isClearable
                                    isDisabled={false}
                                    isLoading={false}
                                    onChange={(tags)=>{handleTagSelection(tags, "podcastEpisode")}}
                                    options={tags.all}
                                    value={podcastEpisodeForm._tags}
                                />
                            </div>

                            <hr className="mb-5" />
                          
                            {
                                !isEditingPodcastSubtitles
                                ? null
                                : (
                                    <form className="mb-2" onSubmit={
                                        e => {
                                            e.preventDefault();
                                            handleSelectedPodcastEpisode("create", podcastEpisodeSelected, podcastEpisodeForm);
                                        }
                                    }>
                                        <div className="mb-3">
                                            <label className="mr-2">Start Time (seconds):</label>
                                            <input value={podcastEpisodeForm.startTime} type="number" min="0" required onChange={e => onPodcastEpisodeSubtitleInputChange("startTime", e.target.value)} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="mr-2">End Time (seconds):</label>
                                            <input value={podcastEpisodeForm.endTime} type="number"  min="0"  required onChange={e => onPodcastEpisodeSubtitleInputChange("endTime", e.target.value)}/>
                                        </div>

                                        <div className="mb-3">
                                            <label className="mr-2 mb-3">Content Image:</label>
                                            <ReactFilestack
                                                apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                                buttonText="Set Content Image"
                                                buttonClass="contentImage"
                                                options={{ 
                                                    accept: 'image/*',
                                                    storeTo: {
                                                        location: 's3',
                                                        path: "/images/"
                                                    }
                                                }}
                                                onSuccess={response => handlePodcastEpisodeSubtitleImageUploaded(response.filesUploaded[0].url)}
                                            />
                                            {
                                                !_.isEmpty(podcastEpisodeForm.content)
                                                ? (
                                                    <div className="img-container ">
                                                        <img src={podcastEpisodeForm.content} className="img-fluid" alt="podcast episode content"  />  
                                                    </div>
                                                )
                                                : null
                                            }
                                        </div>
                                        
                                        <div className="d-flex">
                                            <button type="submit" className="btn-rounded-pink mr-2">Save</button>
                                        </div>
                                    </form>

                             
                                )
                            }

                            <div className="mb-5">
                                <button className="btn-rounded-pink grey" onClick={()=>handleSelectedPodcastEpisode("edit", podcastEpisodeSelected)}>Clear</button>
                            </div>

                            <hr className="mb-5" />

                            <div>
                                <h3 className="mb-3">Current Media Times:</h3>
                                {
                                    _.isEmpty(podcastSubtitles)
                                    ? null
                                    : (
                                        <ul className="d-flex flex-column">
                                            {
                                                podcastSubtitles.map(sub => {
                                                    const start = utils.displayTime(sub.startTime);
                                                    const end = utils.displayTime(sub.endTime);

                                                    return (
                                                        <li key={sub._id} className="py-1 px-3 mb-1 subtitle d-flex align-items-center">
                                                            <p className="mx-2" onClick={()=>handleSelectedPodcastEpisode("edit", podcastEpisodeSelected, sub)}>
                                                                {start} - {end}
                                                            </p>
                                                            <button onClick={()=> {
                                                                handleSelectedPodcastEpisode("delete", podcastEpisodeSelected)
                                                            }}>x</button>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const mapFunctionsToProps = {
    handleSelectedPodcastEpisode,
    onPodcastEpisodeSubtitleInputChange,
    handlePodcastEpisodeSubtitleImageUploaded,
    editSubtitle,
    handleTagSelection
};

const mapStateToProps = ({ 
    layout: { isEditingPodcastSubtitles },
    podcast: { podcastEpisodes, podcastEpisodeSelected, podcastSubtitles },
    forms: { podcastEpisodeForm },
    tags

}) => ({ 
    podcastEpisodes,
    podcastEpisodeSelected,
    podcastSubtitles,
    podcastEpisodeForm,
    
    isEditingPodcastSubtitles,
    tags
});

export default connect(mapStateToProps, mapFunctionsToProps)(PodcastEpisodes);