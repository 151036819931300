import * as types from '../constants/types';
import axios from 'axios';

export const toggleRightSidebar = isEditing => ({ type: types.TOGGLE_RIGHT_SIDEBAR, payload: isEditing });

export const toggleDropdown = name => (({ type: types.TOGGLE_DROPDOWN, payload: name }));

export const toggleAddingNewSeries = (isAdding, isEditing, selectedSeries ) => async dispatch => {
    dispatch({ type: types.TOGGLE_IS_ADDING_SERIES, payload: isAdding });
    dispatch({ type: types.TOGGLE_EDITING, payload: isEditing });
    dispatch({ type: types.RESET_SERIES_SEARCH });

    if(isAdding && !isEditing){
        const res = await axios.get('/categories/all_categories');
        dispatch({ type: types.RESET_SERIES_FORM, payload: res.data.categories });
    } 

    if(isEditing) {
        const res = await axios.get(`/seasons/${selectedSeries.id}`);
        const res2 = await axios.get('/categories/all_categories');
        const episodes = res.data[0].episodes;
        let season = res.data;
        let episodesCopy = [...episodes];
        
        const views = episodes.map( ep => {
            return axios
            .get(`/episodes/views/${ep.id}`)
            .then(res => {
                return res.data
            })
            .catch(e => console.log(e))
        })

        Promise.all(views)
            .then( res => {
                for (let i = 0; i < res.length; i++) {
                    for (let k = 0; k < episodes.length; k++) {
                        if(episodesCopy[k]["id"] === res[i].episodeId) {
                            episodesCopy[k]["views"] = res[i].viewCount
                        }
                    }
                }

                //Set episodes for 1 season
                season[0].episodes = episodesCopy
                dispatch({ type: types.EDIT_SELECTED_SERIES, payload: selectedSeries, seasons: season, categories: res2.data.categories });
            })
            .catch( err => {
                dispatch({ type: types.EDIT_SELECTED_SERIES, payload: selectedSeries, seasons: res.data, categories: res2.data.categories });
            });
    } 
}

export const selectedCategories = isSelected => ({ type: types.SELECT_CATEGORIES , payload: isSelected });

export const toggleCategoriesModal = () => ({ type: types.TOGGLE_CATEGORIES_MODAL });

export const toggleEpisodeModal = isEditing => dispatch => {
    if(isEditing) {
        dispatch({ type: types.TOGGLE_EPISODES_MODAL, payload: true });
    } else {
        dispatch({ type: types.TOGGLE_EPISODES_MODAL, payload: false });
        dispatch({ type: types.RESET_EPISODE_FORM });
    }  
}


export const toggleMobileSideDrawer = () => ({ type: types.TOGGLE_MOBILE_SIDEDRAWER });

export const toggleEditingBanner = () => ({ type: types.HANDLE_EDITING_BANNER });

export const toggleViewAnalytics = () => ({ type: types.HANDLE_VIEW_ANALYTICS });

export const toggleViewUserAccounts = () => ({ type: types.HANDLE_VIEW_USER_ACCOUNTS });

export const toggleView = view => ({ type: types[view] });

export const toggleViewReorder = () => ({ type: types.HANDLE_REORDER_CATEGORIES });

export const toggleViewPodcasts = podcastType => dispatch => {

    if(podcastType === "socialSharing") {
        dispatch({ type: types.HANDLE_VIEW_PODCAST_SOCIAL_SHARING, payload: podcastType});
        return;
    }

    if(podcastType === "reorder") {
        dispatch({ type: types.HANDLE_VIEW_PODCAST_BANNER, payload: podcastType});
        return;
    }
    
    dispatch({ type: types.HANDLE_VIEW_PODCAST, payload: podcastType });
    
    if(podcastType === "add") {
        dispatch({ type: types.PODCAST_FORM_RESET });
    }
    
    dispatch({ type: types.HANDLE_VIEW_PODCAST, payload: podcastType });
} 

export const toggleViewCopy = copyType => async dispatch => { 
    const res = await axios.get("/copy/get");
    dispatch({ type: types.HANDLE_EDIT_COPY, payload: copyType, list: res.data.list, copyId: res.data.copyId });
}

export const resetDownloadReports = () => ({ type: types.RESET_DOWNLOADABLE_DAILYMOTION_REPORT });

export const editSubtitle = payload => ({ type: types.PODCAST_EPISODE_EDIT_SUBTITLE, payload });

export const togglePodcastCategoriesModal = payload => dispatch => {
    dispatch({ type: types.TOGGLE_PODCAST_CATEGORIES_MODAL, payload });
}
