import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from "lodash";

import { fetchAllSeries } from '../../../redux/actions/series';
import { toggleAddingNewSeries } from '../../../redux/actions/layout';
import { fetchTags } from '../../../redux/actions/tags';

import {
    fetchPlaylist, 
    cancelEditPlaylist, 
    toggleEditing, 
    uploadEpisode, 
    newUploadEpisode, 
    fetchSeasonEpisodes,
    toggleEditingSeason,
    setDeletableSeasons
} from '../../../redux/actions/playlist';

class SeriesList extends Component {
    async componentDidMount() {
        this.props.fetchAllSeries();
    }

    _renderImagePlaceholder = number => {
        let images = [];

        for (let i = 0; i < number; i++) {
            let newSeries = {
                _id: i,
                title: `Title ${i}`,
                thumbnail: "https://via.placeholder.com/800x450"
            }
        
            images.push(newSeries);
        }

        return images;
    }

    // ==================== RENDER EDITABLE PLAYLISTS ====================
    _renderSeries = series => {
        if (_.isEmpty(series)){ return null }
        const { user } = this.props;
      
        let filterPodcasts = series.filter( item => !item.isPodcast );

        if (user && user.roles.includes("creator")){  
            const creators = filterPodcasts.filter( series => {
                let names = series.publisher.split(","); 
                
                for (let i = 0; i < names.length; i++) {
                    names[i].trim();    
                }

                names = names.map(name => name.trim());
            
                return names.includes(user.googleName);
            })

            filterPodcasts = creators
        }

        let filteredList = filterPodcasts.filter(item => {
            return item.title.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1
        });

        return filteredList.map( item => {
            const { thumbnail, _id, title } = item;

            return (
                <li key={_id} className="col-md-6 col-lg-6 col-xl-3" onClick={()=>{
                    this.props.toggleAddingNewSeries(true, true, item);
                    this.props.fetchTags();
                }}>
                    <img src={thumbnail} className="img-fluid" alt={title} />
                </li>
            )
        })
    }

    // ==================== MAIN RENDER ====================
    render() {
        if(this.props.isEditingCategories 
            || this.props.isEditingBanner 
            || this.props.isViewingAnalytics 
            || this.props.isViewingPodcasts 
            || this.props.isViewingReorderCategories
            || this.props.isViewingCopy){
            return null;
        }

        if(!this.props.allSeries) {
            return <div> loading ... </div>
        }

        // const IMAGE_PLACEHOLDER = this._renderImagePlaceholder(12);
        // const LIST_WITH_PLACEHOLDERS = this.props.allSeries.concat(IMAGE_PLACEHOLDER)

        if(this.props.isAddingNewSeries) {
            return null;
        }

        return (
            <div id="series-list">
                <ul className="row no-gutters">
                    { this._renderSeries(this.props.allSeries) }
                </ul>
            </div>
        )
    }
}

const mapStateToProps = ({ 
        series: { allSeries, search }, 
        layout: { isAddingNewSeries, isEditingCategories, isEditingBanner, isViewingAnalytics, isViewingPodcasts,isViewingReorderCategories, isViewingCopy }
    }) => ({ 
        allSeries,
        search,
        isAddingNewSeries,
        isEditingCategories,
        isEditingBanner,
        isViewingAnalytics,
        isViewingPodcasts,
        isViewingReorderCategories,
        isViewingCopy
})

export default connect(mapStateToProps, {
    fetchPlaylist,
    cancelEditPlaylist,
    toggleEditing,
    uploadEpisode,
    newUploadEpisode,
    fetchSeasonEpisodes,
    toggleEditingSeason,
    setDeletableSeasons,
    fetchTags,

    // new 
    fetchAllSeries,
    toggleAddingNewSeries
})(SeriesList);
