import axios from 'axios';
import * as types from '../constants/types';

export const addSeries = (_data) => async dispatch => { 
    try {
        const res = await axios.put('/roku/api', _data);
        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data });
    } catch (err) {
        console.log(err);
    }
}

const refetchEditedEpisode = async (rokuId, dispatch, seasonId) => {
    const res = await axios.get(`/roku/api/episodes`);
    const editableRokuEpisode = res.data.episodes.filter(item => item.id === rokuId);

    // if(seasonId) {
    //     const res2 = await axios.get('/playlist');
    //     const editableSeason = res.data.seasons.filter(item => item.id === seasonId);
    //     dispatch({ type: types.FETCH_SEASON_EPISODES, payload: editableSeason[0].episodes})
    //     dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res2.data });
    // }
    
    dispatch({ type: types.EPISODE_SELECT_ROKU, payload: editableRokuEpisode[0]});
}

export const selectEpisodeToEdit = (episode) => async dispatch => {
    refetchEditedEpisode(episode.rokuId, dispatch);
}

export const updateSelectedEpisode = (seriesId, seasonId, episode, data) => async dispatch => {
    await axios.put(`/roku/api/${seriesId}/${seasonId}/${episode}`, data);
    refetchEditedEpisode(episode, dispatch, seasonId);
}

export const removeSelectedEpisode = () => {}