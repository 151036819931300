import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';

import { getMatchingPodcastCategories } from "../../../redux/actions/podcast"; 

const PodcastCategoriesList = ({ podcastCategories, allPodcasts, getMatchingPodcastCategories, selectedCategory }) => {
    if (_.isEmpty(podcastCategories)) {
        return null
    }

    return (
        <div id="podcast-categories" className="mb-4">
            <p className="mb-3">
                Click a category to reorder.
            </p>
            <ul className="d-flex flex-wrap">
                {   
                    podcastCategories.map( (category, i) => {
                        return (
                            <li key={i} className={`m-1 py-1 px-3 ${selectedCategory.value === category.value ? "active" : "non-active"}`} onClick={() => getMatchingPodcastCategories(category, allPodcasts) } >
                                <p>{ category.label }</p>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

const addFunctionsToProps = {
    getMatchingPodcastCategories
}

const mapStateToProps = ({ 
    categories: { podcastCategories }, 
    podcast: { allPodcasts, selectedCategory }
}) => ({ podcastCategories, allPodcasts, selectedCategory });

export default connect(mapStateToProps, addFunctionsToProps)(PodcastCategoriesList);