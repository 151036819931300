import React from "react";
import { connect } from "react-redux";
import ReactFilestack from 'filestack-react';
import _ from 'lodash';

import { socialSharing } from '../../../redux/actions/podcast';

const PodcastSocialSharing = ({ socialSharing, socialSharingUrl }) => {
    let currentSrc = socialSharingUrl;

    let videoHTML = (
        <div>
            <h2 className="m-2">Social Sharing Background Video</h2>
            <video key={currentSrc} controls autoPlay muted>
                <source src={currentSrc} type="video/mp4"/>
            </video>  
        </div>
    )

    return (
        <div id="podcast-socials">
            <h2 >Upload New Video</h2>
            <div className="mb-5 filestack-podcast">
                <p>Uploads are for <b>Mobile Only</b>. Portrait videos are MANDATORY.</p>
                <p>Video Dimesions:</p>
                <p> - WIDTH: 1080</p>
                <p className="mb-3"> - HEIGHT: 1920</p>
                <ReactFilestack
                    apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                    buttonText="Upload"
                    buttonClass="btn-rounded-pink"
                    options={{ 
                        accept: 'video/mp4',
                        storeTo: {
                            location: 's3',
                            path: "/"
                        }
                    }}
                    onSuccess={ response => {
                        socialSharing(response.filesUploaded[0].key, "update");
                    }}
                />
            </div>

            <div className="d-md-flex">
                {
                    _.isEmpty(currentSrc) 
                        ? null 
                        : videoHTML
                }
            </div>
        </div>
       
    )
}

const mapStateToProps = ({ podcast: { socialSharingUrl } }) => ({ socialSharingUrl });

const mapFunctionsToProps = { 
    socialSharing
};

export default connect(mapStateToProps, mapFunctionsToProps)(PodcastSocialSharing);