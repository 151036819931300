import axios from 'axios';
import * as types from '../constants/types'

export const fetchUser = () => async dispatch => {
    const res = await axios.get('/auth/current_user');
    dispatch({ type: types.FETCH_USER, payload: res.data });
};

export const verifyUser = (secretKey) => async dispatch => {
    try {
        const res = await axios.post('/auth/verify', { secretKey });

        dispatch({ type: types.VERIFY_USER, payload: res.data });

    } catch(err) {
        dispatch({ type: types.VERIFY_USER, payload: false });
    }
}

export const validateResetToken = token => async dispatch => {
    try { 
        const res = await axios.get(`/auth/reset/${token}`);

        if (res.data.type === "invalid") {
            dispatch({ type: types.HANDLE_RESET_TOKEN_MESSAGE, payload: res.data.message });
            return;
        } 

        dispatch({ type: types.HANDLE_RESET_TOKEN, payload: res.data.type });

    } catch(err) {
        console.log(err)
    }
}

export const updatePassword = (token, passwordResetForm ) => async dispatch => {
    try {
        const { newPassword, confirmPassword } = passwordResetForm;

        if( newPassword === "" ||  confirmPassword === "" ) {
            dispatch({ type: types.HANDLE_RESET_TOKEN_MESSAGE, payload: "Password fields cannot be empty." });
            return;
        }

        if(confirmPassword !== newPassword ) {
            dispatch({ type: types.HANDLE_RESET_TOKEN_MESSAGE, payload: "Passwords do not match." });
            return;
        }

        const res = await axios.post(`/auth/reset/${token}`, { password: newPassword });
        
        if(res.data.type === "invalid"){
            dispatch({ type: types.HANDLE_RESET_TOKEN_MESSAGE, payload: res.data.message });
            return;
        }

        dispatch({ type: types.HANDLE_RESET_TOKEN_MESSAGE_SUCCESS, payload: res.data.message });

    } catch(err) {
        console.log(err);
    }
}

export const onResetPasswordInputChange = (input, text) => ({ type: types.RESET_PASSWORD_FORM_INPUT_CHANGE, input, text }); 

