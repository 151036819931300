import "react-tabs/style/react-tabs.css";

import Flatpickr from 'react-flatpickr'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Accordion, AccordionItem } from 'react-sanfona';
import CreatableSelect from 'react-select/lib/Creatable';
import _ from "lodash";

import EditForm from '../../episodes/forms/EditForm';

import { addSeries } from '../../../../redux/actions/roku';
import { toggleAddingNewSeries, toggleEpisodeModal } from '../../../../redux/actions/layout'
import {
    onSeriesInputChange,
    handleGenreSelection,
    handleSelectSeason,
    fillEpisodeForm,
    handleAddSeason,
    handleBlacklistSelection,
    handleWhitelistSelection,
    handleSeriesCategorySelection,
    handleTagSelection
} from '../../../../redux/actions/forms';

import { fetchTags } from '../../../../redux/actions/tags';

import bootstrap from '../../../helpers/bootstrap';
import supportedGenres from './supportedGenres';
import supportedCountries from './supportedCountries';

Modal.setAppElement('#root');

class SeriesForm extends Component {
    state = {
        open: false,
    }

    componentDidMount() {
        this.props.fetchTags();
    }

    _handleAddEpisode = (seriesId, isEditing, seasonNumber) => {
        this.props.handleSelectSeason(seriesId, seasonNumber);
        if (isEditing) {
            this.props.toggleEpisodeModal(true);
        } else {
            this.props.toggleEpisodeModal(false);
        }
    }

    _handleModal = isEditing => {
        if (isEditing) {
            if (this.props.episodeForm) {

            }

            this.props.toggleEpisodeModal(true);
        } else {
            this.props.toggleEpisodeModal(false);
        }
    }

    _handleEditEpisode = (episode, seriesId, seasonNumber) => {
        this.props.toggleEpisodeModal(true);
        this.props.fillEpisodeForm(episode, seriesId, seasonNumber);
    }

    __handleCollapse = () => {
        this.setState({ open: !this.state.open });
    }

    _handleSeriesCategorySelection = (updatedCategories) => {
        this.props.handleSeriesCategorySelection(updatedCategories);
    }

    _handleTagSelection = (tags) => {
        this.props.handleTagSelection(tags, "series");
    }

    _renderSeason = (user) => {
        let sortedSeason = this.props.seasons.sort((a, b) => (a.seasonNumber > b.seasonNumber) ? 1 : -1);

        return sortedSeason.map(season => {
            let sorted = season.episodes.sort((a, b) => (a.episodeNumber > b.episodeNumber) ? 1 : -1);

            return (
                <AccordionItem className="accordion-item" key={season._id} title={`Season ${season.seasonNumber}`} expanded={season.seasonNumber}>
                    <div>
                        <div className="accordion-icon">
                            <FontAwesomeIcon icon="chevron-down" size="2x" color="#666666" />
                        </div>
                        <div className="season-container">
                            {
                                season.episodes.length !== 0
                                    ? (
                                        sorted.map(episode => {
                                            return (
                                                <div key={episode.title} className="season-edit p-4">
                                                    <div className="row">
                                                        <div className="col-xl-3">
                                                            <img className="img-fluid" src={episode.thumbnail} alt={episode.title} />
                                                        </div>

                                                        <div className="col-xl-9 pb-0 pt-4 p-lg-4">
                                                            <p className="mb-2">
                                                                {this.props.title} - Episode {episode.episodeNumber}: - "{episode.title}"
                                                            </p>
                                                            {
                                                                user && user.roles.includes("creator")
                                                                    ? null
                                                                    : (
                                                                        <p className="mb-2">
                                                                            VideoFile: <span>{episode.content.videos[0].url}</span>
                                                                        </p>
                                                                    )
                                                            }
                                                            <p>
                                                                Views: {episode.views}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {
                                                        user && user.roles.includes("creator")
                                                            ? null
                                                            : (
                                                                <button
                                                                    className="btn-rounded-pink sm"
                                                                    onClick={() => this._handleEditEpisode(episode, season.seriesId, season.seasonNumber)}>
                                                                    Edit
                                                                </button>
                                                            )
                                                    }
                                                </div>
                                            )
                                        })
                                    )
                                    : (
                                        <p className={`season-message py-5 px-2 mb-5 ${bootstrap.center}`}>
                                            Click the "Add Episode" button below to start building the season
                                        </p>
                                    )
                            }
                            {
                                user && user.roles.includes("creator")
                                    ? null
                                    : (
                                        <div className="text-right mb-4 mt-5">
                                            <button
                                                className="btn-rounded-pink sm"
                                                onClick={() => this._handleAddEpisode(season.seriesId, false, season.seasonNumber)}>
                                                ADD EPISODE
                                        </button>
                                        </div>
                                    )
                            }

                        </div>
                    </div>
                </AccordionItem>

            )
        })
    }

    _handleDateChange = seriesReleaseDate => {
        let seriesIsoDateFormat = moment(new Date(seriesReleaseDate)).format('YYYY-MM-DD');
        this.props.onSeriesInputChange("releaseDate", seriesIsoDateFormat);
    }

    _handleEnterKeyDown = e => {
        if (e.key === 'Enter') {

        } else {
            return
        }
    }

    // _formatTags = () => {
    //     const tags = this.props.seriesForm._tags;
    //     const formattedTags = []

    //     for (let i = 0; i < tags.length; i++) {
    //         let tag = {};
    //         tag.value = tags[i].value
    //         tag
    //     }       
    // }

    render() {
        const { isEditing, seasonForm, user, toggleAddingNewSeries } = this.props;

        if (user && user.roles.includes("creator")) {
            return (
                <div id="series-form">
                    <h1 className="mb-4">
                        {this.props.title}
                    </h1>
                    <Tabs>
                        <TabList>
                            <Tab>Videos</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="description-container">
                                <Accordion>
                                    {this._renderSeason(user)}
                                </Accordion>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            )
        }

        return (
            <div id="series-form">
                <div className="d-flex align-items-center mb-5">
                    <h1 className="mr-4">
                        {
                            isEditing ? "Edit Series" : "Add New Series"
                        }
                    </h1>
                    <button className="btn-rounded-pink" onClick={() => toggleAddingNewSeries(false, false)}>
                        Back
                    </button>
                </div>

                <div className="input-group mb-5">
                    <input
                        value={this.props.title}
                        type="text"
                        placeholder="Enter title here"
                        required
                        onChange={e => this.props.onSeriesInputChange("title", e.target.value)}
                    />
                </div>
                <Tabs>
                    <TabList>
                        <Tab>Description</Tab>
                        {!isEditing ? null : <Tab>Videos</Tab>}
                    </TabList>

                    <TabPanel>
                        <div className="description-container">
                            <div className="mb-3">
                                <label className="px-3 py-2">Main Description</label>
                                <textarea
                                    className="description"
                                    maxLength="200"
                                    value={this.props.shortDescription}
                                    onChange={e => this.props.onSeriesInputChange("shortDescription", e.target.value)}>
                                </textarea>
                            </div>
                            <div className="mb-4">
                                <label className="px-3 py-2">Genres</label>
                                <Select
                                    value={this.props.genres}
                                    options={supportedGenres}
                                    onChange={selectedGenres => this.props.handleGenreSelection(selectedGenres)}
                                    isMulti
                                />
                            </div>
                            <div className="mb-3">
                                <label className="px-3 py-2">Cast</label>
                                <textarea
                                    value={this.props.cast}
                                    onChange={e => this.props.onSeriesInputChange("cast", e.target.value)}>
                                </textarea>
                            </div>
                            <div className="mb-3">
                                <label className="px-3 py-2">Publisher</label>
                                <textarea
                                    value={this.props.publisher}
                                    onChange={e => this.props.onSeriesInputChange("publisher", e.target.value)}>
                                </textarea>
                            </div>
                            <div className="mb-4">
                                <label className="px-3 py-2">Release Date</label>
                                <Flatpickr
                                    value={this.props.releaseDate}
                                    onChange={seriesReleaseDate => this._handleDateChange(seriesReleaseDate)}
                                    options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: 'Y-m-d'
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="px-3 py-2">Blacklist (country)</label>
                                <Select
                                    key={this.props.blacklist.value}
                                    value={this.props.blacklist}
                                    isDisabled={this.props.isWhitelist ? true : false}
                                    label="Select Countries"
                                    options={supportedCountries}
                                    onChange={supportedCountries => this.props.handleBlacklistSelection(supportedCountries)}
                                    isMulti
                                />
                            </div>
                            <div className="mb-4">
                                <label className="px-3 py-2">Categories</label>
                                <Select
                                    value={this.props.seriesForm.seriesCategories}
                                    options={this.props.categories.seriesCategories}
                                    onChange={this._handleSeriesCategorySelection}
                                    isMulti
                                />
                            </div>
                            <div className="mb-4">
                                <label className="px-3 py-2">Tags</label>
                                <CreatableSelect
                                    isMulti
                                    isClearable
                                    isDisabled={false}
                                    isLoading={false}
                                    onChange={this._handleTagSelection}
                                    options={this.props.tags.all}
                                    value={this.props.seriesForm._tags}
                                />
                            </div>
                            {
                                this.props.seriesForm.published
                                    ? (
                                        <div className="mb-4">
                                            <label className="px-3 py-2">Deep Link</label>
                                            <textarea value={`whohaha://series-${this.props.seriesForm.id}`} onChange={() => { return }} />
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                    </TabPanel>
                    {
                        !isEditing
                            ? null
                            : (
                                <TabPanel>
                                    <div className="description-container">
                                        <Accordion>
                                            {this._renderSeason()}
                                        </Accordion>
                                    </div>
                                    <div className="text-right mb-3">
                                        <OverlayTrigger
                                            trigger="click"
                                            rootClose={true}
                                            key="bottom"
                                            placement="bottom"
                                            overlay={
                                                <Popover
                                                    id={`popover-positioned-bottom`}
                                                    title={`Do you want to add a season?`}>
                                                    <button
                                                        className="btn-rounded-pink sm"
                                                        onClick={() => this.props.handleAddSeason(seasonForm.seriesId, seasonForm.seasonNumberIfAdded)}>
                                                        Yes
                                                </button>
                                                </Popover>
                                            }>
                                            <button className="btn-rounded-pink sm" >
                                                ADD SEASON
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </TabPanel>
                            )
                    }
                </Tabs>
                <Modal
                    isOpen={this.props.editEpisodesModal}
                    onRequestClose={() => this._handleModal(this.props.isEditingEpisode)}
                    className="modal-react episodes"
                    overlayClassName="modal-overlay-categories"
                >
                    <EditForm />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({
    forms: { seriesForm: { title, shortDescription, cast, publisher, releaseDate, genres, seasons, blacklist, whitelist, isWhitelist }, seriesForm, seasonForm, episodeForm },
    layout: { isEditing, isEditingEpisode, editEpisodesModal },
    categories,
    tags
}) => ({
    title,
    shortDescription,
    cast,
    publisher,
    isEditing,
    releaseDate,
    genres,
    seasons,
    blacklist,
    whitelist,
    isWhitelist,
    isEditingEpisode,
    editEpisodesModal,
    categories,
    seriesForm,
    seasonForm,
    episodeForm,
    tags
});

const funtionsToProps = {
    addSeries,
    toggleAddingNewSeries,
    onSeriesInputChange,
    toggleEpisodeModal,
    handleGenreSelection,
    handleSelectSeason,
    handleAddSeason,
    fillEpisodeForm,
    handleBlacklistSelection,
    handleWhitelistSelection,
    handleSeriesCategorySelection,
    handleTagSelection,
    fetchTags
}

export default connect(mapStateToProps, funtionsToProps)(SeriesForm);


/* <div className="form-group">
    <label>Number of Seasons</label>
    <input value={numberOfSeasons} className="form-control" type="number" min="1" required onChange={ e => this.setState({ numberOfSeasons: e.target.value })}/>
</div> */


