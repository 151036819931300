import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import CategoriesForm from './forms/CategoriesForm';
import CategoriesList from './CategoriesList';

import { toggleCategoriesModal } from '../../../redux/actions/layout';

Modal.setAppElement('#root');

const CategoriesComponent = ({ editCategoriesModal, toggleCategoriesModal }) => {
    return (
        <div id="categories-component">
            <h1 className="mb-4">Edit Categories</h1>
            {/* <CategoriesForm /> */}
            <Modal
                isOpen={editCategoriesModal}
                onRequestClose={()=>toggleCategoriesModal()}
                className="modal-react categories"
                overlayClassName="modal-overlay-categories"
            >
                <CategoriesForm />
            </Modal>

            <CategoriesList />
        </div>
    )
}

const mapStateToProps = ({ layout: { editCategoriesModal }}) => ({ editCategoriesModal });

export default connect(mapStateToProps, { toggleCategoriesModal })(CategoriesComponent);