import * as types from '../constants/types';

const initialState = {
    all: []
};

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.GET_ALL_TAGS:
            return {
                ...state,
                all: action.payload
            };

        default:
            return state;
    }
}