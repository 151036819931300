import axios from 'axios';
import * as types from '../constants/types'

/*============================================================
  AWS
============================================================*/
// RESET UPLOAD PERCENTAGE
export const fetchAllPlaylist = () => async dispatch => {
    const res = await axios.get('/playlist');
    dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data });
};

/*============================================================
  PLAYLISTS
============================================================*/
// FETCH PLAYLIST
export const fetchPlaylist = playlistId => async dispatch => {
    try {
        const res = await axios.get(`/playlist/${playlistId}`);
        dispatch({ type: types.FETCH_PLAYLIST, payload: res.data });
    } catch(err) {
        console.log(err);
    }
};

// CANCEL PLAYLIST EDIT
export const cancelEditPlaylist = () => dispatch => {
    dispatch({ type: types.RESET_SEASON_EDITING });
    dispatch({ type: types.EDIT_PLAYLIST_CANCEL });
};

// UPDATE PLAYLIST
export const updatePlaylist = (playlistId, playlistData) => async dispatch => {
    try {
        const res = await axios.put(`/playlist/update/${playlistId}`, playlistData );

        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data.playlists });
        dispatch({ type: types.FETCH_PLAYLIST, payload: res.data.playlist });
        dispatch({ type: types.RESET_SEASON_EDITING });
        
        //notify user updated
        dispatch({ type: types.EDIT_PLAYLIST_SUCCESS });
    } catch (err) {
        dispatch({ type: types.UPDATE_PLAYLIST_FAIL, payload: err });
    }
};

// DELETE PLAYLIST
export const deletePlaylist = playlistId => async dispatch => {
    try {
        const res = await axios.delete(`/playlist/delete/${playlistId}`);
        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data });
        dispatch({ type: types.DELETE_PLAYLIST_SUCCESS });
    } catch(err) {
        dispatch({ type: types.DELETE_PLAYLIST_FAIL });
    }
}

// TOGGLE PLAYLIST PUBLISHED
export const togglePublished = (playlistId, rokuId, shouldPublish) => async dispatch => {
    try {
        const res = await axios.put(`/playlist/publish/${playlistId}`, {shouldPublish, rokuId});
        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data.playlists });
        dispatch({ type: types.FETCH_PLAYLIST, payload: res.data.playlist });
    } catch (err){
        console.log(err);
    }
}

/*============================================================
  EPISODES
============================================================*/
export const fetchSeasonEpisodes = (season, selectType) => async dispatch => {
    try {
        if(selectType === 'upload'){
            dispatch({ type: types.SEASON_SELECT, payload: season });
        } else {
            dispatch({ type: types.FETCH_SEASON_EPISODES, payload: season.episodes, id: season.id || season._id});
        }
    } catch (err) {
        console.log(err);
    }
}

// UPLOAD EPISODE TO PLAYLIST
export const uploadEpisode = (playlistId, episodeData) => async dispatch => {
    try {
        const res = await axios.put(`/episode/upload/${playlistId}`, episodeData );
        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data });
        dispatch({ type: types.UPLOAD_EPISODE_SUCCESS });
    } catch (err) {
        dispatch({ type: types.UPLOAD_EPISODE_FAIL, payload: err });
    }
};

// NEW UPLOAD EPISODE
export const newUploadEpisode = () => dispatch => {
    dispatch({ type: types.AWS_UPLOAD_NEW_EPISODE });
};

// UPLOAD EPISODE TO PLAYLIST
export const updateEpisode = (episodeId, episodeData) => async dispatch => {
    try {
        const res = await axios.put(`/episode/update/${episodeId}`, episodeData );

        if(res.status === 204) {
        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data });
        } else {
        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data.playlists });
        dispatch({ type: types.FETCH_PLAYLIST_EPISODE, payload: res.data.episode });
        }

    } catch (err) {
        dispatch({ type: types.UPDATE_EPISODE_FAIL, payload: err });
    }
};

// TOGGLE EDITING EPISODE
export const toggleEditing = isEditing => dispatch => {
    dispatch({ type: types.RESET_SEASON_EDITING });
    dispatch({ type: types.EDIT_PLAYLIST_TOGGLE, payload: isEditing });
};

// DELETE EPISODE
export const deleteEpisode = data => async dispatch => {
    try {
        const res = await axios.put(`/roku/api/remove_episode`, data);
        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data });
        dispatch({ type: types.TOGGLE_EDITING_SEASON, isEditing: false, seriesId : "", seriesTitle: "", seasonNumber: "" });
    } catch (err) {
        console.log(err);
    }
}

/*============================================================
  NOTIFICATION
============================================================*/
// RESET NOTIFICATION
export const resetNotification = () => dispatch => {
    dispatch({ type: types.RESET_NOTIFICATION });
}

/*============================================================
  SEASONS
============================================================*/
export const toggleEditingSeason = (isEditing, seriesId, seriesTitle, seasonNumber) => dispatch => {
   dispatch({ type: types.TOGGLE_EDITING_SEASON, isEditing, seriesId, seriesTitle, seasonNumber });

   if(!isEditing) {
       dispatch({ type:types.REMOVE_DELETABLE_SEASONS });
   }
}

export const toggleDeletingSeason = () => ({ type: types.TOGGLE_DELETING_SEASON });
export const toggleAddingSeason = () => ({ type: types.TOGGLE_ADDING_SEASON });
export const setDeletableSeasons = deletableSeasons => ({ type: types.SET_DELETABLE_SEASONS, deletableSeasons })

export const addSeason = (seriesId, seasonNumber) => async dispatch => {
    try {
        const res = await axios.put('/roku/api/add_season', { seriesId, seasonNumber });
        dispatch({ type: types.FETCH_ALL_PLAYLISTS, payload: res.data });
        dispatch({ type: types.TOGGLE_EDITING_SEASON, isEditing: false, seriesId : "", seriesTitle: "", seasonNumber: "" });
    } catch(err) {
        console.log(err);
    } 
}

export const deleteSeason = (seriesId, seasonId) => async dispatch => {
    try {
        await axios.put('/roku/api/delete_season', { seriesId, seasonId });
        window.location.reload(true);
       
    } catch (err) {
        console.log(err);
    }
}
