import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, AccordionItem } from 'react-sanfona';
import ReactFilestack from 'filestack-react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { handleBannerUpdate, handleDeleteBannerImage } from '../../../redux/actions/forms';
import BannerSortable from './BannerSortable';
import BannerMain from './BannerMain';

class BannerComponent extends  Component {
    state = {
        _id: "",
        bannerUrl: "",
        readyForUpdate: false,
        isEditingBanner: false
    }

    _handleBannerUploadSuccess = response => {
        this.setState({ readyForUpdate: true, bannerUrl: response.filesUploaded[0].url });
        toast("Please save new changes");
    }

    _handleBannerUploadError = () => {
        toast("Unable to update Banner");
    }

    _handleBannerUpdate = () => {
        this.props.handleBannerUpdate(this.state._id, this.state.bannerUrl);
        this.setState({ readyForUpdate: false, bannerUrl: "", isEditingBanner: false });
    }

    _handleEditBannerImage = () => {
        this.setState({ isEditingBanner: true });
    }

    _setSeries = selectedSeries => {
        this.setState({ _id: selectedSeries._id });
    }

    render() {
        if (!this.props.reorderedSeriesTV || !this.props.reorderedSeriesMobile) {
            return null;
        }

        return (
            <div id="banner">
                <h1 className="mb-5">
                    Banners
                </h1>

                <div className="mb-5">
                    <h2 className="mb-3">
                        Apple TV Main Banner (1920 × 768) 
                    </h2>
                    <BannerMain />
                </div>

                <div className="mb-3">
                    <h2 className="mb-3">
                        Apple TV Banners
                    </h2 >
                    <section className="mb-5">
                        <Accordion>
                            {
                                this.props.reorderedSeriesTV.map( item => (
                                    <AccordionItem 
                                        title={`${item.title}`} 
                                        expanded={item === 1} 
                                        key={item._id}
                                        onExpand={() => this._setSeries(item)}
                                        >
                                        <div>
                                            <div className="banner-container">
                                                <div className="banner-upload mb-4 d-flex flex-column">
                                                    <label className="mb-2">
                                                        TV
                                                    </label>
                                                    <p className="mb-3">
                                                        minimum dimensions 1500 x 354
                                                    </p>
                                                        {
                                                            !item.bannerUrl || this.state.isEditingBanner
                                                                ? (
                                                                    <div>
                                                                        <span className="mr-4">Upload File</span>
                                                                        <ReactFilestack
                                                                            apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                                                            buttonText="Choose File"
                                                                            buttonClass="banner btn-rounded-grey py-2 px-3"
                                                                            options={{ 
                                                                                accept: 'image/*',
                                                                                storeTo: {
                                                                                    location: 's3',
                                                                                    path: "/banners/"
                                                                                },
                                                                                transformations: {
                                                                                    crop: {
                                                                                    aspectRatio: 16/9,
                                                            
                                                                                    },
                                                                                    circle: false
                                                                                }
                                                                            }}
                                                                            onSuccess={this._handleBannerUploadSuccess}
                                                                            onError={this._handleBannerUploadError}
                                                                        />
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div className="banner-edit-container">
                                                                        <img src={item.bannerUrl} className="img-fluid" alt={item.title} />
                                                                        <button className="edit" onClick={this._handleEditBannerImage}>
                                                                            <FontAwesomeIcon icon="pencil-alt" color="#fff" />
                                                                        </button >
                                                                        <button className="delete" onClick={()=>this.props.handleDeleteBannerImage(item._id, item.bannerUrl)}>
                                                                            <FontAwesomeIcon icon="times" color="#fff" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                        }
                                                </div>
                                                {
                                                    item.bannerUrl || this.state.bannerUrl 
                                                        ? (
                                                            <div className="banner-url">
                                                                <label>
                                                                    Banner URL
                                                                </label>
                                                                {
                                                                    this.state._id === item._id && item.bannerUrl
                                                                        ? <span>{item.bannerUrl}</span>
                                                                        : <span>{this.state.bannerUrl}</span>
                                                                }
                                                            </div>
                                                        )
                                                        : null
                                                }
                                            
                                                {
                                                    this.state.readyForUpdate
                                                    ? (
                                                        <div className="text-right">
                                                            <button className="save btn-rounded-pink mt-4" onClick={this._handleBannerUpdate}>
                                                                Save
                                                            </button>
                                                        </div>
                                                    )
                                                    : null
                                                }
                                                
                                            </div>
                                            <div className="accordion-icon">
                                                <FontAwesomeIcon icon="chevron-down" size="2x" color="#666666" />
                                            </div>
                                        </div>
                                    </AccordionItem>
                                ))
                            }
                        </Accordion>
                    </section>
                </div>
                
                <div className="d-md-flex">
                    <div className="m-2">
                        <h2 className="mb-2">
                            Reorder Mobile Featured
                        </h2 >
                        <p className="mb-3">
                            Drag & drop to sort series featured order.
                        </p>
                        <BannerSortable series={this.props.reorderedSeriesMobile} platform="mobile" />
                    </div>
                    <div className="m-2">
                        <h2 className="mb-2">
                            Reorder TV Featured
                        </h2 >
                        <p className="mb-3">
                            Drag & drop to sort series featured order.
                        </p>
                        <BannerSortable series={this.props.reorderedSeriesTV} platform="tv" />
                    </div>

                </div>
                
            </div>
        )
    }
}

const mapStateToProps = ({ 
    series : { reorderedSeriesMobile, reorderedSeriesTV }
}) => ({ reorderedSeriesMobile, reorderedSeriesTV });

export default connect(mapStateToProps, { 
    handleBannerUpdate,
    handleDeleteBannerImage
} )(BannerComponent);