import React, { Suspense }from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toggleAddingNewSeries } from '../../redux/actions/layout';
import { onSearchChange } from '../../redux/actions/series';
import bootstrap from '../helpers/bootstrap'

import SeriesForm from './series/forms/SeriesForm';
import CategoriesComponent from './categories/CategoriesComponent';
import BannerComponent from './banners/BannerComponent';
import UserAccountsContainer from './accounts/UserAccountsContainer';
import PodcastComponent from './podcasts/Podcast';
import ReorderComponent from './reorder/Reorder';
import CopyComponent from "./copy/CopyComponent";

const AnalyticsComponent = React.lazy(() => import('./analytics/AnalyticsContainer'));

const MainComponent = ({  
    isAddingNewSeries, 
    toggleAddingNewSeries, 
    isEditingCategories, 
    isEditingBanner,  
    isViewingAnalytics,
    isViewingPodcasts,
    isViewingUserAccounts,
    isViewingReorderCategories, 
    isViewingCopy,
    search,
    user
}) => {

    const renderComponent = () => {
        if(isViewingCopy) {
            return <CopyComponent />
        }

        if (isEditingCategories) {
            return <CategoriesComponent />
        }

        if (isViewingPodcasts)  {
            return <PodcastComponent />
        }

        if(isEditingBanner) {
            return <BannerComponent />
        }
    
        if (isViewingAnalytics) {
            return (
                <div id="analyticsContainer">
                    <h1 className="mb-4">
                        Analytics
                    </h1>
                    <Suspense fallback={<div>Loading ...</div>}>
                        <AnalyticsComponent />
                    </Suspense>
                </div>
                
            )
        }

        if (isViewingUserAccounts) {
            return <UserAccountsContainer />
        }
        
        if (isViewingReorderCategories){
            return <ReorderComponent />
        }

        if(!isAddingNewSeries) {
            return (
                <div className={`series-container ${bootstrap.between} flex-fill`}>
                    <div className="d-flex align-items-center">
                        <h1 className="mr-4"> All Series </h1>
                        {
                            user && user.roles.includes("creator")
                            ? null
                            : <button className="btn-rounded-pink" onClick={()=>toggleAddingNewSeries(true, false)}>ADD NEW</button>
                        }
                        
                    </div>
                    {/* <div className="series-searchbar">
                        <input value={search} placeholder="Search Series..." className="input-rounded-white" onChange={e=>onSearchChange(e.target.value)} /> 
                        <FontAwesomeIcon icon="search" size="2x" className="icon-search" color="#666666" />
                    </div> */}
                </div>
            )
        }

        return <SeriesForm user={user} />
    }

    return (
        <section id="series-section">
            { renderComponent() }
        </section>
    );
}

const mapStateToProps = ({ 
        layout: {
            isAddingNewSeries,
            isEditingCategories, 
            isEditingBanner, 
            isViewingAnalytics, 
            isViewingPodcasts, 
            isViewingUserAccounts,
            isViewingReorderCategories,
            isViewingCopy
        }, 
        series: { 
            search 
        }, 
    }) => ({ 
        isAddingNewSeries,
        isEditingCategories,
        isEditingBanner,
        isViewingAnalytics,
        isViewingPodcasts,
        isViewingUserAccounts,
        isViewingReorderCategories,
        isViewingCopy,
        search
});

export default connect(mapStateToProps, {
    toggleAddingNewSeries,
    onSearchChange
})(MainComponent);
