import * as types from '../constants/types';

const initialState = {
    allPodcasts: [],
    reorderedPodcasts: [],
    search: "",
    loading: false,
    socialSharingUrl: "",
    didSort: false,
    selectedCategory: {},
    podcastEpisodes: [],
    podcastEpisodeSelected: {},
    podcastSubtitles: [],
    platforms: [],
};

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.FETCH_ALL_PODCASTS:
            return {
                ...state,
                allPodcasts: action.payload,
                loading: false
            };
            
        case types.REORDER_PODCASTS:
            return {
                ...state,
                reorderedPodcasts: action.payload,
                didSort: action.didSort,
                selectedCategory: action.selectedCategory,
                platforms: action.platforms
            };

        case types.PODCAST_CATEGORIES_PLATFORMS:
            return {
                ...state,
                platforms: action.payload,
                didSort: true
            };

        case types.PODCAST_FORM_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        
        case types.PODCAST_SOCIAL_VIDEO_SUCCESS:
            return {
                ...state,
                socialSharingUrl: action.payload
            };
        
        case types.PODCAST_GET_RSS:
            return {
                ...state,
                podcastEpisodes: action.payload
            };

        case types.PODCAST_EPISODE_SELECTED:
            return {
                ...state,
                podcastEpisodeSelected: action.payload,
                podcastSubtitles: action.podcastSubtitles
            };

        default:
            return state;
    }
};