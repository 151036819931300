import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { fetchUser } from '../redux/actions/auth';
import { fetchAllPlaylist } from '../redux/actions/playlist';
import { fetchAllCategories } from '../redux/actions/categories';
import { getSocialSharing, fetchPodcasts } from '../redux/actions/podcast';
import Landing from './pages/Landing';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/ResetPassword';
import Layout from './components/layout/Layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faChevronDown, faMinus, faTimes, faFileVideo, faPencilAlt, faSortDown} from '@fortawesome/free-solid-svg-icons';

library.add(faSearch, faChevronDown, faMinus, faTimes, faFileVideo, faPencilAlt, faSortDown );

class App extends Component {
    async componentDidMount(){
        this.props.fetchUser();
        this.props.fetchAllCategories(false);
        this.props.fetchPodcasts();
    }

    render() {
        return (
            <Layout>
                <Switch>
                    <Route path="/" exact component={Landing} />
                    <Route path="/reset/:token" exact component={ResetPassword} />
                    <Redirect to='/notfound' component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}

const addFunctionsToProps = { 
    fetchUser, 
    fetchPodcasts,
    fetchAllPlaylist, 
    fetchAllCategories, 
    getSocialSharing
};


export default connect(null, addFunctionsToProps )(App);
