import React from 'react';
// import { connect } from 'react-redux';

const CategoriesForm = () => {
    return (
        <div id="categories-form">
           Categories Form
        </div>    
    )
}

export default CategoriesForm;