export default {
    renderInitials(name) {
        let parts = name.split(' ')
        let initials = ''
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].length > 0 && parts[i] !== '') {
                initials += parts[i][0]
            }
        }

        return initials;
    },

    //remove key !== url/thumbnail after testing
    validate(formdata) {
        let errors = [];

        for (const key in formdata) {
            if (formdata.hasOwnProperty(key)) {
                if (formdata[key] === "" || formdata[key] === undefined) {
                    if (process.env.NODE_ENV === "development") {
                        if (
                            key !== "id"
                            && key !== "dailymotionEpId"
                            && key !== "addToDailymotion"
                            && key !== "youtubeEpId"
                            && key !== "youtube"
                            && key !== "url"
                            && key !== "thumbnail"
                            && key !== "url720"
                            && key !== "url480"
                            && key !== "srtFileName"
                            && key !== "srtData"
                            && key !== "autoplayTime"
                        ) {
                            errors.push(key)
                        }
                    } else {
                        if (
                            key !== "id"
                            && key !== "dailymotionEpId"
                            && key !== "addToDailymotion"
                            && key !== "youtubeEpId"
                            && key !== "youtube"
                            && key !== "url720"
                            && key !== "url480"
                            && key !== "srtFileName"
                            && key !== "srtData"
                            && key !== "autoplayTime"
                        ) {
                            errors.push(key)
                        }
                    }
                }
            }
        }

        return errors
    },

    isEmpty(formData) {
        let errors = [];

        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                if (formData[key] === "" || formData[key] === undefined) {
                    if (key !== "_id" && key !== "podcastId") {
                        errors.push(key)
                    }
                }
            }
        }

        return errors;
    },

    validateSeriesForPublishing(seriesForm, checkedDailyMotion, isNew) {
        const {
            title,
            genres,
            releaseDate,
            shortDescription,
            thumbnail,
            verticalThumbnail,
            seasons
        } = seriesForm;

        let errors = []

        if (!title || title === "") {
            errors.push(" Title ");
        }

        if (genres.length === 0) {
            errors.push(" Genres ");
        }

        if (!releaseDate || releaseDate === "") {
            errors.push(" Release Date ");
        }

        if (!shortDescription || shortDescription === "") {
            errors.push(" Main Description ");
        }

        if (!thumbnail || thumbnail === "") {
            errors.push(" Horizontal Image ");
        }

        if (!checkedDailyMotion) {
            if (!verticalThumbnail || verticalThumbnail === "") {
                errors.push(" Vertical Image ");
            }

            if (seasons.length === 0 && !isNew) {
                errors.push(" No Episodes uploaded ");
            }
        }

        return errors;
    },

    displayErrors(errors) {
        let userErrors = errors.map(error => {
            return error
        });

        return userErrors
    },

    displayTime(timestamp) {
        let hours = Math.floor(timestamp / 60 / 60);
        let minutes = Math.floor(timestamp / 60) - (hours * 60);
        let seconds = timestamp % 60;
        let formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
        return formatted;
    },

    CSVToArray(strData, strDelimiter) {
        // Check to see if the delimiter is defined. If not,
        // then default to comma.
        strDelimiter = (strDelimiter || ",");

        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp(
            (
                // Delimiters.
                "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

                // Quoted fields.
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

                // Standard fields.
                "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
        );


        // Create an array to hold our data. Give the array
        // a default empty first row.
        var arrData = [[]];

        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;


        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        // eslint-disable-next-line
        while (arrMatches = objPattern.exec(strData)) {

            // Get the delimiter that was found.
            var strMatchedDelimiter = arrMatches[1];

            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (
                strMatchedDelimiter.length &&
                strMatchedDelimiter !== strDelimiter
            ) {

                // Since we have reached a new row of data,
                // add an empty row to our data array.
                arrData.push([]);

            }

            var strMatchedValue;

            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[2]) {

                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"),
                    "\""
                );

            } else {

                // We found a non-quoted value.
                strMatchedValue = arrMatches[3];

            }


            // Now that we have our value string, let's add
            // it to the data array.
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        // Return the parsed data.
        return (arrData);
    },

    formatTime(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    }
};