import * as types from '../constants/types'
import axios from "axios";
import { toast } from 'react-toastify';

export const editCopyForm = (array, index, label, text) => async dispatch => {
    dispatch({ type: types.HANDLE_EDIT_FORM, array, index, label, text });
    return;
};

export const updateCopyForm = (copy, copyId) => async dispatch => {
    try {
        const res = await axios.put("/copy/update", { copy, copyId });
        toast("Copy successfully updated.");
        dispatch({ type: types.HANDLE_EDIT_COPY, payload: "edit", list: res.data.list, copyId: res.data.copyId ?? copyId });
    } catch (error) {
        toast("Something went wrong.");
    }
}