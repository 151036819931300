import React from 'react';
import { connect } from 'react-redux';

import { toggleAddingNewSeries, toggleMobileSideDrawer } from '../../../redux/actions/layout';

import logo from '../../../assets/images/whh_logo.png';
import bootstrap from '../../helpers/bootstrap';
import utils from '../../helpers/utils';

import { Navbar } from 'react-bootstrap';

const Header = ({ user, toggleAddingNewSeries, toggleMobileSideDrawer, mobileSideDrawer, isAddingNewSeries }) => {
    const renderUserDisplay = user => {
        return user
            ? (
                <div className={`${bootstrap.center}`}>
                    <div className="header-account-desktop">
                        <div className={`header-account-initials ${bootstrap.center} mr-3`}>
                            { utils.renderInitials(user.googleName) }
                        </div>
                        <div className="pt-2">
                            <h3 className="mb-1">{user.googleName.toUpperCase()}</h3>
                            <a className="header-account-logout" href="/auth/logout">LOG OUT</a>
                        </div>
                    </div>
                  
                    <div className="header-account-mobile">
                        <a className="header-account-logout" href="/auth/logout">LOG OUT</a>
                    </div>
                </div>     
            )
            : null
    }

    return (
        
             <Navbar expand="lg" className="header">
                <Navbar.Brand>
                    <div className="header-logo">
                        <img src={logo} className="img-fluid" alt="WhoHaha" />
                    </div>
                </Navbar.Brand>
                {
                    !user 
                        ? null
                        : <Navbar.Toggle aria-controls="basic-navbar-nav" />
                }
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
                    <div className="header-account pt-3 pt-lg-0">
                        { renderUserDisplay(user) }
                    </div>
                    <div className="text-center">
                        <div onClick={()=>toggleAddingNewSeries(false, false)} className="header-nav-item mb-3">All Series</div>
                        <div onClick={()=>toggleAddingNewSeries(true, false)} className="header-nav-item mb-3">Add New</div>
                        {
                            !isAddingNewSeries 
                                ? null
                                : <div onClick={()=>toggleMobileSideDrawer()} className="header-nav-item mb-3">{ mobileSideDrawer ? "Hide" : "Show"} Sidebar</div>
                        }            
                    </div>
     
                </Navbar.Collapse>
            </Navbar>       
    )
};

const mapStateToProps = ({ auth, layout: { mobileSideDrawer, isAddingNewSeries }}) => ({ user : auth.user, mobileSideDrawer, isAddingNewSeries });

export default connect(mapStateToProps, { toggleAddingNewSeries, toggleMobileSideDrawer })(Header);
