import * as types from '../constants/types';

const initialState = {
    seriesForm: {
        id: "",
        _id: "",
        cast: "",
        publisher: "",
        title: "",
        thumbnail: "",
        releaseDate: "",
        shortDescription: "",
        verticalThumbnail: "",
        bannerUrl: "",
        genres: [],
        categories: [],
        seriesCategories: [],
        seasons: [],
        blacklist: [],
        whitelist: [],
        isWhitelist: false,
        published: "",
        datePublished: "",
        featured: false,
        featuredTV: false,
        featuredMobile: false,
        showOnRoku: false,
        showOnApp: false,
        dailymotion: false,
        dailymotionId: "",
        youtube: false,
        youtubePlaylistId: "",
        _tags: []
    },
    episodeForm: {
        id: "",
        title: "",
        thumbnail: "",
        episodeNumber: "",
        duration: "",
        autoplayTime: "",
        shortDescription: "",
        releaseDate: "",
        seasonId: "",
        seasonNumber: "",
        url: "",
        url720: "",
        url480: "",
        quality: "",
        videoType: "",
        addToDailymotion: false,
        dailymotionEpId: "",
        youtube: false,
        youtubeEpId: "",
        srtFileName: "",
        srtData: "",
        _tags: []
    },
    awsForm: {
        file: {},
        fileName: "",
        fileSize: "",
        fileType: "",
        uploading: false,
        canceled: false,
        percentage: 0,
        success: false,
        error: ""
    },
    seasonForm: {
        seriesId: "",
        seasonNumberIfAdded: null
    },
    podcastForm: {
        _id: "",
        title: "",
        author: "",
        rssFeedUrl: "",
        description: "",
        image: "",
        mobileFeaturedImage: "",
        thumbnail: "",
        featureAsBanner: false,
        featuredTV: false,
        featuredMobile: false,
        isZeroBucket: false,
        podcastId: "",
        categories: [],
        podcastPublished: false,
        _tags: [],
        seasonsByEpisode: []
    },
    podcastEpisodeForm: {
        _id: "",
        podcastEpisodeId: "",
        startTime: "",
        endTime: "",
        content: "",
        _tags: []
    },
    passwordResetForm: {
        newPassword: "",
        confirmPassword: ""
    }
};

export default (state = initialState, action) => {
    switch (action.type) {

        /*---------------------------------
        SERIES FORM
        ---------------------------------*/
        case types.CLEAR_SERIES_FORM_IMAGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    thumbnail: action.payload
                }
            };

        case types.CLEAR_SERIES_FORM_VERTICAL_IMAGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    verticalThumbnail: action.payload
                }
            };

        case types.SERIES_FORM_INPUT_CHANGE:
            const seriesInput = action.input
            const seriesText = action.text;
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    [seriesInput]: seriesText
                }
            };

        case types.HANDLE_EPISODE_FILE_CHANGE:
            const { fileType, fileSize, fileName, file } = action;
            return {
                ...state,
                awsForm: {
                    ...state.awsForm,
                    file,
                    fileName,
                    fileSize,
                    fileType
                },
                episodeForm: {
                    ...state.episodeForm,
                    url: ""
                }
            };

        case types.HANDLE_SERIES_VERTICAL_IMAGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    verticalThumbnail: action.payload
                }
            };

        case types.HANDLE_SERIES_HORIZONTAL_IMAGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    thumbnail: action.payload
                }
            };

        case types.HANDLE_SERIES_CHECKBOX_CHANGE:
            let newCategories = state.seriesForm.categories.map(item => {
                if (item.name === action.name) {
                    item.checked = action.checked;
                }

                return item;
            });

            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    categories: newCategories
                }
            };

        case types.HANDLE_SERIES_FEATURED_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    [action.name]: action.checked
                }
            };

        case types.HANDLE_SERIES_SHOW_ON_ROKU_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    showOnRoku: action.checked
                }
            };

        case types.HANDLE_SERIES_SHOW_ON_APP_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    showOnApp: action.checked
                }
            };

        case types.HANDLE_SERIES_DAILYMOTION_CHECKBOX_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    dailymotion: action.checked
                }
            };

        case types.HANDLE_SERIES_YOUTUBE_CHECKBOX_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    youtube: action.checked
                }
            }

        case types.HANDLE_SERIES_GENRES_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    genres: action.payload,
                }
            };

        case types.HANDLE_SERIES_BLACKLIST_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    blacklist: action.payload
                }
            }

        case types.HANDLE_SERIES_WHITELIST_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    whitelist: action.payload
                }
            }

        case types.SERIES_CATEGORIES_CHANGE:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    seriesCategories: action.payload || []
                }
            }

        case types.UPDATE_SERIES_TAGS:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    _tags: action.payload
                }
            }

        case types.RESET_SERIES_FORM:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    _id: "",
                    cast: "",
                    publisher: "",
                    title: "",
                    thumbnail: "",
                    releaseDate: "",
                    shortDescription: "",
                    verticalThumbnail: "",
                    bannerUrl: "",
                    categories: action.payload,
                    seriesCategories: [],
                    featured: false,
                    featuredTV: false,
                    featuredMobile: false,
                    showOnRoku: false,
                    showOnApp: false,
                    dailymotion: false,
                    dailymotionId: "",
                    youtube: false,
                    youtubePlaylistId: "",
                    genres: [],
                    blacklist: [],
                    whitelist: [],
                    _tags: [],
                    seasonsByEpisode: []
                }
            };

        case types.EDIT_SELECTED_SERIES:
            const {
                cast,
                publisher,
                title,
                thumbnail,
                releaseDate,
                shortDescription,
                verticalThumbnail,
                bannerUrl,
                genres,
                blacklist,
                whitelist,
                isWhitelist,
                categories,
                seriesCategories,
                _id,
                id,
                published,
                datePublished,
                duration,
                autoplayTime,
                featured,
                featuredTV,
                featuredMobile,
                showOnRoku,
                showOnApp,
                dailymotion,
                dailymotionId,
                youtube,
                youtubePlaylistId,
                _tags
            } = action.payload

            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    id,
                    _id,
                    cast,
                    publisher,
                    title,
                    thumbnail,
                    releaseDate,
                    shortDescription,
                    verticalThumbnail,
                    bannerUrl: bannerUrl || "",
                    duration,
                    autoplayTime: autoplayTime || "",
                    categories,
                    seriesCategories: seriesCategories || [],
                    genres,
                    seasons: action.seasons,
                    blacklist,
                    whitelist,
                    isWhitelist,
                    published,
                    featured,
                    featuredTV: featuredTV || false,
                    featuredMobile: featuredMobile || false,
                    showOnRoku,
                    showOnApp,
                    dailymotion: dailymotion || false,
                    dailymotionId: dailymotionId || "",
                    youtube: youtube || false,
                    youtubePlaylistId: youtubePlaylistId || "",
                    datePublished,
                    _tags: _tags || []
                },
                seasonForm: {
                    ...state.seasonForm,
                    seriesId: id,
                    seasonNumberIfAdded: action.seasons.length + 1
                }
            };

        case types.UPDATE_SERIES_FORM:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    published: action.published,
                    datePublished: action.datePublished
                }
            };

        /*---------------------------------
        AWS FORM 
        ---------------------------------*/
        case types.AWS_UPLOADING:
            return {
                ...state,
                awsForm: {
                    ...state.awsForm,
                    uploading: action.payload,
                    canceled: false,
                    error: ""
                }
            };

        case types.AWS_UPLOADING_PROGRESS:
            return {
                ...state,
                awsForm: {
                    ...state.awsForm,
                    percentage: action.uploadPercentage
                }
            };
        case types.FILESTACK_AWS_UPLOAD_SUCCESS:
            const { key, size, filename, mimetype } = action.payload
            return {
                ...state,
                awsForm: {
                    ...state.awsForm,
                    fileName: filename,
                    fileSize: size,
                    fileType: mimetype,
                    uploading: false,
                    canceled: false,
                    success: true,
                    error: ""
                },
                episodeForm: {
                    ...state.episodeForm,
                    url: key
                }
            };

        case types.FILESTACK_AWS_UPLOAD_SUCCESS_720:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    url720: action.payload.key
                }
            };

        case types.FILESTACK_AWS_UPLOAD_SUCCESS_480:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    url480: action.payload.key
                }
            };

        case types.AWS_UPLOAD_SUCCESS:
            return {
                ...state,
                awsForm: {
                    ...state.awsForm,
                    uploading: false,
                    canceled: false,
                    success: true,
                    error: ""
                },
                episodeForm: {
                    ...state.episodeForm,
                    url: `${action.payload}`
                }
            };

        case types.AWS_UPLOAD_CANCEL:
            return {
                ...state,
                awsForm: {
                    ...state.awsForm,
                    uploading: false,
                    canceled: true,
                    percentage: 0
                }
            };
        /*---------------------------------
        EPISODE FORM
        ---------------------------------*/
        case types.EPISODE_FORM_INPUT_CHANGE:
            const episodeInput = action.input;
            const episodeText = action.text;

            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    [episodeInput]: episodeText
                }
            };

        case types.HANDLE_EPISODE_IMAGE_UPLOADED:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    thumbnail: action.payload
                }
            };

        case types.CLEAR_EPISODE_FORM_IMAGE:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    thumbnail: ""
                }
            };

        case types.RESET_EPISODE_FORM:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    id: "",
                    title: "",
                    url: "",
                    url720: "",
                    url480: "",
                    thumbnail: "",
                    episodeNumber: "",
                    duration: "",
                    autoplayTime: "",
                    shortDescription: "",
                    releaseDate: "",
                    quality: "",
                    videoType: "",
                    addToDailymotion: false,
                    dailymotionEpId: "",
                    youtube: false,
                    youtubeEpId: "",
                    srtFileName: "",
                    srtData: "",
                    _tags: []
                },
                awsForm: {
                    ...state.awsForm,
                    file: {},
                    fileName: "",
                    fileSize: "",
                    fileType: "",
                    percentage: 0
                }
            };

        case types.UPDATE_SELECTED_EPISODE:
            const { seasonId, seasonNumber } = action;

            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    seasonId,
                    seasonNumber,
                    id: action.formatted.id,
                    title: action.formatted.title,
                    thumbnail: action.formatted.thumbnail,
                    episodeNumber: action.formatted.episodeNumber,
                    shortDescription: action.formatted.shortDescription,
                    releaseDate: action.formatted.releaseDate,
                    duration: action.formatted.content.duration,
                    autoplayTime: action.formatted.content.autoplayTime || "",
                    url: action.formatted.content.videos[0].url,
                    url720: action.formatted.content.videos[0].url720 || "",
                    url480: action.formatted.content.videos[0].url480 || "",
                    quality: action.formatted.content.videos[0].quality,
                    videoType: action.formatted.content.videos[0].videoType,
                    addToDailymotion: action.formatted.addToDailymotion || false,
                    youtube: action.formatted.youtube || false,
                    dailymotionEpId: action.formatted.dailymotionEpId,
                    youtubeEpId: action.formatted.youtubeEpId,
                    srtFileName: action.formatted.srtFileName || "",
                    _tags: action.formatted._tags || []
                }
            };

        case types.HANDLE_DAILYMOTION_CHECKBOX_CHANGE:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    addToDailymotion: action.checked
                }
            };

        case types.UPDATE_SERIES_EPISODE_TAGS:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    _tags: action.payload
                }
            };

        case types.HANDLE_EPISODE_YOUTUBE_CHECKBOX_CHANGE:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    youtube: action.checked
                }
            };

        /*---------------------------------
        SEASONS
        ---------------------------------*/
        case types.SEASON_SELECTED:
            return {
                ...state,
                episodeForm: {
                    ...state.episodeForm,
                    seasonId: action.payload.seasonId,
                    seasonNumber: action.payload.seasonNumber
                }
            };

        case types.UPDATED_SELECTED_SEASONS:
            return {
                ...state,
                seriesForm: {
                    ...state.seriesForm,
                    seasons: action.seasons
                },
                seasonForm: {
                    ...state.seasonForm,
                    seasonNumberIfAdded: action.seasons.length + 1
                }
            };

        /*---------------------------------
        PODCAST FORM
        ---------------------------------*/
        case types.PODCAST_FORM_INPUT_CHANGE:
            const podcastKey = action.input
            const podcastValue = action.text;

            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    [podcastKey]: podcastValue
                }
            };

        case types.PODCAST_HANDLE_IMAGE_UPLOADED:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    image: action.payload
                }
            };

        case types.PODCAST_HANDLE_MOBILE_FEATURED_IMAGE_UPLOADED:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    mobileFeaturedImage: action.payload
                }
            };

        case types.PODCAST_FORM_RESET:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    title: "",
                    author: "",
                    rssFeedUrl: "",
                    description: "",
                    image: "",
                    thumbnail: "",
                    mobileFeaturedImage: "",
                    featureAsBanner: false,
                    featuredTV: false,
                    featuredMobile: false,
                    isZeroBucket: false,
                    podcastId: "",
                    categories: [],
                    podcastPublished: false,
                    _tags: [],
                    seasonsByEpisode: []
                }
            }

        case types.PODCAST_EDIT_FORM:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    _id: action.payload._id,
                    title: action.payload.title,
                    author: action.payload.author,
                    rssFeedUrl: action.payload.rssFeedUrl,
                    description: action.payload.description,
                    image: action.payload.image,
                    mobileFeaturedImage: action.payload.mobileFeaturedImage,
                    featureAsBanner: action.payload.featureAsBanner,
                    featuredTV: action.payload.featuredTV || false,
                    featuredMobile: action.payload.featuredMobile || false,
                    podcastId: action.payload.podcastId,
                    isZeroBucket: action.payload.isZeroBucket,
                    categories: action.payload.categories || [],
                    podcastPublished: action.payload.podcastPublished || false,
                    _tags: action.payload._tags || [],
                    seasonsByEpisode: action.payload.seasonsByEpisode || []
                }
            };

        case types.PODCAST_EDIT_IMAGE:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    image: "",
                    thumbnail: ""
                }
            };

        case types.PODCAST_EDIT_MOBILE_FEATURED_IMAGE:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    mobileFeaturedImage: ""
                }
            };

        case types.PODCAST_HANDLE_CHECKBOX_CHANGE:
            const name = action.name

            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    [name]: action.checked
                }
            };

        case types.PODCAST_SEASON_BY_EPISODE_CATEGORIES_CHANGE:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    seasonsByEpisode: action.payload
                }
            }

        case types.PODCAST_CATEGORIES_CHANGE:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    categories: action.payload
                }
            }

        case types.UPDATE_PODCAST_TAGS:
            return {
                ...state,
                podcastForm: {
                    ...state.podcastForm,
                    _tags: action.payload
                }
            }

        /*---------------------------------
        PASSWORD RESET FORMS
        ---------------------------------*/
        case types.RESET_PASSWORD_FORM_INPUT_CHANGE:
            const passwordInput = action.input
            const passwordText = action.text;

            return {
                ...state,
                passwordResetForm: {
                    ...state.passwordResetForm,
                    [passwordInput]: passwordText
                }
            };

        case types.HANDLE_RESET_TOKEN_MESSAGE_SUCCESS:
            return {
                ...state,
                passwordResetForm: {
                    ...state.passwordResetForm,
                    newPassword: "",
                    confirmPassword: ""
                }
            };


        /*---------------------------------
        PODCAST EPISODES FORM
        ---------------------------------*/
        case types.PODCAST_EPISODE_SUBTITLE_FORM_INPUT_CHANGE:
            const podcastEpisodeKey = action.input
            const podcastEpisodeValue = action.text;

            return {
                ...state,
                podcastEpisodeForm: {
                    ...state.podcastEpisodeForm,
                    [podcastEpisodeKey]: podcastEpisodeValue
                }
            };

        case types.PODCAST_EPISODE_SUBTITLE_IMAGE_UPLOADED:
            return {
                ...state,
                podcastEpisodeForm: {
                    ...state.podcastEpisodeForm,
                    content: action.payload
                }
            };

        case types.PODCAST_EPISODE_SET:

            return {
                ...state,
                podcastEpisodeForm: {
                    ...state.podcastEpisodeForm,
                    _id: action.payload._id,
                    podcastEpisodeId: action.payload.podcastEpisodeId,
                    startTime: action.payload.startTime,
                    endTime: action.payload.endTime,
                    content: action.payload.content,
                    _tags: action.payload._tags || []
                }
            };

        case types.PODCAST_EPISODE_FORM_RESET:
            return {
                ...state,
                podcastEpisodeForm: {
                    ...state.podcastEpisodeForm,
                    _id: "",
                    podcastEpisodeId: "",
                    startTime: "",
                    endTime: "",
                    content: "",
                    _tags: []
                }
            }

        case types.UPDATE_PODCAST_EPISODE_TAGS:
            return {
                ...state,
                podcastEpisodeForm: {
                    ...state.podcastEpisodeForm,
                    _tags: action.payload
                }
            }

        default:
            return state;
    }
}

