import axios from 'axios';
import * as types from '../constants/types'

export const getDataFromUuid = user => dispatch => {
    axios.get(`/accounts/uuid/${user.uuid}`)
        .then( res => {
           dispatch({ type: types.GET_SELECTED_ACCOUNT, payload: res.data, selectedUser: user });
        })
        .catch(err => {
            if (err.response) {
                const  { status } = err.response;

                if (status ===  422) { return }
                if (status ===  401) { return }
            }
        })
}

export const handleAccountInputChange = text => ({ type: types.ACCOUNT_SEARCH_INPUT_CHANGE, payload: text });

export const cancelAccountViewing = () => ({ type: types.CANCEL_ACCOUNT_VIEWING });