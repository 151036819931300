import * as types from '../constants/types';

const initialState = {
    statistics: [],
    filename: "",
    downloadableReportLink: "",
    youtubeData: [],
    rokuData: [],
    dailymotionData: []
};

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.SUCCESSFULLY_SAVED_CSV_STATISTICS:
            return { 
                ...state,
                statistics: [],
                daysInMonth: "",
                numberOfItems: "",
                filename: ""
            };
        case types.SAVE_SUCCESS_CSV_YOUTUBE: 
            return {
                ...state,
                youtubeData: action.payload
            };
        case types.DOWNLOADABLE_DAILYMOTION_REPORT:
            return {
                ...state,
                downloadableReportLink: action.payload
            };
        case types.RESET_DOWNLOADABLE_DAILYMOTION_REPORT:
            return {
                ...state,
                downloadableReportLink: ""
            };
        case types.HANDLE_ADD_YOUTUBE_CSV:
            return {
                ...state,
                youtubeData: action.payload
            };
        case types.HANDLE_ADD_ROKU_CSV:
            return {
                ...state,
                rokuData: action.payload
            };
        case types.HANDLE_ADD_DAILYMOTION_CSV:
            return {
                ...state,
                dailymotionData: action.payload
            };
        default:
            return state;
    }
}