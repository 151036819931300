import React, { Component } from "react";
import { connect } from 'react-redux';

import { fetchPodcasts, editPodcast } from '../../../redux/actions/podcast';
import { toggleViewPodcasts } from '../../../redux/actions/layout';

class PodcastList extends Component { 
    componentDidMount() { 
        this.props.fetchPodcasts();
    }

    _renderList = () => {
        if (!this.props.allPodcasts) {
            return <div>Loading ...</div>;
        }

        if (this.props.allPodcasts.length === 0) {
            return <div> <p>no available podcasts</p> </div>
        }

        return this.props.allPodcasts.map( podcast => {
            return (
                <li 
                    key={podcast._id} 
                    className="" 
                    onClick={() => {
                        this.props.editPodcast(podcast);
                        this.props.toggleViewPodcasts("edit");
                    }}>
                    <img src={podcast.thumbnail} alt={podcast.title} />
                </li>
            )
        });
    }

    render(){
        return (
            <div id="podcast-list">
                <ul className="">
                    { this._renderList() }
                </ul>
            </div>
        )
    }
}
const mapStateToProps = ({ podcast: { allPodcasts } }) => ({ allPodcasts });

export default connect(mapStateToProps, { 
    fetchPodcasts, 
    toggleViewPodcasts,
    editPodcast
 })(PodcastList);