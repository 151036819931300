import * as types from '../constants/types'
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';

import hooks from '../helpers/hooks';

export const fetchAllCategories = isEditing => async dispatch => {
    const res = await axios.get('/categories/all_categories');

    if (isEditing) {
        dispatch({ type: types.FETCH_ALL_CATEGORIES_EDITING, payload: res.data.categories });
    } else {
        dispatch({ type: types.FETCH_ALL_CATEGORIES, payload: res.data.categories });
    }
}

export const fetchContentCategories = () => async dispatch => {
    const { tryRequest } = hooks({
        url: "/categories/all_categories",
        method: 'get',
        onSuccess: data => {
            const categories = [];
            const seriesCategories = data.categories.seriesCategories || [];
            const podcastCategories = data.categories.podcastCategories || [];
            const combinedCategories = data.categories.combinedCategories || [];
            const combinedTVCategories = data.categories.combinedTVCategories || [];
            const seriesCategoriesPlatforms = data.seriesCategories || [];
            const podcastCategoriesPlatforms = data.podcastCategories || [];

            let mobileCategories = [];
            let mobileCombinedSeriesIds = [];
            let tvCombinedSeriesIds = [];
            let tvCategories = [];

            //Combine data to fit swift data models
            for (let i = 0; i < seriesCategories.length; i++) {
                const category = seriesCategories[i];
                categories.push({
                    label: category.label,
                    id: category.value,
                    isPodcast: false
                });
            }

            for (let i = 0; i < podcastCategories.length; i++) {
                const category = podcastCategories[i];
                categories.push({
                    label: category.label,
                    id: category.value,
                    isPodcast: true
                });
            }

            //Check if db sorted categories exists
            if (!_.isEmpty(combinedCategories)) {
                for (let i = 0; i < combinedCategories.length; i++) {
                    const id = combinedCategories[i];

                    for (let k = 0; k < categories.length; k++) {

                        if (id === categories[k].id) {
                            mobileCategories.push(categories[k]);
                        }
                    }
                }

                for (let i = 0; i < categories.length; i++) {
                    if (!mobileCategories.includes(categories[i])) {
                        mobileCategories.push(categories[i])
                    }
                }


                //filter series mobile only
                for (let k = 0; k < seriesCategoriesPlatforms.length; k++) {
                    const category = seriesCategoriesPlatforms[k];

                    if (!_.isEmpty(category.platforms)) {
                        for (let i = 0; i < category.platforms.length; i++) {
                            const platform = category.platforms[i];

                            if (platform.value === "mobile") {
                                mobileCombinedSeriesIds.push(category.categoryId)
                            }
                        }
                    }
                }

                //filter podcast mobile only
                for (let k = 0; k < podcastCategoriesPlatforms.length; k++) {
                    const category = podcastCategoriesPlatforms[k];

                    if (!_.isEmpty(category.platforms)) {
                        for (let i = 0; i < category.platforms.length; i++) {
                            const platform = category.platforms[i];

                            if (platform.value === "mobile") {
                                mobileCombinedSeriesIds.push(category.categoryId)
                            }
                        }
                    }
                }

                const ordered = combinedCategories.filter(category => mobileCombinedSeriesIds.includes(category.id));

                dispatch({
                    type: types.FETCH_COMBINED_CATEGORIES,
                    payload: ordered
                });
            } else {
                dispatch({
                    type: types.FETCH_COMBINED_CATEGORIES,
                    payload: categories
                });
            }

            if (!_.isEmpty(combinedTVCategories)) {
                for (let i = 0; i < combinedTVCategories.length; i++) {
                    const id = combinedTVCategories[i];

                    for (let k = 0; k < categories.length; k++) {
                        if (id === categories[k].id) {
                            tvCategories.push(categories[k]);
                        }
                    }
                }

                for (let i = 0; i < categories.length; i++) {
                    if (!tvCategories.includes(categories[i])) {
                        tvCategories.push(categories[i])
                    }
                }

                //filter series tv only
                for (let k = 0; k < seriesCategoriesPlatforms.length; k++) {
                    const category = seriesCategoriesPlatforms[k];

                    if (!_.isEmpty(category.platforms)) {
                        for (let i = 0; i < category.platforms.length; i++) {
                            const platform = category.platforms[i];

                            if (platform.value === "appletv") {
                                tvCombinedSeriesIds.push(category.categoryId)
                            }
                        }
                    }
                }

                //filter podcast tv only
                for (let k = 0; k < podcastCategoriesPlatforms.length; k++) {
                    const category = podcastCategoriesPlatforms[k];

                    if (!_.isEmpty(category.platforms)) {
                        for (let i = 0; i < category.platforms.length; i++) {
                            const platform = category.platforms[i];

                            if (platform.value === "appletv") {
                                tvCombinedSeriesIds.push(category.categoryId)
                            }
                        }
                    }
                }

                const orderedTV = combinedTVCategories.filter(category => tvCombinedSeriesIds.includes(category.id));

                dispatch({
                    type: types.FETCH_COMBINED_TV_CATEGORIES,
                    payload: orderedTV
                });
            } else {
                dispatch({
                    type: types.FETCH_COMBINED_TV_CATEGORIES,
                    payload: categories
                });
            }
        }
    });

    await tryRequest();
}

export const reorderContentCategories = updatedCategories => ({
    type: types.FETCH_COMBINED_CATEGORIES,
    payload: updatedCategories,
    mobileNeedsUpdate: true
});

export const reorderContentTVCategories = updatedCategories => ({
    type: types.FETCH_COMBINED_TV_CATEGORIES,
    payload: updatedCategories,
    tvNeedsUpdate: true
});

export const updateDatabaseCategories = (updatedCategories, platform) => async dispatch => {
    try {
        let combined = [];

        for (let i = 0; i < updatedCategories.length; i++) {
            const id = updatedCategories[i];
            combined.push(id)
        }

        const { data } = await axios.put('/categories/updateContentCategories', { categories: combined, platform });
        toast("Categories updated");

        if (platform === "mobile") {
            dispatch({
                type: types.FETCH_COMBINED_CATEGORIES,
                payload: data,
                mobileNeedsUpdate: false
            });

            return
        }

        dispatch({
            type: types.FETCH_COMBINED_TV_CATEGORIES,
            payload: data,
            tvNeedsUpdate: false
        });

    } catch (error) {
        toast("Something went wrong");
    }
}

//REORDER CATEGORIES
export const handleUpdateCategories = (categories, categoryType) => async dispatch => {
    if (categoryType === "podcast") {
        dispatch({ type: types.UPDATE_PODCAST_CATEGORIES, payload: categories });
        return;
    }

    dispatch({ type: types.UPDATE_SERIES_CATEGORIES, payload: categories });
}

export const saveUpdatedCategories = (categories, categoryType) => async dispatch => {
    try {

        if (categoryType === "podcast") {
            const res = await axios.put('/categories/handlePodcastCategories', { categories, type: "update" });

            refetchContentCategories(dispatch, false);
            dispatch({ type: types.FETCH_PODCAST_CATEGORIES, payload: res.data });
            toast("Updated podcasts categories.");
            return;
        }

        const res = await axios.put('/categories/handleSeriesCategories', { categories, type: "update" });
        const seriesResponse = await axios.get('/series/all_series');
        refetchContentCategories(dispatch, false);

        dispatch({ type: types.UPDATE_SERIES_CATEGORIES, payload: res.data });
        dispatch({ type: types.FETCH_ALL_SERIES, payload: seriesResponse.data.series });
        toast("Updated series categories.");
        return;

    } catch {
        toast("Unable to update categories");
    }
}

//REORDER SERIES CATEGORIES ITEM SAVE TO DATABASE
export const updateReorderedSeriesCategories = (list, didSort, selectedCategory, platforms) => async dispatch => {
    const res = await axios.put('/seriesCategories/reorder', { list, selectedCategory, platforms });

    dispatch({
        type: types.REORDER_SERIES_CATEGORIES,
        payload: list,
        didSort,
        selectedCategory,
        platforms: res.data.platforms
    });

    refetchContentCategories(dispatch, true);

    toast("Updated podcast category order");
}

export const updatedReorderedPodcast = (list, didSort, selectedCategory, platforms) => async dispatch => {

    const podcastIds = list.map(podcast => {
        return podcast.podcastId
    })

    const { data } = await axios.put('/podcast/reorder', { podcastIds, selectedCategory, platforms });

    dispatch({ type: types.REORDER_PODCASTS, payload: list, didSort, selectedCategory, platforms: data.platforms });
    refetchContentCategories(dispatch, true);

    toast("Updated podcast category order");
}

async function refetchContentCategories(dispatch, shouldUpdate) {
    const { tryRequest } = hooks({
        url: "/categories/all_categories",
        method: 'get',
        onSuccess: data => {
            let categories = [];
            const seriesCategories = data.categories.seriesCategories || [];
            const podcastCategories = data.categories.podcastCategories || [];
            const combinedCategories = data.categories.combinedCategories || [];
            const combinedTVCategories = data.categories.combinedTVCategories || [];
            const seriesCategoriesPlatforms = data.seriesCategories || [];
            const podcastCategoriesPlatforms = data.podcastCategories || [];

            let mobileCategories = [];
            let mobileCombinedSeriesIds = [];
            let tvCombinedSeriesIds = [];
            let tvCategories = [];

            for (let i = 0; i < seriesCategories.length; i++) {
                const category = seriesCategories[i];
                categories.push({
                    label: category.label,
                    id: category.value,
                    isPodcast: false
                });
            }

            for (let i = 0; i < podcastCategories.length; i++) {
                const category = podcastCategories[i];
                categories.push({
                    label: category.label,
                    id: category.value,
                    isPodcast: true
                });
            }

            if (!_.isEmpty(combinedCategories)) {
                for (let i = 0; i < combinedCategories.length; i++) {
                    const id = combinedCategories[i];

                    for (let k = 0; k < categories.length; k++) {
                        if (id === categories[k].id) {
                            mobileCategories.push(categories[k]);
                        }
                    }
                }

                for (let i = 0; i < categories.length; i++) {
                    if (!mobileCategories.includes(categories[i])) {
                        mobileCategories.push(categories[i])
                    }
                }

                //filter series mobile only
                for (let k = 0; k < seriesCategoriesPlatforms.length; k++) {
                    const category = seriesCategoriesPlatforms[k];

                    if (!_.isEmpty(category.platforms)) {
                        for (let i = 0; i < category.platforms.length; i++) {
                            const platform = category.platforms[i];

                            if (platform.value === "mobile") {
                                mobileCombinedSeriesIds.push(category.categoryId)
                            }
                        }
                    }
                }

                //filter podcast mobile only
                for (let k = 0; k < podcastCategoriesPlatforms.length; k++) {
                    const category = podcastCategoriesPlatforms[k];

                    if (!_.isEmpty(category.platforms)) {
                        for (let i = 0; i < category.platforms.length; i++) {
                            const platform = category.platforms[i];

                            if (platform.value === "mobile") {
                                mobileCombinedSeriesIds.push(category.categoryId)
                            }
                        }
                    }
                }


                const ordered = mobileCategories.filter(category => mobileCombinedSeriesIds.includes(category.id));

                dispatch({
                    type: types.FETCH_COMBINED_CATEGORIES,
                    payload: ordered,
                    mobileNeedsUpdate: shouldUpdate
                });
            } else {
                dispatch({
                    type: types.FETCH_COMBINED_CATEGORIES,
                    payload: categories,
                    mobileNeedsUpdate: shouldUpdate
                });
            }

            if (!_.isEmpty(combinedTVCategories)) {
                for (let i = 0; i < combinedTVCategories.length; i++) {
                    const id = combinedTVCategories[i];

                    for (let k = 0; k < categories.length; k++) {
                        if (id === categories[k].id) {
                            tvCategories.push(categories[k]);
                        }
                    }
                }

                for (let i = 0; i < categories.length; i++) {
                    if (!tvCategories.includes(categories[i])) {
                        tvCategories.push(categories[i])
                    }
                }

                //filter tv only
                for (let k = 0; k < seriesCategoriesPlatforms.length; k++) {
                    const category = seriesCategoriesPlatforms[k];

                    if (!_.isEmpty(category.platforms)) {
                        for (let i = 0; i < category.platforms.length; i++) {
                            const platform = category.platforms[i];

                            if (platform.value === "appletv") {
                                tvCombinedSeriesIds.push(category.categoryId)
                            }
                        }
                    }
                }

                //filter podcast tv only
                for (let k = 0; k < podcastCategoriesPlatforms.length; k++) {
                    const category = podcastCategoriesPlatforms[k];

                    if (!_.isEmpty(category.platforms)) {
                        for (let i = 0; i < category.platforms.length; i++) {
                            const platform = category.platforms[i];

                            if (platform.value === "appletv") {
                                tvCombinedSeriesIds.push(category.categoryId)
                            }
                        }
                    }
                }

                const orderedTV = tvCategories.filter(category => tvCombinedSeriesIds.includes(category.id));

                dispatch({
                    type: types.FETCH_COMBINED_TV_CATEGORIES,
                    payload: orderedTV,
                    tvNeedsUpdate: shouldUpdate
                });
            } else {
                dispatch({
                    type: types.FETCH_COMBINED_TV_CATEGORIES,
                    payload: categories,
                    tvNeedsUpdate: shouldUpdate
                });
            }
        }
    });

    await tryRequest();
}


