import React from "react";
import CopyForm from "./CopyForm";

const CopyComponent = () => {
    return (
        <>
            <h1 className="mb-5">
                Copy
            </h1>
            <CopyForm />
        </>
    )
}

export default CopyComponent;