import React from 'react';
import { connect } from 'react-redux';

// import googleLogo from '../../assets/images/google.png';
import * as actions from '../../redux/actions/auth';
import Home from './Home';
import AdminVerifyForm from '../components/forms/AdminVerifyForm';

const Landing = ({ user, userVerified, roles }) => {
    let landing = <AdminVerifyForm />

    if(userVerified) {
        landing = (
            <div id="login-google" className="text-center">
                {/* <img src={googleLogo} alt="login" /> */}
                <a className="" href={roles.includes("creators") ? "/creatorAuth/google": "/auth/google"}>Sign in with Google</a>
            </div>
        );
    }

    if( user && userVerified ) {
        landing = <Home user={user}/>
    }

    return landing;
}

const mapStateToProps = ({ auth }) => ({ 
    user : auth.user,
    userVerified: auth.userVerified, 
    roles: auth.roles 
});

export default connect(mapStateToProps, actions)(Landing);
