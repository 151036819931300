import React , { Component } from 'react';
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";

import { verifyUser } from '../../../redux/actions/auth';

class AdminLoginForm extends Component {
    state = {
        secret: "",
        recaptchaChecked: false,
        isRobot: false
    }

    _handleAdminVerification = e => {
        e.preventDefault();

        // if(!this.state.secret || !this.state.recaptchaChecked || this.state.isRobot) {
        //     return;
        // }

        this.props.verifyUser(this.state.secret);

        this.setState({ secret: ""});
    }

    _handleInputChange = e => {
        this.setState({ secret: e.target.value });
    }

    _renderError = () => {
        let error;
        switch (this.props.userVerified) {
            case true:
                error = null;
                break;
            case false:
                error = <div style={{ fontSize: "1.5rem ", color: "red" }}>Sorry incorrect password, please try again.</div>
                break;
            default:
                error = null;
        }

        return error;
    }

    _onChange = value => {
        if(!value) {
            this.setState({ isRobot: true });
            return 
        } 

        this.setState({ recaptchaChecked: true });
    }

    render() {
        const RECAPTCHA_KEY = "6LdXlKgUAAAAALgA9E4s8IgUZ2_AUjuDvoeo8AhI"
    
        return (
            <div id="login" className="d-flex flex-column justify-content-center align-items-center">
                <h1 className="mb-4">Login</h1>
                <form onSubmit={this._handleAdminVerification} className="d-flex mb-4">
                    <input placeholder="Secret key" className="p-4" onChange={ this._handleInputChange} value={this.state.secret} />
                    <button className="btn-rounded-pink login" type="submit" value="submit">Submit</button>
                </form>

                {/* <ReCAPTCHA
                    sitekey={RECAPTCHA_KEY}
                    onChange={this._onChange}
                /> */}
                
                { this._renderError() }
            </div>
        );
    }
}

const mapStateToProps = ( { auth }) => ({ userVerified: auth.userVerified });

export default connect(mapStateToProps, { verifyUser })(AdminLoginForm);
