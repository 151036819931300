import React from 'react';
import _ from "lodash";

import MainComponent from '../components/MainComponent';
import SeriesList from '../components/series/SeriesList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Home = ({ user }) => {
    if (_.isEmpty(user.roles)) {
        return (
            <div>
                <h1 className="mb-4">Authentication Required</h1>
                <p>
                    Processing your account. Please contact system admin.
                </p>
            </div>
        )
    }
    
    return (
        <div id="home">
            <ToastContainer toastClassName="toast" bodyClassName="toast-body"  hideProgressBar={true}/>
            <MainComponent user={user}/>
            <SeriesList user={user} />
        </div>
    );
}

export default Home;
