import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import ReactFilestack from 'filestack-react';
import { toast } from 'react-toastify';
import axios from 'axios';

const BannerMain = ({ allSeries }) => {
    const [imageUrl, setImageUrl] = useState("");
    const [series, setSeries] = useState([])
    const [selectedSeriesId, setSelectedSeriesId] = useState("");
    const [isEditing, setEditing] = useState(false);

    useEffect(() => {
        if (allSeries.length !== 0){
            const filter = allSeries.filter( s => !s.isPodcast && s.published );
            setSeries(filter);
        }
        
        (async () => {
            try {
                const { data } = await axios.get('/series/mainBanner');
                
                if(data){
                    setImageUrl(data[0].url);
                    setSelectedSeriesId(data[0].seriesId);
                }
                
            } catch (error) {}
        })();
         
    }, []);

    return (
        <>
            <div className="mb-3">
                {
                    !imageUrl ? null : <img src={imageUrl} className="img-fluid" />
                } 
            </div>
            
            <div className="form-group">
                <label className="mb-2">Select Series</label>
                {
                    series.length === 0 
                    ? ( 
                        <div>
                             <button className="btn-rounded-pink" onClick={()=> {
                                const filter = allSeries.filter( s => !s.isPodcast && s.published );
                                setSeries(filter);
                            }}>Reload</button>
                        </div>
                    )
                    : (
                        <select className="form-control" value={selectedSeriesId} onChange={ e => { 
                                setSelectedSeriesId(e.target.value);
                                setEditing(true);
                            }}>
                            <option key={"None"} value={""}>None</option>
                            {
                                series.map((s) => <option key={s.id} value={s.id}>{s.title}</option>)
                            }
                        </select>
                    )
                }
            </div>

            <div>
                <label className="mb-2">Banner Image</label>
            </div>
            <div className="mb-3 d-flex justify-content-between">
                <ReactFilestack
                    apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                    buttonText={"Upload"}
                    buttonClass="btn-rounded-pink"
                    options={{ 
                        accept: 'image/*',
                        storeTo: {
                            location: 's3',
                            path: "/images/"
                        }
                    }}
                    onSuccess={ (res) => { 
                        setEditing(true);
                        setImageUrl(res.filesUploaded[0].url);
                        toast("Image successfully uploaded.");
                    }}
                />
                {
                    !isEditing
                    ? null
                    : (
                        <button className="btn-rounded-pink" onClick={ async () => {
                            if (selectedSeriesId && imageUrl){
                                const body = {
                                    seriesId: selectedSeriesId,
                                    url: imageUrl
                                };

                                try {
                                    await axios.put("/series/updateMainBanner", body);
                                    toast("Main banner saved");

                                } catch (error) {
                                    toast("Banner not saved");
                                }

                                setEditing(false);
                                return
                            }

                            toast("Series selection and Image upload required.");
                        }} >
                            Save
                        </button>
                    )
                }

            </div>
        </>
    )
}

const mapStateToProps = ({ series: { allSeries } }) => ({
    allSeries
});

export default connect(mapStateToProps, null)(BannerMain);