import * as types from '../constants/types';

const initialState = {
    allSeries: [],
    reorderedSeries: [],
    reorderedSeriesTV: [],
    reorderedSeriesMobile: [],
    search: "",
    reorderedSeriesCategories: [],
    platforms: [],
    didSort: false,
    selectedCategory: {},
};

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.FETCH_ALL_SERIES:
            return {
                ...state,
                allSeries: action.payload,
            };
        case types.SEARCH_SERIES:
            return {
                ...state,
                search: action.payload
            };
        case types.RESET_SERIES_SEARCH:
            return {
                ...state,
                search: ""
            };
        case types.REORDER_SERIES:
            return {
                ...state,
                reorderedSeries: action.payload.reorderedSeries,
                reorderedSeriesTV: action.payload.reorderedSeriesTV,
                reorderedSeriesMobile: action.payload.reorderedSeriesMobile
            };
        case types.REORDER_SERIES_MOBILE:
            return {
                ...state,
                reorderedSeriesMobile: action.payload
            };

        case types.REORDER_SERIES_TV:
            return {
                ...state,
                reorderedSeriesTV: action.payload
            };
        
        case types.SERIES_CATEGORIES_PLATFORMS:
            return {
                ...state,
                platforms: action.payload,
                didSort: true
            };

        case types.REORDER_SERIES_CATEGORIES:
            return {
                ...state,
                reorderedSeriesCategories: action.payload,
                didSort: action.didSort,
                selectedCategory: action.selectedCategory,
                platforms: action.platforms
            };

        default:
            return state;
    }
};