export default [
    { label: "Action", value: "action" },
    { label: "Adventure", value: "adventure" },
    { label: "Animals", value: "animals" },
    { label: "Animated", value: "animated" },
    { label: "Anime", value: "anime" },
    { label: "Children", value: "children" },
    { label: "Comedy", value: "comedy" },
    { label: "Crime", value: "crime" },
    { label: "Documentary", value: "documentary" },
    { label: "Drama", value: "drama" },
    { label: "Educational", value: "educational" },
    { label: "Fantasy", value: "fantasy" },
    { label: "Faith", value: "faith" },
    { label: "Food", value: "food" },
    { label: "Fashion", value: "fashion" },
    { label: "Gaming", value: "gaming" },
    { label: "Health", value: "health" },
    { label: "History", value: "history" },
    { label: "Horror", value: "horror" },
    { label: "Miniseries", value: "miniseries" },
    { label: "Mystery", value: "mystery" },
    { label: "Nature", value: "nature" },
    { label: "News", value: "news" },
    { label: "Reality", value: "reality" },
    { label: "Romance", value: "romance" },
    { label: "Science", value: "science" },
    { label: "Science Fiction", value: "science fiction" },
    { label: "Sitcom", value: "sitcom" },
    { label: "Special", value: "special" },
    { label: "Sports", value: "sports" },
    { label: "Thriller", value: "thriller" },
    { label: "Technology", value: "technology" }
];