import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactFilestack from 'filestack-react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import bootstrap from '../../../helpers/bootstrap';
import { toggleDropdown, toggleEditingBanner } from '../../../../redux/actions/layout';
import { handlePublishSeries } from '../../../../redux/actions/series';
import {
    handleImageUploaded,
    handleCheckboxChange,
    handleSeries,
    handleSeriesDelete,
    handleEpisodes,
    handleSeriesEditImage,
    handleSeriesVeritcalEditImage,
    handleYoutubeCheckboxChange,
} from '../../../../redux/actions/forms';

const RightSideDrawer = ({
    isActive,
    toggleDropdown,
    statusIsActive,
    isAddingNewSeries,
    thumbnailIsActive,
    categoriesIsActive,
    mobileSideDrawer,
    isEditing,
    thumbnail,
    verticalThumbnail,
    categories,
    handleImageUploaded,
    handleCheckboxChange,
    handleSeries,
    handleSeriesDelete,
    handlePublishSeries,
    handleSeriesEditImage,
    handleSeriesVeritcalEditImage,
    seriesForm,
    seasonForm,
    handleYoutubeCheckboxChange,
    toggleEditingBanner,
    user
}) => {
    const dropdown = (title, isActive) => {
        return (
            <div className={`${bootstrap.between} ${isActive ? "pb-5" : ""}`}>
                <h3 className="bold ">{title}</h3>
                {
                    isActive
                        ? (
                            <div className="dropdown-container" onClick={() => toggleDropdown(title)} >
                                <FontAwesomeIcon icon="chevron-down" size="2x" color="#666666" />
                            </div>
                        )
                        : (
                            <div className="dropdown-container" onClick={() => toggleDropdown(title)}>
                                <FontAwesomeIcon icon="minus" size="2x" color="#666666" />
                            </div>
                        )
                }
            </div>
        )
    }

    const renderCategories = categories => {
        return categories.map(category => (
            <label className="checkbox-custom-label" key={category.name}>
                { category.label}
                <input type="checkbox" checked={category.checked} name={category.name} onChange={e => handleCheckboxChange(e)} />
                <span className="checkmark"></span>
            </label>
        ))
    }

    if (user && user.roles.includes("creator")) {
        return null;
    }

    return (
        <aside id="sidedrawer-right" className={`${isActive ? 'active ' : 'inactive'} ${mobileSideDrawer ? "mobile-active " : ""} flex-column`}>
            <section className={`p-5 ${bootstrap.between} ${isEditing ? "" : "flex-row-reverse"}`}  >
                {
                    !isEditing
                        ? null
                        : (
                            <OverlayTrigger
                                trigger="click"
                                rootClose={true}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Popover
                                        id={`popover-positioned-bottom`}
                                        title={`Warning! Are you sure?`}
                                    >
                                        <button style={{ width: "auto" }} className="btn-rounded-pink sm m-3" onClick={() => handleSeriesDelete(seriesForm._id, seasonForm.seriesId, seriesForm.dailymotionId, seriesForm.youtubePlaylistId)}>
                                            Delete Forever
                                        </button>
                                    </Popover>
                                }
                            >
                                <button className="btn-underlined-red">
                                    Delete
                                    </button>
                            </OverlayTrigger>
                        )
                }
                <button
                    className="btn-rounded-pink"
                    onClick={async () => {
                        !isEditing
                            ? handleSeries("create", seriesForm)
                            : handleSeries("update", seriesForm)
                    }}>
                    {isEditing ? "Update" : "Save"}
                </button>
            </section>
            <hr />
            <section className="section-status p-5">
                {dropdown("Status", statusIsActive)}
                {
                    !statusIsActive
                        ? null
                        : (
                            <div className="content">
                                <div className={`${bootstrap.between} mb-4`}>
                                    <h3>Status:</h3>
                                    {
                                        !isEditing && isAddingNewSeries
                                            ? <p>Draft</p>
                                            : <p>{!seriesForm.published ? "Draft" : "Published"}</p>
                                    }
                                </div>
                                <div className={`${bootstrap.between} mb-4`}>
                                    <h3>Visibility:</h3>
                                    {
                                        !isEditing && isAddingNewSeries
                                            ? <p>View Only</p>
                                            : <p>{!seriesForm.published ? "View Only" : "Public"}</p>
                                    }
                                </div>
                                {
                                    !isEditing && isAddingNewSeries
                                        ? null
                                        : (
                                            <div className={`${bootstrap.between} mb-4 section-categories`}>
                                                <h3>Featured:</h3>
                                                <label className="checkbox-custom-label" key={"featured"}>
                                                    <input type="checkbox" checked={seriesForm.featured} name={"featured"} onChange={e => {
                                                        handleCheckboxChange(e)
                                                    }} />
                                                    <span className="checkmark featured"></span>
                                                </label>
                                            </div>
                                        )
                                }
                                {
                                    !isEditing && isAddingNewSeries
                                        ? null
                                        : (
                                            <div className={`${bootstrap.between} mb-4 section-categories`}>
                                                <h3>Featured TV:</h3>
                                                <label className="checkbox-custom-label" key={"featuredTV"}>
                                                    <input type="checkbox" checked={seriesForm.featuredTV} name={"featuredTV"} onChange={e => {
                                                        handleCheckboxChange(e)
                                                    }} />
                                                    <span className="checkmark featured"></span>
                                                </label>
                                            </div>
                                        )
                                }
                                {
                                    !isEditing && isAddingNewSeries
                                        ? null
                                        : (
                                            <div className={`${bootstrap.between} mb-4 section-categories`}>
                                                <h3>Featured Mobile:</h3>
                                                <label className="checkbox-custom-label" key={"featuredMobile"}>
                                                    <input type="checkbox" checked={seriesForm.featuredMobile} name={"featuredMobile"} onChange={e => {
                                                        handleCheckboxChange(e)
                                                    }} />
                                                    <span className="checkmark featured"></span>
                                                </label>
                                            </div>
                                        )
                                }
                                <div className={`${bootstrap.between} mb-4 section-categories`}>
                                    <h3>Youtube:</h3>
                                    <label className="checkbox-custom-label" key={"featured"}>
                                        <input type="checkbox" checked={seriesForm.youtube} name={"featured"} onChange={e => {
                                            const target = e.target;
                                            const value = target.type === 'checkbox' ? target.checked : target.value;
                                            const whohahaId = process.env.NODE_ENV === "development" ? process.env.REACT_APP_WHOHAHA_ID_DEV : process.env.REACT_APP_WHOHAHA_ID

                                            if (user && (user._id !== whohahaId)) {
                                                toast("Must be on main Whohaha Account for YouTube.")
                                                return;
                                            }

                                            handleYoutubeCheckboxChange(value, true);
                                        }} />
                                        <span className="checkmark featured"></span>
                                    </label>
                                </div>
                                <div className={`${bootstrap.between} mb-4`}>
                                    <h3>Publish:</h3>
                                    {
                                        !isEditing && isAddingNewSeries
                                            ? <p className="draft">Immediately</p>
                                            : (
                                                <OverlayTrigger
                                                    trigger="focus"
                                                    key={"left"}
                                                    placement={"left"}
                                                    overlay={
                                                        <Popover
                                                            id={`popover-positioned-left`}
                                                            title={`Warning!`}
                                                        >
                                                            <p style={{ fontSize: "1.5rem" }}>
                                                                Clicking this will {
                                                                    <span style={{ color: "#da0092" }}> {!seriesForm.published ? "PUBLISH" : "UNPUBLISH"} </span>
                                                                } your series.
                                                        </p>
                                                        </Popover>
                                                    }
                                                >
                                                    <p className="pointer" onClick={() => handlePublishSeries(seriesForm._id, !seriesForm.published, seriesForm)}>
                                                        {!seriesForm.published ? "Immediately" : seriesForm.datePublished}
                                                    </p>
                                                </OverlayTrigger>
                                            )
                                    }

                                </div>
                                <div className={`${bootstrap.between} mb-4 section-categories`}>
                                    <h3>Roku:</h3>
                                    <label className="checkbox-custom-label" key={"showOnRoku"}>
                                        <input type="checkbox" checked={seriesForm.showOnRoku} name={"showOnRoku"} onChange={e => handleCheckboxChange(e)} />
                                        <span className="checkmark featured"></span>
                                    </label>
                                </div>
                                <div className={`${bootstrap.between} mb-4 section-categories`}>
                                    <h3>App:</h3>
                                    <label className="checkbox-custom-label" key={"showOnApp"}>
                                        <input type="checkbox" checked={seriesForm.showOnApp} name={"showOnApp"} onChange={e => handleCheckboxChange(e)} />
                                        <span className="checkmark featured"></span>
                                    </label>
                                </div>
                            </div>
                        )
                }
            </section>
            <hr />
            <section className="section-thumbnail p-5">
                {dropdown("Thumbnail", thumbnailIsActive)}
                {
                    !thumbnailIsActive
                        ? null
                        : (
                            <div>
                                <div className="mb-4">
                                    <h3 className="mb-4">Vertical</h3>
                                    {
                                        verticalThumbnail ? (
                                            <div className="img-container">
                                                <img src={verticalThumbnail} className="img-fluid" alt="vertical" />
                                                <div className="edit-container">
                                                    <button onClick={() => handleSeriesVeritcalEditImage()}>
                                                        <FontAwesomeIcon icon="pencil-alt" color="#fff" />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                            : (
                                                <ReactFilestack
                                                    apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                                    buttonText="Set Vertical Thumbnail"
                                                    options={{
                                                        accept: 'image/*',
                                                        storeTo: {
                                                            location: 's3',
                                                            path: "/images/"
                                                        }
                                                    }}
                                                    onSuccess={response => handleImageUploaded("seriesFormVertical", response.filesUploaded[0].url)}
                                                />
                                            )
                                    }
                                </div>
                                <div>
                                    <h3 className="mb-4">Horizontal</h3>
                                    {
                                        thumbnail ? (
                                            <div className="img-container">
                                                <img src={thumbnail} className="img-fluid" alt="horizontal" />
                                                <div className="edit-container">
                                                    <button onClick={() => handleSeriesEditImage()}>
                                                        <FontAwesomeIcon icon="pencil-alt" color="#fff" />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                            : (
                                                <ReactFilestack
                                                    apikey={"AqfDxBOJJRcaiq6S4TBVzz"}
                                                    buttonText="Set Horizontal Thumbnail"
                                                    options={{
                                                        accept: 'image/*',
                                                        storeTo: {
                                                            location: 's3',
                                                            path: "/images/"
                                                        },
                                                        transformations: {
                                                            circle: false
                                                        }
                                                    }}
                                                    onSuccess={response => handleImageUploaded("seriesFormHorizontal", response.filesUploaded[0].url)}
                                                />
                                            )
                                    }
                                </div>
                            </div>
                        )
                }
            </section>
        </aside>
    )
}

const mapStateToProps = ({
    layout: { rightSidebarActive, isAddingNewSeries, Status, Thumbnail, Categories, isEditing, mobileSideDrawer },
    forms: { seriesForm: { verticalThumbnail, thumbnail, categories }, seriesForm, episodeForm, seasonForm },
    auth: { user }
}) => ({
    isActive: rightSidebarActive,
    statusIsActive: Status,
    thumbnailIsActive: Thumbnail,
    categoriesIsActive: Categories,
    mobileSideDrawer,
    isAddingNewSeries,
    isEditing,
    verticalThumbnail,
    thumbnail,
    categories,
    seriesForm,
    episodeForm,
    seasonForm,
    user
});

export default connect(mapStateToProps, {
    toggleDropdown,
    handleImageUploaded,
    handleCheckboxChange,
    handleSeries,
    handleSeriesDelete,
    handleEpisodes,
    handlePublishSeries,
    handleSeriesEditImage,
    handleSeriesVeritcalEditImage,
    handleYoutubeCheckboxChange,
    toggleEditingBanner
})(RightSideDrawer);