import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchAllCategories } from '../../../redux/actions/categories';
import { toggleCategoriesModal } from '../../../redux/actions/layout';

class CategoriesList extends Component {
    componentDidMount() {
        this.props.fetchAllCategories(true);
    }

    _renderCategories = categories => {
        return categories.map( category => {
            return (
                <li key={category.label} onClick={()=>this.props.toggleCategoriesModal()}>
                    <label>
                        { category.label }
                    </label>
                    <p>
                        { category.name }
                    </p>
                </li>
            )
        })
    }
    
    render() {
        if(!this.props.allCategories) return null;

        return (
            <ul id="categories-list" className="row">
                { this._renderCategories(this.props.allCategories) }
            </ul>
        ) 
    }
}

const mapStatetoProps = ({ categories: { allCategories } }) => ({ allCategories });

export default connect(mapStatetoProps, { fetchAllCategories, toggleCategoriesModal })(CategoriesList);