import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Select from 'react-select';
import arrayMove from 'array-move';
import _ from "lodash";

import { reorderSeriesCategories } from '../../../redux/actions/series';
import { updateReorderedSeriesCategories } from "../../../redux/actions/categories";
import { handleSeriesCategoryPlatformSelection } from "../../../redux/actions/forms";
 
const SortableItem = SortableElement(({ value, orderNumber }) => <li className="sortable-banner-list-item podcast">{orderNumber + 1}. { value.title}</li>);

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul className="sortable-banner-list podcast">
            {items.map((value, index) => (
                <SortableItem key={`${value._id}`} index={index} orderNumber={index} value={value} />
            ))}
        </ul>
    );
});

const seriesPlatforms = [
    { label: "Mobile", value: "mobile" },
    { label: "Apple TV", value: "appletv" },
]

class SeriesCategoriesSortable extends Component {
    _onSortEnd = ({ oldIndex, newIndex }) => {  
        let updatedPodcasts = arrayMove(this.props.reorderedSeriesCategories, oldIndex, newIndex)

        this.props.reorderSeriesCategories(updatedPodcasts, true, this.props.selectedCategory, this.props.platforms);
    };

    _handleSeriesCategoryPlatformSelection = (updatedCategories) => {
        this.props.handleSeriesCategoryPlatformSelection(updatedCategories);
    }

    render() {
        if (_.isEmpty(this.props.reorderedSeriesCategories)){ return null }

        return (
            <div id="podcast-sortable">
                <p className="mb-3">
                    Select Platform
                </p>

                <Select 
                    isMulti
                    value={this.props.platforms}
                    options={seriesPlatforms}
                    onChange={this._handleSeriesCategoryPlatformSelection}
                    className="mb-3" 
                />

                <p className="mb-3">
                    Drag & drop to sort.
                </p>
                <div className="mb-3">
                    <SortableList 
                        items={this.props.reorderedSeriesCategories} 
                        onSortEnd={this._onSortEnd} 
                    />
                </div>
                {
                    this.props.didSort
                    ? <button className="btn-rounded-pink" onClick={()=>this.props.updateReorderedSeriesCategories(this.props.reorderedSeriesCategories, false, this.props.selectedCategory, this.props.platforms)}>Update</button>
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = ({
    series: { reorderedSeriesCategories, didSort, selectedCategory, platforms }
}) => ({ reorderedSeriesCategories, didSort, selectedCategory, platforms });

const mapFunctionsToProps = {
    reorderSeriesCategories,
    updateReorderedSeriesCategories,
    handleSeriesCategoryPlatformSelection
}

export default connect(mapStateToProps, mapFunctionsToProps)(SeriesCategoriesSortable);