import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';

import { getMatchingSeriesCategories } from "../../../redux/actions/series"; 

const SeriesCategoriesList = ({ seriesCategories, allSeries, getMatchingSeriesCategories, selectedCategory }) => {
    if (_.isEmpty(seriesCategories)) {
        return null
    }

    return (
        <div id="podcast-categories" className="mb-5">
            <p className="mb-3">
                Click a category to reorder.
            </p>
            <ul className="d-flex flex-wrap">
                {   
                    seriesCategories.map( (category, i) => {
                        return (
                            <li key={i} className={`m-1 py-1 px-3 ${selectedCategory.value === category.value ? "active" : "non-active"}`} onClick={() => getMatchingSeriesCategories(category, allSeries) } >
                                <p>{ category.label }</p>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

const addFunctionsToProps = {
    getMatchingSeriesCategories
}

const mapStateToProps = ({ 
    categories: { seriesCategories }, 
    series: { allSeries, selectedCategory }
}) => ({ seriesCategories, allSeries, selectedCategory });

export default connect(mapStateToProps, addFunctionsToProps)(SeriesCategoriesList);