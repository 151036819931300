import * as types from '../constants/types';
import _ from "lodash";

const initialState = {
    copyId: "",
    list: [
        {
            label: "Share Series Prepare Clip",
            title: "",
            message: "",
            modal: "sharePrepareSeries"
        },
        {
            label: "Share Series Clip Ready",
            title: "",
            message: "",
            modal: "shareReadySeries"
        },
        {
            label: "Share Podcast Prepare Clip",
            title: "",
            message: "",
            modal: "sharePreparePodcast"
        },
        {
            label: "Share Podcast Clip Ready",
            title: "",
            message: "",
            modal: "shareReadyPodcast"
        },
        {
            label: "Download Podcast",
            title: "",
            message: "",
            modal: "downloadPodcast"
        },
        {
            label: "Sign Out",
            title: "",
            message: "",
            modal: "signout"
        },
    ]
}

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.HANDLE_EDIT_COPY:
            let list = state.list; 

            if (!_.isEmpty(action.list)){
                list = action.list
            }

            return {
                ...state,
                list,
                copyId: action.copyId
            };

        case types.HANDLE_EDIT_FORM:
            const newList = action.array.map((item, index) => {
                if (index !== action.index) {
                    return item
                }

                return {
                    ...item,
                    [action.label]: action.text
                }
            })
            
            return {
                ...state,
                list: newList
            };

        default:
            return state;
    }
};