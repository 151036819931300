import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/lib/Creatable';
import _ from "lodash";

import { handleUpdateCategories, saveUpdatedCategories } from '../../../redux/actions/categories';

class PodcastCategories extends Component {
    _handleChange = updatedCategories => {
        let formatted  = []

        for (let i = 0; i < updatedCategories.length; i++) {
            const category = updatedCategories[i];
            let obj = {}
            let label = _.startCase(_.lowerCase(category.label));
            let value = _.lowerCase(category.value); 
            let valueWithNoSpaces = value.replace(/\s/g, "");

            obj.label = label;
            obj.value = valueWithNoSpaces;

            formatted.push(obj)
        }

        this.props.handleUpdateCategories(formatted, "podcast");
    };

    render(){
        const { categories: { podcastCategories }} =  this.props;
        return (
            <div>
                <div className="d-flex align-items-center mb-3">
                    <h2>Podcast Categories:</h2>
                    <button 
                        className="btn-rounded-pink ml-3" 
                        onClick={()=>this.props.saveUpdatedCategories(podcastCategories, "podcast")}>
                            Update
                    </button>
                </div>
                <div className="mb-4">
                    <CreatableSelect
                        isMulti
                        isClearable
                        isDisabled={false}
                        isLoading={false}
                        onChange={this._handleChange}
                        options={podcastCategories}
                        value={podcastCategories}
                    />
                </div>
            </div>
        )
    }
}

const mapFunctionsToProps = {
    handleUpdateCategories,
    saveUpdatedCategories
}

const mapStateToProps = ({ categories }) => ({
    categories
})

export default connect(mapStateToProps, mapFunctionsToProps)(PodcastCategories);