import * as types from '../constants/types';

const initialState = {
    rightSidebarActive: false,
    modalIsActive: false,
    editCategoriesModal: false, 
    isEditing: false,
    isAddingNewSeries: false,
    isEditingEpisode: false,
    isEditingCategories: false,
    isViewingUserAccounts: false, 
    isEditingBanner: false,
    isViewingAnalytics: false,
    isViewingPodcasts: false,
    isViewingCopy: false, 
    isViewingReorderCategories: false,
    podcastType: "",
    copyType: "edit", 
    editEpisodesModal: false,
    mobileSideDrawer: false,
    Status: true,
    Thumbnail: true,
    Categories: true,
    deleteEpisodeLoading: false,
    updateEpisodeLoading: false,
    createEpisodeLoading: false,
    updateSeriesLoading: false,
    createSeriesLoading: false,
    reportLoading: false,
    isEditingPodcastSubtitles: true,
    showPodcastCategoriesModal: false
};

export default ( state = initialState, action ) => {
    switch (action.type) {
        case types.TOGGLE_RIGHT_SIDEBAR:
            return {
                ...state,
                rightSidebarActive: action.payload
            };

        case types.TOGGLE_MODAL:
            return {
                ...state,
                modalIsActive: !state.modalIsActive
            };

        case types.TOGGLE_DROPDOWN:
            let name = action.payload
            return {
                ...state,
                [name]: !state[name]
            };

        case types.TOGGLE_IS_ADDING_SERIES:
            return {
                ...state,
                isAddingNewSeries: action.payload,
                rightSidebarActive: action.payload,
                isEditingCategories: false
            };
        
        case types.SELECT_CATEGORIES:
            return {
                ...state,
                isEditingCategories: action.payload,
                rightSidebarActive: false
            };

        case types.TOGGLE_CATEGORIES_MODAL:
            return {
                ...state,
                editCategoriesModal: !state.editCategoriesModal
            };

        case types.TOGGLE_EPISODES_MODAL:
            return {
                ...state,
                editEpisodesModal: !state.editEpisodesModal,
                isEditingEpisode: action.payload
            };

        case types.TOGGLE_PODCAST_CATEGORIES_MODAL:
            return {
                ...state,
                showPodcastCategoriesModal: action.payload
            };

        case types.UPDATED_SELECTED_SEASONS:
            return {
                ...state,
                editEpisodesModal: false
            };

        case types.TOGGLE_MOBILE_SIDEDRAWER:
            return {
                ...state,
                mobileSideDrawer: !state.mobileSideDrawer
            };

        case types.HANDLE_EPISODE_LOADING:
            let episodeType = `${action.loadingType}EpisodeLoading`;
    
            return {
                ...state, 
                [episodeType]: action.payload
            };

        case types.HANDLE_SERIES_LOADING:
            let seriesType = `${action.loadingType}SeriesLoading`;
    
            return {
                ...state, 
                [seriesType]: action.payload
            };

        case types.TOGGLE_EDITING: 
            return {
                ...state,
                isEditing: action.payload,
                isEditingBanner: false,
                isViewingAnalytics: false,
                isViewingPodcasts: false,
                isViewingCopy: false,
                isViewingReorderCategories: false,
                isViewingUserAccounts: false,
                podcastType: "",
            };

        case types.TOGGLE_VIEW_EDITING_PODCAST_SUBTITLES:
            return {
                ...state,
                podcastType: "subtitles",
            };

        case types.HANDLE_EDITING_BANNER:
            return {
                ...state,
                isEditingBanner: true,
                isViewingAnalytics: false,
                isViewingPodcasts: false,
                isViewingCopy: false,
                isViewingReorderCategories: false,
                isViewingUserAccounts: false,
                podcastType: "",
                rightSidebarActive: false
            };

        case types.HANDLE_VIEW_ANALYTICS:
            return {
                ...state,
                isViewingAnalytics: true,
                isViewingPodcasts: false,
                isViewingCopy: false,
                isViewingReorderCategories: false,
                podcastType: "",
                isEditingBanner: false,
                rightSidebarActive: false,
                isAddingNewSeries: false,
                isViewingUserAccounts: false
            }

        case types.HANDLE_VIEW_USER_ACCOUNTS:
            return {
                ...state,
                isViewingUserAccounts: true,
                isViewingAnalytics: false,
                isViewingPodcasts: false,
                isViewingCopy: false,
                isViewingReorderCategories: false,
                podcastType: "",
                isEditingBanner: false,
                rightSidebarActive: false,
                isAddingNewSeries: true,
            };

        case types.HANDLE_VIEW_PODCAST:
        case types.HANDLE_VIEW_PODCAST_BANNER:
        case types.HANDLE_VIEW_PODCAST_SOCIAL_SHARING:
            return {
                ...state,
                isViewingPodcasts: true,
                isViewingCopy: false,
                podcastType: action.payload,
                isViewingReorderCategories: false,
                isViewingAnalytics: false,
                isEditingBanner: false,
                rightSidebarActive: false,
                isAddingNewSeries: false,
                isViewingUserAccounts: false
            }

        case types.HANDLE_EDIT_COPY:
            return {
                ...state, 
                isViewingCopy: true,
                isViewingPodcasts: false,
                copyType: action.payload,
                isViewingReorderCategories: false,
                isViewingAnalytics: false,
                isEditingBanner: false,
                rightSidebarActive: false,
                isAddingNewSeries: false,
                isViewingUserAccounts: false
            }

        case types.HANDLE_REORDER_CATEGORIES:
            return {
                ...state,
                isViewingReorderCategories: true,
                isViewingPodcasts: false,
                isViewingCopy: false,
                isViewingAnalytics: false,
                isEditingBanner: false,
                rightSidebarActive: false,
                isAddingNewSeries: false,
                isViewingUserAccounts: false
            };

        case types.PODCAST_DELETED:
            return {
                ...state,
                podcastType: "view"
            };

        case types.TOGGLE_LOADING_DAILYMOTION_REPORT:
            return {
                ...state,
                reportLoading: action.payload
            };

        case types.RESET_DOWNLOADABLE_DAILYMOTION_REPORT:
            return {
                ...state,
                reportLoading: false
            };

        case types.PODCAST_EPISODE_EDIT_SUBTITLE:
            return {
                ...state,
                isEditingPodcastSubtitles: action.payload
            };
            
        default:
            return state;
    }
};

