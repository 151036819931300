import React , { Suspense } from 'react';
import { connect } from 'react-redux';

import UserAccountsList from './UserAccountsList';
import UserAccountsItem from './UserAccountsItem';

import { cancelAccountViewing } from '../../../redux/actions/accounts';

const UserAccountsContainer = ({ isViewingData, cancelAccountViewing, selectedData, selectedUser }) => {
    return (
        <div>
            <div className="d-flex align-item-center mb-5">
                <h1 className="mr-4">
                    User Accounts
                </h1>
                {
                    isViewingData
                    ? <button className="btn-rounded-pink" onClick={()=>cancelAccountViewing()}>Back</button>
                    : null
                }
            </div>
            {
                isViewingData 
                ? <UserAccountsItem user={selectedData} details={selectedUser}/>
                : (
                    <Suspense fallback={<div>Loading ...</div>}>
                        <UserAccountsList />
                    </Suspense>
                )
            }
            
        </div>
    )
}

const mapStateToProps = ({ accounts: { isViewingData, selectedData, selectedUser } }) => ({ isViewingData, selectedData, selectedUser });

const mapFunctionsToProps = {
    cancelAccountViewing
};

export default connect(mapStateToProps, mapFunctionsToProps)(UserAccountsContainer);